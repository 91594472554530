import { getNotReportedSchedulePositions } from 'actions/schedule';
import Card, { CardsContainer, CardTitle } from 'components/Cards/Card';
import Redirect from 'components/Redirect/Redirect';
import { ROLE_ADMINISTRATOR, ROLE_TEACHER } from 'constants/roles';
import { getRootUrl } from 'helpers/url';
import Moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';
import no from 'date-fns/locale/nb';
import en from 'date-fns/locale/en-US';
import da from 'date-fns/locale/da';
import uk from 'date-fns/locale/uk';
import { translate, getActiveLanguage } from '@haldor/ui';
import { connect } from 'react-redux';
import { Spinner } from 'UI';
import { Button } from '@haldor/ui';
import DateTime from '_class/DateTime';
import { setPageTitle } from 'actions/header';

class AttendanceOverview extends Component {
	constructor(props) {
		super(props);

		Moment.locale(getActiveLanguage());

		registerLocale('en-US', en);
		registerLocale('sv-SE', sv);
		registerLocale('nb-NO', no);
		registerLocale('da-DK', da);
		registerLocale('uk', uk);
		setDefaultLocale('en-US');

		let start = Moment().subtract(1, 'days');
		let end = Moment();

		let startDateLocalStorage = localStorage.getItem('attendance-start-date');
		let endDateLocalStorage = localStorage.getItem('attendance-end-date');

		if (!Moment(startDateLocalStorage).isValid()) {
			startDateLocalStorage = null;
		}

		if (!Moment(endDateLocalStorage).isValid()) {
			endDateLocalStorage = null;
		}

		if (startDateLocalStorage != null && endDateLocalStorage != null) {
			start = Moment(startDateLocalStorage);
			end = Moment(endDateLocalStorage);
		}

		this.state = {
			loading: true,
			startDate: start,
			endDate: end,
			allowed: null,
			admin: false,
			filter: '',
		};
	}

	UNSAFE_componentWillMount = () => {
		this.props.setPageTitle(this.props.translate('not-reported-lessons'));
	}

	componentDidMount = () => {
		const school = this.props.user.schools.find(school => {
			return school.id == this.props.activeSchool;
		});

		if (school != null) {
			let isTeacher = school.userRoles.find(role => {
				return role.toUpperCase() == ROLE_TEACHER;
			});

			let isAdmin = school.userRoles.find(role => {
				return role.toUpperCase() == ROLE_ADMINISTRATOR;
			});

			this.setState({ allowed: isTeacher != null || isAdmin != null });
			this.setState({ admin: isAdmin != null });

			let role = isAdmin != null ? ROLE_ADMINISTRATOR : ROLE_TEACHER;
			this.props.getNotReportedSchedulePositions(role, this.state.startDate.format('YYYY-MM-DD'), this.state.endDate.format('YYYY-MM-DD'))
				.then(() => {
					this.setState({ loading: false });
				});
		}
	}

	onStartDateChange = (startDate) => {
		if (startDate == null) {
			return false;
		}

		this.setState({ startDate: Moment(startDate), endDate: Moment(startDate).add(6, 'days') });

		localStorage.setItem("attendance-start-date", new DateTime(startDate).getDateStamp());
		localStorage.setItem("attendance-end-date", new DateTime(Moment(startDate).add(6, 'days')).getDateStamp());
	}

	onEndDateChange = (endDate) => {
		if (endDate == null) {
			return false;
		}

		this.setState({ endDate: Moment(endDate), startDate: Moment(endDate).subtract(6, 'days') });

		localStorage.setItem("attendance-start-date", new DateTime(Moment(endDate).subtract(6, 'days')).getDateStamp());
		localStorage.setItem("attendance-end-date", new DateTime(endDate).getDateStamp());
	}

	onFilterChange = (event) => {
		event.preventDefault();

		const filter = event.target.value;
		this.setState({ filter });
	}

	onDateFilterChange = () => {
		this.setState({ loading: true });
		let role = this.state.admin ? ROLE_ADMINISTRATOR : ROLE_TEACHER;

		this.props.getNotReportedSchedulePositions(role, this.state.startDate.format('YYYY-MM-DD'), this.state.endDate.format('YYYY-MM-DD'))
			.then(() => {
				this.setState({ loading: false });
			});
	}

	getDateFormat = () => {
		let format = Moment.localeData(getActiveLanguage()).longDateFormat("L");
		let newFormat = format.replace("YYYY", "yyyy");
		let days = newFormat.replace("DD", "dd");
		return days;
	};

	normalizeLocale = (locale) => {
		const [language, region] = locale.split('-');

		if (language.toUpperCase() == "UK"){
			return `uk`;
		}
		return `${language}-${region.toUpperCase()}`;
	};

	render() {
		if (this.state.loading || this.state.allowed == null) {
			return (
				<div>
					<Spinner center />
				</div>
			);
		}

		if (this.state.allowed != null && !this.state.allowed) {
			return <Redirect to="/" />
		}

		let languages = ['sv', 'en', 'no', 'da', 'uk'];
		const sections = [];
		if (this.props.schedulePositions != null) {
			this.props.schedulePositions.forEach(position => {
				if (sections.indexOf(position.section.title) == -1 && position.section.title !== null) {
					sections.push(position.section.title);
				}
			});
		}

		let schedulePositions = this.props.schedulePositions;
		if (this.state.filter != '') {
			schedulePositions = schedulePositions.filter(position => {
				return position.section.title == this.state.filter;
			});
		}

		return (
			<div className="overview">
				<div className="single-section form" style={{ paddingTop: 0 }}>
					<div className="form-row">
						<div className="form-row-half">
							<h3>{this.props.translate('select-date')}</h3>
							<div className="form-row-half static">
								<DatePicker
									selected={this.state.startDate.toDate()}
									startDate={this.state.startDate.toDate()}
									endDate={this.state.endDate.toDate()}
									onChange={this.onStartDateChange}
									dateFormat={this.getDateFormat()}
									popperModifiers={{
										preventOverflow: {
											enabled: false,
										},
										positionFixed: true,
									}}
									popperPlacement="bottom-start"
									showWeekNumbers
									fixedHeight
									previousMonthButtonLabel=""
									nextMonthButtonLabel=""
									locale={this.normalizeLocale(getActiveLanguage())}
									{...this.state.startDate}
								/>
							</div>

							<div className="form-row-half static last">
								<DatePicker
									selected={this.state.endDate.toDate()}
									startDate={this.state.startDate.toDate()}
									endDate={this.state.endDate.toDate()}
									onChange={this.onEndDateChange}
									dateFormat={this.getDateFormat()}
									popperModifiers={{
										preventOverflow: {
											enabled: false,
										},
										positionFixed: true,
									}}
									popperPlacement="bottom-start"
									showWeekNumbers
									fixedHeight
									previousMonthButtonLabel=""
									nextMonthButtonLabel=""
									locale={this.normalizeLocale(getActiveLanguage())}
									{...this.state.endDate}
								/>
							</div>
						</div>

						<div className="form-row-half last">
							<h3>{this.props.translate('section')}</h3>

							<div className="select">
								<select onChange={this.onFilterChange} value={this.state.filter}>
									<option value="">{this.props.translate('filter-results')}</option>
									{sections.sort((a, b) => a.localeCompare(b, languages)).map(section => (
										<option key={'select-' + section} value={section}>{section}</option>
									))}
								</select>
							</div>
						</div>

						<div className="clearfix"></div>
					</div>

					<div className="form-row" style={{ marginTop: '0.75rem' }}>
						<Button onClick={this.onDateFilterChange}>
							{this.props.translate('update')}
						</Button>

						<div className="clearfix"></div>
					</div>
				</div>

				<div style={{ marginTop: '2rem' }}></div>

				<CardsContainer>
					{schedulePositions.map((position) => {
						if (position.activityMemberships.length > 0 || position.section.id > 0) {
							return (
								<Card
									style={{ minHeight: '2em' }}
									key={position.id + "-" + position.section.id}
									target={getRootUrl() + 'lesson/' + position.id + '?redirect=list'}
								>
									<div className="card-meta">
										{position.section.title}
									</div>

									<div className="card-meta" style={{ marginLeft: '0.65rem' }}>
										{new DateTime(position.startTime).getLongDateWithTime()}
									</div>

									<CardTitle>{position.title}</CardTitle>
								</Card>
							)
						}
					})}

					{schedulePositions.length == 0 ?
						<div className="color--meta text--center weight--bold">
							{this.props.translate('no-results')}
						</div>
						: null}
				</CardsContainer>

			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		activeSchool: state.user.activeSchool,
		user: state.user.currentUser,
		schedulePositions: state.schedule.schedulePositions,
		languages: state.Languages.languages,
	};
}

export default connect(mapStateToProps, {
	getNotReportedSchedulePositions,
	setPageTitle,
})(AttendanceOverview);
