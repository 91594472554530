import React from 'react';
import { useTranslate } from 'lib/translate';
import { Form, Field, FormSpy } from 'react-final-form';
import { capitalize } from 'helpers/content';
import { ASSESSMENT_TYPES } from 'components/BlockAssessments/AssessmentTypes';
import SimpleSelect from 'components/Inputs/SimpleSelect';
import AssessmentDetailField from 'components/Inputs/AssessmentDetailField';
import AssessmentFeedbackField from 'components/Inputs/AssessmentFeedbackField';
import { Button, Checkbox, Expandable, Icon } from '@haldor/ui';
import { ColorLabel, ColorLabelContainer } from 'UI';
import api from 'lib/api';

import './_assessmentForm.scss';

const AssessmentForm = (props) => {
	const translate = useTranslate();
	if (props.blocks == null) {
		return null;
	}

	const getInitialValues = () => {
		let initialValues = {};

		if (props.initialValues != null) {
			initialValues = JSON.parse(JSON.stringify(props.initialValues));
		}

		if (initialValues.assessmentFeedbacks == null) {
			initialValues.assessmentFeedbacks = [];
		}

		props.blocks.forEach((block) => {
			if (block == null || block.resources == null) {
				return true;
			}

			block.resources.forEach((resource) => {
				const foundFeedback = initialValues.assessmentFeedbacks.find((value) =>
					value.assessmentBlockPartId == resource.id && value.isPrivate == false
				);

				const foundNote = initialValues.assessmentFeedbacks.find((value) =>
					value.assessmentBlockPartId == resource.id && value.isPrivate == true
				);

				if (foundFeedback == null) {
					initialValues.assessmentFeedbacks.push({
						text: '',
						assessmentBlockPartId: resource.id,
						isPrivate: false,
					});
				}

				if (foundNote == null && (props.assessmentType == ASSESSMENT_TYPES.TEACHER || props.assessmentType == null)) {
					initialValues.assessmentFeedbacks.push({
						text: '',
						assessmentBlockPartId: resource.id,
						isPrivate: true,
					});
				}

				// Private assessmentFeedbacks should be after non private
				initialValues.assessmentFeedbacks.sort((a) => a.isPrivate ? 1 : -1);
			});
		});

		return initialValues;
	}

	const onSubmit = (values, form) => new Promise((resolve) => {
		values = JSON.parse(JSON.stringify(values));
		values.referenceId = props.referenceId;
		values.referenceType = props.referenceType;
		values.assessorType = props.assessmentType;

		if (values.assessmentFeedbacks != null) {
			values.assessmentFeedbacks = values.assessmentFeedbacks.filter((feedback) =>
				feedback.text != ''
			);
		}

		if (props.onSubmit != null) {
			props.onSubmit(values).then(() => {
				resolve(values);
				setTimeout(form.reset, 0);
				form.restart();
			});
		} else {
			resolve(values);
		}
	});

	const _getResourceTitle = (resource, courses) => {
		let text = null;

		if (resource.referenceType == 'matrix') {
			text = resource.value.year != null ? `${resource.value.title} ${resource.value.year}` : resource.value.title;
		}

		if (resource.referenceType == 'assessmentGoal') {
			text = translate('Goal');

			return (
				<div className='assessmentGoal-dotContainer'>
					<span>{text}</span>
					{courses.length > 0 ?
						courses.map(course => {
							let title = course.value.year != null ? `${course.value.title} ${course.value.year}` : course.value.title;
							return (
								<ColorLabelContainer>
									<ColorLabel
										key={course.value.id}
										tooltip={title}
										color={course.value.colorCode}
									/>
								</ColorLabelContainer>
							)
						}
						)
						: null}
				</div>
			);
		}

		return (
			<div className="df aic jcb">
				{text}

				{resource != null && resource.referenceType != 'assessmentonlyfeedback' && props.assessmentType == ASSESSMENT_TYPES.TEACHER && <div className="permissions">
					{renderPermission('STUDENT', resource.permissions)}
					{renderPermission('GUARDIAN', resource.permissions)}
				</div>}
			</div>
		);
	}

	const _getResourceColor = (resource) => {
		if (resource.referenceType == 'assessmentGoal') {
			return '#0f78da';
		}

		if (resource.referenceType == 'matrix') {
			return resource.value.colorCode;
		}

		return '#ccc';
	}

	/**
	 * @param {string} target
	 * @param {array} permissions
	 */
	const renderPermission = (target, permissions) => {
		const active = permissions.find((perm) =>
			perm.target == target && perm.referenceType == 'ASSESSMENTBLOCKPART'
		) != null;

		return (
			<div className="permission">
				<Icon name={active ? "Eye" : "Eye_Off"} />
				{translate(capitalize(target))}
			</div>
		);
	}

	const renderResource = (resource, index, form, courses = []) => {
		let matrix;

		if (resource.referenceType == 'matrix') {
			matrix = resource.value?.details?.find((detail) =>
				detail.matrixID == resource.referenceId
			)?.matrix;

			if (matrix == null || matrix._ParagraphSet == null) {
				return null;
			}
		}

		return (
			<Expandable
				title={_getResourceTitle(resource, courses)}
				color={_getResourceColor(resource)}
				key={resource.id}
				open
				ignorePropsUpdate
			>
				{resource.referenceType == 'matrix' &&
					matrix._ParagraphSet.map((paragraph) => {
						return paragraph.rows.map((row) => {
							var assessmentBlockPartRow = resource.assessmentBlockPartRows.find((selectedRow) => selectedRow.referenceId == row.id);
							if (assessmentBlockPartRow != null) {
								return (
									<Field
										name="assessmentDetails"
										component={AssessmentDetailField}
										row={assessmentBlockPartRow}
										resource={resource}
										key={assessmentBlockPartRow.id}
										assessmentType={props.assessmentType}
									/>
								);
							}
						})
					})}

				{resource.referenceType == 'assessmentGoal' &&
					resource.assessmentBlockPartRows.map((row) => {
						return (
							<Field
								name="assessmentDetails"
								component={AssessmentDetailField}
								row={row}
								resource={resource}
								key={row.id}
								assessmentType={props.assessmentType}
							/>
						);
					})}

				<Field
					name="assessmentFeedbacks"
					component={AssessmentFeedbackField}
					resource={resource}
					assessmentType={props.assessmentType}
				/>
			</Expandable>
		);
	}

	const normalizeHtmlString = (html) => {
		return html
			.replace(/<br\s*\/?>/g, ' ')
			.replace(/<[^>]*>/g, '')
			.replace(/\s+/g, ' ')
			.trim();
	}

	const areValuesEqual = (arr1, arr2, field) => {
		if ((arr1 == null && arr2 == null) || (arr1?.length == 0 && arr2?.length == 0) || (arr1 == null && arr2?.length == 0)
		) return true;
		else if (arr1 == null && arr2 != null || (arr1 != null && arr1.length !== arr2.length)
		) return false;

		return arr1.every((obj1, index) => {
			let obj2 = arr2.find(obj => obj.id === obj1.id);

			if (obj2?.id == null) {
				obj2 = arr2[index];
			}

			return obj2 && obj1[field] === obj2[field];
		});
	};

	const areFeedbackEqual = (arr1, arr2, field) => {
		if ((arr1 == null && arr2 == null) || (arr1?.length == 0 && arr2?.length == 0) || (arr1 == null && arr2?.length == 0)
		) return true;

		return arr1.every((obj1, index) => {
			let obj2 = arr2.find(obj => obj.id === obj1.id);

			if (obj2?.id == null) {
				obj2 = arr2[index];
			}
			if (obj1.text == '' && obj2 == null || obj1.text == '' && obj2.text == '<p><br></p>') return true;
			else {
				if (normalizeHtmlString(obj1[field]) === normalizeHtmlString(obj2[field])) return true;
			}
		});
	};

	const cellValuesEqual = (arr1, arr2) => areValuesEqual(
		arr1 ?? null,
		arr2 ?? null,
		'cellId'
	);

	const feedbackValuesEqual = (arr1, arr2) => areFeedbackEqual(
		arr1 ?? null,
		arr2 ?? null,
		'text'
	);

	const { blocks, unsavedValues, items, activeStudentId, referenceType } = props;

	const handleFormChange = (formState) => {

		let referenceId = items?.[0]?.id;
		let studentId = items?.[0]?.students?.[0]?.userId ?? null;
		if (studentId == null && activeStudentId != null) {
			studentId = activeStudentId;
		}

		let initialFeedback = getInitialValues().assessmentFeedbacks ? getInitialValues().assessmentFeedbacks : formState.values.assessmentFeedbacks;
		let initialDetails = getInitialValues().assessmentDetails ? getInitialValues().assessmentDetails : formState.values.assessmentDetails;

		if (formState?.values != null && props.updateUnsavedValues != null) {
			if (referenceType === "TeamsAssignment" && ((!getInitialValues().studentId) || (activeStudentId === getInitialValues().studentId))) {
				if ((formState.modified.assessmentFeedbacks == true || formState.modified.assessmentDetails == true) && // Form modified and unsaved values not yet set
					(
						(props.unsavedValues == null || (formState.modified.assessmentFeedbacks == true && !feedbackValuesEqual(props.unsavedValues.assessmentFeedbacks, formState.values.assessmentFeedbacks))) ||
						(props.unsavedValues == null || (formState.modified.assessmentDetails == true && !cellValuesEqual(props.unsavedValues.assessmentDetails, formState.values.assessmentDetails)))
					)) {
					props.updateUnsavedValues(formState.values, studentId, referenceId);
				} else if ((formState.modified.assessmentFeedbacks == false && formState.modified.assessmentDetails == false) && ( // Form not modified but unsaved values exists that are not equal
					(props.unsavedValues != null && feedbackValuesEqual(props.unsavedValues.assessmentFeedbacks, formState.values.assessmentFeedbacks)) ||
					(props.unsavedValues != null && cellValuesEqual(props.unsavedValues.assessmentDetails, formState.values.assessmentDetails)))
				) {
					props.updateUnsavedValues(null);
				} else if (props.unsavedValues != null && !formState.modified.assessmentFeedbacks && !formState.modified.assessmentDetails // New form, (modified not yet set), but values exists in unsaved values
					&& (feedbackValuesEqual(props.unsavedValues.assessmentFeedbacks, getInitialValues().assessmentFeedbacks))
					&& ((!getInitialValues().assessmentDetails && !props.unsavedValues.assessmentDetails) || (getInitialValues().assessmentDetails && cellValuesEqual(props.unsavedValues.assessmentDetails, initialDetails)))) {
					props.updateUnsavedValues(null);
				}
			}
			else if (referenceType === "AssignmentTask" && ((!getInitialValues().referenceId) || (referenceId === getInitialValues().referenceId))) { // If no initial reference (no assessment) or assessment reference is equal to task
				if ((formState.modified.assessmentFeedbacks == true || formState.modified.assessmentDetails == true) && // Form modified and unsaved values not yet set
					(
						(props.unsavedValues == null || (formState.modified.assessmentFeedbacks == true && !feedbackValuesEqual(props.unsavedValues.assessmentFeedbacks, formState.values.assessmentFeedbacks))) ||
						(props.unsavedValues == null || (formState.modified.assessmentDetails == true && !cellValuesEqual(props.unsavedValues.assessmentDetails, formState.values.assessmentDetails)))
					)) {
					props.updateUnsavedValues(formState.values, studentId, referenceId);
				} else if ((formState.modified.assessmentFeedbacks == false && formState.modified.assessmentDetails == false) && ( // Form not modified but unsaved values exists that are not equal
					(props.unsavedValues != null && feedbackValuesEqual(props.unsavedValues.assessmentFeedbacks, formState.values.assessmentFeedbacks)) ||
					(props.unsavedValues != null && cellValuesEqual(props.unsavedValues.assessmentDetails, formState.values.assessmentDetails)))
				) {
					props.updateUnsavedValues(null);
				} else if (props.unsavedValues != null && !formState.modified.assessmentFeedbacks && !formState.modified.assessmentDetails // New form, (modified not yet set), but values exists in unsaved values
					&& (feedbackValuesEqual(props.unsavedValues.assessmentFeedbacks, getInitialValues().assessmentFeedbacks))
					&& ((!getInitialValues().assessmentDetails && !props.unsavedValues.assessmentDetails) || (getInitialValues().assessmentDetails && cellValuesEqual(props.unsavedValues.assessmentDetails, initialDetails)))) {
					props.updateUnsavedValues(null);
				}
			} else if (props.unsavedValues != null) {
				props.updateUnsavedValues(null);
			}
		}
	}

	return (
		<div className="form-container assessment-form">
			<Form
				onSubmit={onSubmit}
				initialValues={unsavedValues != null ? unsavedValues : getInitialValues()}
				mutators={{
					setStatusCompleted: (args, state, utils) => {
						if (props.initialValues == null || props.initialValues.status != 'ASSESSMENT_PUBLISHED') {
							utils.changeValue(state, 'status', () => 'ASSESSMENT_COMPLETED');
						}
					},
					setStatusPublished: (args, state, utils) => {
						utils.changeValue(state, 'status', () => 'ASSESSMENT_PUBLISHED');
					},
					setStatusStarted: (args, state, utils) => {
						utils.changeValue(state, 'status', () => 'ASSESSMENT_STARTED');
					},
				}}
				keepDirtyOnReinitialize
				render={({ handleSubmit, submitting, form }) => {
					return (
						<form onSubmit={handleSubmit} className="form form-component">
							<FormSpy
								subscription={{ modified: true, values: true }}
								onChange={(formState) => handleFormChange(formState)}
							/>
							{submitting ?
								<div className="is_sending">
									<p>
										<span className="loading-spinner" />
									</p>
								</div>
								: null}

							{props.items != null && props.showStudents ?
								<Expandable open title={translate('Students')} ignorePropsUpdate>
									{props.items.map((item) => {
										if (item.firstname == null) {
											return null;
										}

										let name = item.firstname;
										if (item.lastname != null) {
											name += ' ' + item.lastname;
										}

										return (
											<table style={{ width: '100%' }}><tbody>
												<tr key={item.id}>
													<td>
														<div key={item.id} style={{ marginBottom: '.55rem' }}>
															<Checkbox
																disabled
																value={true}
																label={name}
															/>
														</div>
													</td>
												</tr>
											</tbody></table>
										);
									})}
								</Expandable>
								: null}

							{blocks
								.sort((a, b) => a.sortorder - b.sortorder)
								.map((block) => {
									const goals = [...block.resources].filter((resource) => {
										return resource.referenceType == 'assessmentGoal'
									});

									const courses = [...block.resources].filter((resource) => {
										return resource.referenceType == 'matrix'
									});

									const onlyFeedbacks = [...block.resources].filter((resource) => {
										return resource.referenceType == 'assessmentonlyfeedback'
									});

									if (block.assessmentType == ASSESSMENT_TYPES.TEACHER) {
										const foundIndex = blocks.sort((a, b) => a.sortorder - b.sortorder).findIndex((b) => b.id == block.id);
										block.title = translate('Teacher assessment') + ' ' + (foundIndex + 1);
									}

									return (
										<Expandable title={block.title} open key={block.id} ignorePropsUpdate>
											{goals.map((resource, index) => renderResource(resource, index, form, courses))}
											{courses.map((resource, index) => renderResource(resource, index, form))}
											{onlyFeedbacks.map((resource, index) => renderResource(resource, index, form))}
										</Expandable>
									);
								})}

							<div className="form-divider" />

							<div className="form-row spacing submit">
								{props.initialValues == null || props.initialValues?.status != 'ASSESSMENT_PUBLISHED' ?
									<Button
										onClick={(e) => {
											e.preventDefault();
											form.mutators.setStatusCompleted();
											form.submit();
										}}
										style={{ marginBottom: '20px' }}
									>
										{translate('Save assessment')}
									</Button>
									: null}

								<Button
									onClick={(e) => {
										e.preventDefault();
										form.mutators.setStatusPublished();
										form.submit();
									}}
									style={{ marginBottom: '20px' }}
								>
									{translate('Save and publish assessment')}
								</Button>

								{props.initialValues == null || props.initialValues?.status != 'ASSESSMENT_PUBLISHED' ?
									<Button
										onClick={(e) => {
											e.preventDefault();
											form.mutators.setStatusStarted();
											form.submit();
										}}
										type="secondary"
										style={{ marginBottom: '20px' }}
									>
										{translate('Save draft')}
									</Button>
									: null}

								{props.onClose != null ?
									<div className="align-right">
										<Button type="secondary" onClick={props.onClose}>
											{translate('Cancel')}
										</Button>
									</div>
									: null}
							</div>
						</form>
					);
				}}
			/>
		</div>
	);

}

AssessmentForm.defaultProps = {
	showStudents: true,
	assessmentType: "Teacher",
};

export default AssessmentForm;