import React from 'react';

const FolderIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			data-name="Lager 1"
			viewBox="0 0 40 40"
		>
			<path
				d="M36 12H18l-4-4H4a4 4 0 0 0-4 4v8h40v-4a4 4 0 0 0-4-4Z"
				style={{
					fill: "#ffa000",
				}}
			/>
			<path
				d="M36 12H4a4 4 0 0 0-4 4v20a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V16a4 4 0 0 0-4-4Z"
				style={{
					fill: "#ffca28",
				}}
			/>
		</svg>
	);
};

export default FolderIcon;