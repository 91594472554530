import React, { Component } from 'react';
import { translate, Icon } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as microsoftTeams from "@microsoft/teams-js";
import { getAssignmentSubmission, getMicrosoftAssignmentDetails, getTaskDetails } from 'actions/assignments';
import { setPageTitle } from 'actions/header';
import { getSection } from 'actions/sections';
import { isUserTeacher } from 'helpers/user';
import swal from 'sweetalert2';
import Select from 'react-select';
import { FormPrompt } from 'components/Inputs/FormPrompt';
import { addError } from 'actions';
import AssessmentForm from 'containers/Forms/AssessmentForm';
import WopiFrame from 'containers/WopiFrame/WopiFrame';
import { getBlocksByReference, clearBlocksOnReference } from 'actions/blocks';
import { createAssessments, updateAssessment, getAssignmentAssessment } from 'actions/assessments';
import { Skeleton, Spinner } from 'UI';
import DisplayName from 'components/Presentation/DisplayName';
import { isMicrosoftTeams } from 'helpers/teams';

class SubmissionsView extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loadingSubmission: true,
			loadingAssignment: true,
			loadingDetails: false,
			activeFile: null,
			activeSubmission: null,
			sidebarWide: false,
			sidebar: true,
			unsavedValues: null,
			activeStudentId: null,
			activeSubmissionStatus: null,
		};
	}

	componentWillUnmount() {
		this.props.clearBlocksOnReference();
	}

	componentDidMount = () => {
		const { assignmentId } = this.props.match.params;

		this.props.getMicrosoftAssignmentDetails(assignmentId)
			.then(() => {
				this.setState({ loadingDetails: false });
				this.getAssignment();
			});

		if (window.location.hash == '#assessment') {
			this.setState({ sidebarWide: true });
		}

		window.addEventListener('hashchange', () => {
			this.setState({ sidebarWide: window.location.hash == '#assessment' });
		});
	}

	getAssignment = () => {
		const { assignmentId, submissionId } = this.props.match.params;

		if (this.props.assignment == null) {
			if (isMicrosoftTeams()) {
				microsoftTeams.app.getContext().then((context) => {

					let sectionGuid = null;

					if (context.team) {
						sectionGuid = context.team.groupId;

						let group = this.props.groups.find(group => {
							return group.graphId == context.team.groupId;
						});

						if (this.props.group == null) {
							if (group != null) {
								this.props.getSection(group.id);
							}
						}
					}

					this.props.getTaskDetails(assignmentId, sectionGuid).then(() => {
						this.props.setPageTitle(this.props.assignment.displayName);

						if (!context.team) {
							let group = this.props.groups.find(group => {
								return group.graphId == this.props.assignment.classId;
							});

							if (group != null) {
								this.props.getSection(group.id);
							}
						}

						if (this.props.blocks == null && this.props.details != null) {
							this.props.getBlocksByReference(this.props.details.id, 'ASSIGNMENT');
						}
						this.props.getAssignmentAssessment(this.props.details.id);
						this.setState({ loadingAssignment: false });
					});
				});
			} else {
				this.props.getTaskDetails(assignmentId).then(() => {
					this.props.setPageTitle(this.props.assignment.displayName);
					this.setState({ loadingAssignment: false });

					if (this.props.group == null) {
						let group = this.props.groups.find(group => {
							return group.graphId == this.props.assignment.classId;
						});

						if (group != null) {
							this.props.getSection(group.id);
						}
					}

					if (this.props.blocks == null && this.props.details != null) {
						this.props.getBlocksByReference(this.props.details.id, 'ASSIGNMENT');
					}
					this.props.getAssignmentAssessment(this.props.details.id);
				});
			}
		} else {
			this.props.setPageTitle(this.props.assignment.displayName);

			if (this.props.blocks == null && this.props.details != null) {
				this.props.getBlocksByReference(this.props.details.id, 'ASSIGNMENT');
				this.props.getAssignmentAssessment(this.props.details.id);
			}

			this.setState({ loadingAssignment: false });
		}

		if (this.state.activeSubmission == null && submissionId != null) {
			const submission = {
				'id': submissionId,
			};

			this.onSubmissionSelect(submission);
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.props.match.params.submissionId != nextProps.match.params.submissionId) {
			const submission = {
				'id': nextProps.match.params.submissionId,
			};

			this.onSubmissionSelect(submission);
		}
	}

	toggleSidebar = () => {
		this.setState({ sidebar: !this.state.sidebar });
	}

	filterStudentList = () => {
		const { group, assignment } = this.props;
		let students = [];

		if (assignment == null) {
			return students;
		}

		if (assignment.submissions != null && assignment.submissions.length > 0) {
			if (group != null && isUserTeacher(this.props.currentUser)) {
				group.students.forEach((groupStudent) => {
					let student = assignment.submissions.find(submission => submission.recipient.userId == groupStudent.id);

					if (student != null) {
						students.push(groupStudent);
					}
				});
			}

			return students;
		}

		if (assignment.assignTo == null || assignment.assignTo.recipients == null) {
			return students;
		}

		if (group != null && isUserTeacher(this.props.currentUser)) {
			group.students.forEach((groupStudent) => {
				if (this.props.assignment.assignTo == null || this.props.assignment.assignTo.recipients == null) {
					return;
				}

				let student = this.props.assignment.assignTo.recipients.find(assignmentStudentId => assignmentStudentId == groupStudent.id);

				if (student != null) {
					students.push(groupStudent);
				}
			});
		}

		return students;
	}

	toggleIcon(flip) {
		return (
			<i className="cl-container">
				<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" className={flip ? 'i-90' : 'a-90'} style={{ height: '7px', width: '15px' }}>
					<path id="Path_50" data-name="Path 50" className="cls-1" d="M11.361,1.4,6.38,5.9,1.4,1.4" />
				</svg>
			</i>
		);
	}

	onClickDocumentFunc = (file) => {
		this.setState({ activeFile: file });
	};

	renderNoDocuments = () => {
		return <div style={{ textAlign: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
			<strong>{this.props.translate('no-files-found')}</strong>
		</div>
	}

	onSubmissionSelect = (submission) => {
		if (submission == null) {
			return;
		}
		const { assignment, details } = this.props;
		let student = null;
		let foundSubmission = null;

		if (this.state.unsavedValues != null) {
			swal.fire({
				title: this.props.translate('are-you-sure'),
				text: this.props.translate("There are changes that haven't been saved. Are you sure you want to leave the page"),
				showCancelButton: true,
				cancelButtonText: this.props.translate('No'),
				confirmButtonText: this.props.translate('Yes'),
			}).then((result) => {
				if (result.value != null) {
					this.setState({ unsavedValues: null });

					if (assignment != null && assignment.submissions != null && assignment.submissions.length > 0) {
						let students = this.filterStudentList();
						foundSubmission = assignment.submissions.find((sub) => {
							return sub.id == submission.id;
						});

						student = students.find(student => {
							return student.id == foundSubmission.recipient.userId;
						});
					}

					if (details != null) {
						this.setState({ activeSubmission: submission.id, loadingSubmission: true });

						this.props.getAssignmentSubmission(details.id, submission.id).then(() => {
							this.setState({ loadingSubmission: false });
							const { submission } = this.props;

							if (submission != null && submission.length > 0) {
								// Select the first document
								this.onClickDocumentFunc(submission[0]);
								return;
							}

							this.setState({ activeFile: null });
						});
					}

				}
			});
		} else {
			if (assignment != null && assignment.submissions != null && assignment.submissions.length > 0) {
				let students = this.filterStudentList();
				foundSubmission = assignment.submissions.find((sub) => {
					return sub.id == submission.id;
				});

				student = students.find(student => {
					return student.id == foundSubmission.recipient.userId;
				});
			}

			if (details != null) {
				this.setState({ activeSubmission: submission.id, loadingSubmission: true });

				this.props.getAssignmentSubmission(details.id, submission.id).then(() => {
					this.setState({ loadingSubmission: false });
					const { submission } = this.props;

					if (submission != null && submission.length > 0) {
						// Select the first document
						this.onClickDocumentFunc(submission[0]);
						return;
					}

					this.setState({ activeFile: null });
				});
			}
		}
	}

	renderListView = () => {
		const { assignment, details } = this.props;
		const { activeSubmission, activeStudentId } = this.state;

		if (assignment == null && assignment.submissions != null || details == null || activeSubmission == null)
			return null;

		const students = this.filterStudentList().sort((a, b) => a.lastName.localeCompare(b.lastName));

		let active = assignment.submissions.find((submission) => {
			return submission.id == activeSubmission;
		});

		let studentId = students.findIndex(student => {
			return student.id == active?.recipient?.userId;
		});
		let nextStudentIndex = studentId + 1;
		let prevStudentIndex = studentId - 1;

		let prevStudent = prevStudentIndex >= 0 ?
			students[prevStudentIndex] : null;
		let nextStudent = nextStudentIndex < students.length ?
			students[nextStudentIndex] : null;

		let next = nextStudent !== null ?
			assignment.submissions.find(x => x.recipient.userId === nextStudent?.id) : null
		let prev = prevStudent !== null ?
			assignment.submissions.find(x => x.recipient.userId === prevStudent?.id) : null;

		const activeStudent = students[studentId];
		if (activeStudent != null && activeStudent.id != activeStudentId) {
			this.setState({ activeStudentId: activeStudent.id });
		}
		if (this.state.unsavedValues != null && this.state.unsavedValues.studentId !== activeStudentId) {
			this.setState({ unsavedValues: null });
		}

		return <div style={{ marginBottom: '0.75rem' }}>
			<div style={{ display: 'flex' }}>
				<Link to="#" style={{ cursor: 'pointer' }} onClick={() => this.onSubmissionSelect(prev)}>
					{this.toggleIcon(false)}
				</Link>

				<div style={{ flex: 1, display: 'flex', textAlign: 'center', alignItems: 'center' }}>
					<span style={{ fontWeight: 500, flex: '1' }}>

						{activeStudent != null ?
							<DisplayName
								firstName={activeStudent.firstName}
								lastName={activeStudent.lastName}
								email={activeStudent.email}
								dynamicStringWidth={true}
								showEmail={true}
							/>
							: <Skeleton />}

					</span>
				</div>

				<Link to="#" style={{ cursor: 'pointer' }} onClick={() => this.onSubmissionSelect(next)}>
					{this.toggleIcon(true)}
				</Link>
			</div>

			<div className="clearfix"></div>
		</div>
	}

	renderActiveFile = () => {
		const { activeFile } = this.state;

		if (activeFile == null)
			return null;

		let notOfficeFile = activeFile.webUrlViewMode === '';
		let mp4File = notOfficeFile && /.mp4$/.test(activeFile.webUrl);
		let imageFile = notOfficeFile && /(.png|.jpg|.gif|.jpeg)$/.test(activeFile.webUrl);
		let m4aFile = notOfficeFile && /.m4a$/.test(activeFile.webUrl);

		if (!notOfficeFile) {
			return <WopiFrame activeFile={activeFile.webUrlViewMode} />
		} else if (mp4File) {
			return <video
				src={activeFile.webUrl}
				controls
				autoPlay
				width="100%"
				height="600">
				Your browser does not support the video tag.
			</video>
		} else if (imageFile) {
			return <div style={{ height: '600px', width: '100%', overflowX: 'scroll' }}>
				<img src={activeFile.webUrl} width="100%" />
			</div>
		} else if (m4aFile) {
			return <audio controls preload="auto" autoPlay style={{ width: '100%' }} >
				<source src={activeFile.webUrl} type="audio/mp4" />
				<p>Your browser does not support the audio tag.</p>
			</audio>
		}

		return null;
	}

	onAssessmentSubmit = (values) => {
		return new Promise((resolve) => {
			const { assignment } = this.props;

			let active = assignment.submissions.find((submission) => {
				return submission.id == this.state.activeSubmission;
			});

			if (values.id != null) {
				const existing = this.props.assessments.find((assessment) => assessment.id == values.id);
				this.props.updateAssessment(values, existing).then(async () => {
					await this.props.getAssignmentAssessment(this.props.details.id);
					this.props.addError(this.props.translate('changes-saved'), 'info');
					this.setState({ unsavedValues: null });
					resolve(1);
				});

				return true;
			}

			let data = {
				...values,
				externalId: this.props.assignment.id,
				studentId: active.recipient.userId,
			};

			this.props.createAssessments([data]).then(async () => {
				await this.props.getAssignmentAssessment(this.props.details.id);
				this.props.addError(this.props.translate('changes-saved'), 'info');
				this.setState({ unsavedValues: null });
				resolve(1);
			});
		});
	}

	updateUnsavedValues = (newValue, studentId, referenceId) => {
		if (
			newValue == null ||
			studentId != this.state.activeStudentId ||
			referenceId !== this.props.assignment?.id
		) {
			this.setState({ unsavedValues: null });
		} else {
			this.setState((prevState) => ({
				unsavedValues: {
					...newValue,
					studentId: studentId,
					referenceId: referenceId
				}
			}));
		}
	};

	isTaskAssessable = () => {
		let submission = this.props.assignment.submissions.find((s) => s.recipient.userId == this.state.activeStudentId);
		if (submission != null && submission.status != null && this.state.activeSubmissionStatus != submission.status) {
			this.setState({ activeSubmissionStatus: submission.status });
		}
		return (
			submission != null &&
			(submission.status == "submitted" ||
				submission.status == "returned" ||
				submission.status == "reassigned")
		);
	};

	renderStatus = () => {
		return (
			<div className="status-change" style={{ marginBottom: '1rem', display: 'inline-flex', alignItems: 'end' }}>
				<label style={{ marginRight: '.5rem' }}>{this.props.translate('Work status')}: </label>
				<div>{this.props.translate("microsoft-assignment-" + this.state.activeSubmissionStatus)}</div>
			</div>
		)
	};

	render() {
		const {
			assignmentAttachementLoading,
			loadingSubmission,
			activeSubmission,
			loadingDetails,
			sidebarWide,
			activeFile,
			unsavedValues,
		} = this.state;
		const { details, assignment, submission } = this.props;
		const isTeacher = isUserTeacher(this.props.currentUser);

		let assessmentBlocks = [];
		let assessment = [];
		let fileOptions = [];

		if (assignment == null || loadingSubmission || loadingDetails) {
			return <Spinner center />
		}

		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);
		}

		if (this.props.assessments != null && activeSubmission != null) {
			let active = assignment.submissions.find((submission) => {
				return submission.id == activeSubmission;
			});

			assessment = this.props.assessments.find((assessment) =>
				assessment.studentId == active.recipient.userId
			);
		}

		fileOptions = submission?.map((file) => ({
			value: file,
			label: file.name,
		}));

		return (
			<div className={sidebarWide ? "attached-user-documents wide" : "attached-user-documents"}>
				{this.state.unsavedValues != null && isTeacher ? (
					<FormPrompt
						hasUnsavedChanges={this.state.unsavedValues != null}
						formPromptMessage={this.props.translate(
							"There are changes that haven't been saved. Are you sure you want to leave the page"
						)}
						currentPathContext={window.location.pathname}
					/>
				) : null}

				<div className='iframe-section left-side'>
					{!assignmentAttachementLoading && !loadingSubmission && (submission == null || submission.length == 0) ?
						this.renderNoDocuments()
						: !assignmentAttachementLoading && activeFile != null ?
							<div className="choose-file-container">
								<div className="choose-file" >
									<label>{this.props.translate('Choose file')}:</label>
									<Select
										menuPortalTarget={document.body}
										name={'file'}
										options={fileOptions}
										onChange={(eventTarget) => this.onClickDocumentFunc(eventTarget.value)}
										className="simple-select"
										placeholder={activeFile.name != null ? activeFile.name : ''}
										components={{ IndicatorSeparator: () => null }}
									/>
								</div>
								<div className="action">
									{isTeacher && activeFile.webUrlViewMode !== '' ?
										<div>
											<a href={activeFile.webUrlEditMode} target="_blank">
												<span>
													<Icon name="Pen_Small" />
												</span>
												{this.props.translate('Edit') + ' '}({this.props.translate('opens-in-new-tab')})
											</a>
										</div>
										: null}
								</div>
							</div>
							: null}

					{assignmentAttachementLoading ? <Spinner center /> : this.renderActiveFile()}
				</div>

				{this.state.sidebar && isTeacher ?
					<div className="sidebar">
						<div className="collapse color--meta" onClick={this.toggleSidebar}>
							{this.props.translate('fold-up')} <Icon name="ArrowRight" />
						</div>

						{isTeacher && !loadingDetails ? this.renderListView() : null}

						{this.state.activeSubmissionStatus != null ? this.renderStatus() : null}

						{isTeacher && !loadingDetails && assessmentBlocks.length > 0 ?
							<div>
								{this.isTaskAssessable() ?
									<AssessmentForm
										onSubmit={this.onAssessmentSubmit}
										items={[assignment]}
										blocks={assessmentBlocks}
										referenceType="TeamsAssignment"
										referenceId={details.id}
										showStudents={false}
										updateUnsavedValues={this.updateUnsavedValues}
										activeStudentId={this.state.activeStudentId}
										initialValues={assessment?.studentId === this.state.activeStudentId ? assessment : null}
										unsavedValues={unsavedValues?.studentId === this.state.activeStudentId ? unsavedValues : null}
									/>
									: <div style={{ padding: "1rem" }} className="color--meta text--center">
										{this.props.translate("Student-has-not-submitted-assignment")}
									</div>}
							</div>
							: null}
					</div>
					: isTeacher ?
						<div className="expand color--meta" onClick={this.toggleSidebar}>
							{this.props.translate('fold-out')} <Icon name="ArrowLeft" />
						</div>
						: null}

			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		details: state.assignments.teamsAssignmentDetails,
		assignment: state.assignments.active_assignment,
		assessments: state.assessments.assessments,
		submission: state.assignments.submission,
		group: state.sections.activeSection,
		groups: state.sections.educationGroups,
		blocks: state.Blocks.reference,
	};
}

export default connect(mapStateToProps, {
	getAssignmentSubmission,
	getTaskDetails,
	getMicrosoftAssignmentDetails,
	getAssignmentAssessment,
	getSection,
	setPageTitle,
	addError,
	getBlocksByReference,
	clearBlocksOnReference,
	updateAssessment,
	createAssessments
})(SubmissionsView);
