import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import Moment from "moment";

import { getUserInfo, getAllUserSections } from 'actions/user';
import { setPageTitle } from 'actions/header';

import User from "_class/User";
import DateTime from "_class/DateTime";

import { getUserAssessmentDates } from "helpers/user";
import { getActiveSchool } from 'helpers/localstorage';

import swal from 'sweetalert2';

import UserAssessments from './Partials/UserAssessments';
import AttendanceList from "./Partials/AttendanceList";
import DatePickers from "./Partials/DatePickers";
import GroupFilter from "./Partials/GroupFilter";
import MentorVerdicts from "./Partials/MentorVerdicts";
import Schedule from 'containers/Schedule/Schedule';
import MentorNotes from './Partials/MentorNotes';
import AdditionalAdjustments from './Partials/AdditionalAdjustments';
import UserMeetings from './Partials/UserMeetings';
import UserDetails from './Partials/UserDetails';

import UserFileArchive from './Partials/UserFileArchive';
import MentorGoalOverview from './Partials/MentorGoalOverview';

import { Spinner } from "UI";
import { Button, getActiveLanguage, Tabs, Tab } from '@haldor/ui';
import PermissionManager from "_class/PermissionManager";

class UserProgress extends Component {
	constructor(props) {
		super(props);

		Moment.locale(getActiveLanguage());

		this.state = {
			loaded: false,
			selectedGroupId: 0,
			start: getUserAssessmentDates().start,
			end: getUserAssessmentDates().end,
			currentStartDate: getUserAssessmentDates().start,
			currentEndDate: getUserAssessmentDates().end,
			permissionTypes: new PermissionManager().getPermissionTypes()
		};
	}

	_getUserId = () => {
		let userId = null;
		if (this.props.match != null && this.props.match.params.userId != null) {
			userId = this.props.match.params.userId;
		} else if (this.props.userId != null) {
			userId = this.props.userId;
		} else {
			userId = this.props.currentUser.id;
		}

		return userId;
	};

	componentDidMount = () => {
		const userId = this._getUserId();
		this.props.getUserInfo(userId, 'extended').then(() => {
			const savedUser = this.props.users.find(function (usr) {
				return usr.id == userId;
			});

			if (savedUser != null) {
				this.props.setPageTitle(savedUser.firstName + " " + savedUser.lastName);
			}
		});

		this.props.getAllUserSections(userId, { pageSize: 999, filter: 'EDUCATION_GROUP;ACTIVE' });
	};

	onDateChange = ({ start, end }) => {
		if (start == 'Invalid date') {
			start = Moment().subtract(1, 'years');
		}
		if (end == 'Invalid date') {
			end = Moment().add(1, 'years');
		}

		this.setState({
			start: new DateTime(start).getDateStamp(),
			end: new DateTime(end).getDateStamp(),
		});
	};

	updateDates = (event) => {
		event.preventDefault();
		const { start, end } = this.state;

		localStorage.setItem("assessment-start-date", new DateTime(start).getDateStamp());
		localStorage.setItem("assessment-end-date", new DateTime(end).getDateStamp());

		this.setState({ currentStartDate: start, currentEndDate: end });
	};

	onGroupChange = (groupId) => {
		let group = this.props.userSections.find((g_object) => {
			return g_object.id == groupId;
		});

		this.props.userSections.map((group) => {
			group.open = false;
			group.hidden = false;
		});

		if (group != null) {
			this.props.userSections.map((group) => {
				if (groupId != group.id) {
					group.hidden = true;
				}
			});
		} else {
			this.props.userSections.map((group) => {
				group.open = false;
				group.hidden = false;

				return group;
			});
		}

		this.setState({ selectedGroupId: groupId }, () => {
			if (group != null) {
				group.open = true;
				group.hidden = false;
			}

			this.setState({});
		});
	};

	render() {
		const { userSections, translate } = this.props;
		const user = new User(this.props.currentUser);
		const activeSchoolId = getActiveSchool(this.props.currentUser.id);
		const activeSchool = this.props.currentUser.schools.find(
			(school) => school.id == activeSchoolId
		);

		const isStudent = user.isStudent();
		const showTeacherTabs =
			user.isStudent() || user.isTeacher() || user.isMentor() || user.isAdministrator();

		let userId = null;
		if (this.props.match != null && this.props.match.params.userId != null) {
			userId = this.props.match.params.userId;
		} else if (this.props.userId != null) {
			userId = this.props.userId;
		} else {
			userId = this.props.currentUser.id;
		}

		const activeUser = this.props.users.find((user) => {
			return user.userId == userId;
		});

		if (isStudent) {
			if (userId != this.props.currentUser.id) {
				return <Redirect to='/404' />;
			}
		}

		if (this.props.tenant == null) {
			return (
				<div>
					<Spinner center />
				</div>
			);
		}

		return (
			<div className='progress single-task'>
				<div>
					<div className='form' style={{ marginBottom: '2rem' }}>
						<div className='form-row'>
							<div className='form-row-half static'>
								<DatePickers
									startDate={this.state.start}
									endDate={this.state.end}
									onChange={this.onDateChange}
								/>
							</div>

							<div className='form-row-half static last'>
								<GroupFilter
									onChange={this.onGroupChange}
									sections={userSections}
									selectedGroupId={this.state.selectedGroupId}
								/>
							</div>
						</div>
					</div>

					<div className="clearfix" />

					<div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
						<Button type="secondary" onClick={this.updateDates}>
							{translate("update")}
						</Button>
					</div>
				</div>

				<Tabs>
					{activeSchool.type != 'PRESCHOOL' && (showTeacherTabs || user.havingPermission(this.state.permissionTypes.ASSESSMENTS_READ_SCHOOL)) ? (
						<Tab
							title={this.props.translate('assessments-header-overview')}
							route='assessments'
						>
							<div>
								<UserAssessments
									studentOverView
									studentId={userId}
									start={this.state.currentStartDate}
									end={this.state.currentEndDate}
									sectionId={this.state.selectedGrupId != 0 ?
										this.state.selectedGroupId
										: null}
								/>
							</div>
						</Tab>
					) : null}

					{activeSchool.type == 'PRESCHOOL' ? (
						<Tab title={this.props.translate('goals')} route='goals'>
							<MentorGoalOverview
								userId={userId}
								start={this.state.start}
								end={this.state.end}
							/>
						</Tab>
					) : null}

					{(this.props.services.verdicts && activeSchool.type != 'PRESCHOOL' && (showTeacherTabs || user.havingPermission(this.state.permissionTypes.VERDICT_READ_SCHOOL))) ? (
						<Tab title={this.props.translate('verdicts')} route='verdicts'>
							<MentorVerdicts
								userId={userId}
								start={this.state.currentStartDate}
								end={this.state.currentEndDate}
								isStudent={isStudent}
								user={activeUser}
							/>
						</Tab>
					) : null}

					{!isStudent && this.props.services.schedule && activeSchool.type != 'PRESCHOOL' ? (
						<Tab title={translate('schedule')} route='schedule'>
							<Schedule redirect='back' studentId={userId} overview studentCard={true} />
						</Tab>
					) : null}

					{this.props.services.attendance && activeSchool.type != 'PRESCHOOL' ? (
						<Tab title={translate('attendance')} route='attendance'>
							<AttendanceList
								start={this.state.start}
								end={this.state.end}
								sectionId={this.state.selectedGroupId}
								user={userId}
							/>
						</Tab>
					) : null}

					{this.props.services.additionalAdjustments ? (
						<Tab title={this.props.translate('additional-adjustments')} route='adjustments'>
							<AdditionalAdjustments
								start={this.state.start}
								end={this.state.end}
								user={userId}
							/>
						</Tab>
					) : null}

					{this.props.services.notes ? (
						<Tab title={translate('notes')} route='notes'>
							<MentorNotes
								user={userId}
								isUpdated={this.props.isUpdated}
								isUpdating={this.props.isUpdating}
								isStudent={isStudent}
							/>
						</Tab>
					) : null}

					{this.props.services.meetings && (user.isMentor() || user.isAdministrator() || user.havingPermission(this.state.permissionTypes.MEETINGS_READ_SCHOOL)) ? (
						<Tab title={translate('meetings')} route='meetings'>
							<UserMeetings user={userId} start={this.state.start} />
						</Tab>
					) : null}

					{(user.isMentor() || user.havingPermission(this.state.permissionTypes.FILES_READ_SCHOOL)) ? (
						<Tab title={translate('documentation')} route='documentation'>
							<UserFileArchive user={userId} />
						</Tab>
					) : null}

					{!isStudent ? (
						<Tab title={translate('details')} route='details'>
							<UserDetails user={userId} />
						</Tab>
					) : null}
				</Tabs>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		services: state.Services.availableServices,
		userSections: state.user.userSections,
		currentUser: state.user.currentUser,
		users: state.user.info,
	};
}

export default connect(mapStateToProps, {
	getAllUserSections,
	getUserInfo,
	setPageTitle,
})(UserProgress);
