import React, { Component } from 'react';
import { Button, translate, Icon } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import swal from 'sweetalert2';
import api from 'lib/api';
import sniffer from 'sniffer';
import Select from 'react-select';
import { FormPrompt } from 'components/Inputs/FormPrompt';
import { addError } from 'actions';
import { createAssessments, updateAssessment, getAssignmentAssessment } from 'actions/assessments';
import { deleteAssignmentUserDocument, getFolderFiles, getTaskDetails } from 'actions/assignments';
import { getBlocksByReference, clearBlocksOnReference } from 'actions/blocks';
import { getSection } from 'actions/sections';
import { getStudentAssessment, getStudentAssessments } from 'actions/studentassessments';
import SimpleSelect from 'components/Inputs/SimpleSelect';
import AssessmentForm from 'containers/Forms/AssessmentForm';
import WopiFrame from 'containers/WopiFrame/WopiFrame';
import Message from 'containers/SinglePages/Partials/Message';
import jwtDecode from 'jwt-decode';
import { Spinner } from 'UI';
import { ASSESSMENT_TYPES } from 'components/BlockAssessments/AssessmentTypes';
import DisplayName from '../DisplayName';

class AttachedUserDocuments extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedDocument: undefined,
			selectedVideo: undefined,
			selectedAudio: undefined,
			selectedImage: undefined,
			assignmentAttachementLoading: false,
			assignmentLoading: false,
			activeTask: [],
			isTeamsDesktop: false,
			activeTaskLoading: false,
			assessmentSubmitting: false,
			sidebar: true,
			sidebarWide: false,
			conversation: false,
			unsavedValues: null,
		};

		this.clickChildSubmit = null;
	}

	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	componentWillUnmount() {
		this.props.clearBlocksOnReference();
	}

	UNSAFE_componentWillMount = () => {
		this.loadInit(this.props.taskId);
	}

	componentDidMount = () => {
		if (window.location.hash == '#assessment') {
			this.setState({ sidebarWide: true });
		}

		window.addEventListener('hashchange', () => {
			this.setState({ sidebarWide: window.location.hash == '#assessment' });
		});

		if (this.props.blocks == null || this.props.blocks.referenceId != this.props.assignmentId || this.props.blocks.referenceType.toLowerCase() !== 'assignment') {
			this.props.getBlocksByReference(this.props.assignmentId, 'ASSIGNMENT');
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.props.documentRefresh !== nextProps.documentRefresh) {
			this.loadInit(nextProps.taskId);
		}
	}

	loadInit = (taskId) => {
		this.loadUserAssignmentAttachedDocument(taskId);
		this.loadActiveAssignment();
		this.checkTeamsDesktop();
	}

	loadUserAssignmentAttachedDocument = (taskId) => {
		this.setState({
			assignmentAttachementLoading: true,
			activeTaskLoading: true,
			selectedDocument: '',
			selectedVideo: '',
			selectedImage: '',
			selectedAudio: '',
		});

		this.props.assignment.tasks.forEach(t => t.selected = false);

		this.props.getFolderFiles(this.props.assignmentId, taskId).then(() => {

			this.setState({ assignmentAttachementLoading: false });
			let officeFile = this.props.folderFiles.length > 0 && this.props.folderFiles.filter(f => f.webUrlViewMode !== '')[0];
			let mp4File = this.props.folderFiles.length > 0 && this.props.folderFiles.filter(f => /.mp4$/.test(f.webUrl))[0];
			let m4aFile = this.props.folderFiles.length > 0 && this.props.folderFiles.filter(f => /.m4a$/.test(f.webUrl))[0];
			let imageFile = this.props.folderFiles.length > 0 && this.props.folderFiles.filter(f => /(.png|.jpg|.gif|.jpeg)$/.test(f.webUrl))[0];

			if (officeFile) {
				this.setState({ selectedDocument: officeFile.webUrlViewMode });
			} else if (mp4File) {
				this.setState({ selectedVideo: mp4File.webUrl });
			} else if (imageFile) {
				this.setState({ selectedImage: imageFile.webUrl });
			} else if (m4aFile) {
				this.setState({ selectedAudio: m4aFile.webUrl });
			}
		});
	}

	loadActiveAssignment = () => {
		this.setState({ assignmentLoading: true });

		this.props.getTaskDetails(this.props.assignmentId).then(async () => {
			let tasks = this.props.assignment.tasks.filter(t => t.id == this.props.taskId);
			tasks[0].selected = true;

			await this.setState({ activeTask: tasks, activeTaskLoading: false });

			if (this.props.assignment.assessmentType == 'BUDDY' || this.props.assignment.assessmentType == 'SELF') {
				await this.props.getStudentAssessments(this.props.assignmentId);
			}

			this.setState({ assignmentLoading: false });
		});

		this.props.getAssignmentAssessment(this.props.assignmentId);
	}

	checkTeamsDesktop = () => {
		this.setState({ isTeamsDesktop: navigator.userAgent.match(/Teams/i) });
	}

	selectedDocument = (fileUrl) => {
		this.setState({ selectedDocument: fileUrl });
		this.setState({ selectedVideo: '' });
		this.setState({ selectedImage: '' });
		this.setState({ selectedAudio: '' });
	}

	selectedVideo = (fileUrl) => {
		this.setState({ selectedDocument: '' });
		this.setState({ selectedVideo: fileUrl });
		this.setState({ selectedImage: '' });
		this.setState({ selectedAudio: '' });
	}

	selectedImage = (fileUrl) => {
		this.setState({ selectedDocument: '' });
		this.setState({ selectedVideo: '' });
		this.setState({ selectedImage: fileUrl });
		this.setState({ selectedAudio: '' });
	}

	selectedAudio = (fileUrl) => {
		this.setState({ selectedDocument: '' });
		this.setState({ selectedVideo: '' });
		this.setState({ selectedImage: '' });
		this.setState({ selectedAudio: fileUrl });
	}

	removeDocument = (fileName) => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-you-want-to-delete'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then((result) => {
			if (result.value != null) {
				this.setState({ assignmentAttachementLoading: true });

				this.props.deleteAssignmentUserDocument(this.props.assignmentId, fileName).then(() => {
					this.loadUserAssignmentAttachedDocument(this.props.taskId);
				})
			}
		});
	}

	toggleSidebar = () => {
		this.setState({ sidebar: !this.state.sidebar });
	}

	toggleConversation = () => {
		this.setState({ conversation: !this.state.conversation });
	}

	toggleIcon(flip) {
		return (
			<i className="cl-container">
				<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" className={flip ? 'i-90' : 'a-90'} style={{ height: '7px', width: '15px' }}>
					<path id="Path_50" data-name="Path 50" className="cls-1" d="M11.361,1.4,6.38,5.9,1.4,1.4" />
				</svg>
			</i>
		);
	}

	onStatusSelect = (item, value) => {
		if (!this.isTaskAssessable()) {
			api.put("assignments/Student/Status?assignmentTaskId=" + item.id + "&status=" + value.value).then(() => {
				this.props.addError(this.props.translate('changes-saved'), 'info');

				item.status = value.value;
				this.loadInit(this.props.taskId);
			});

			return;
		}

		api.put("assignments/Student/Status?assignmentTaskId=" + item.id + "&status=" + value.value).then(() => {
			this.props.addError(this.props.translate('changes-saved'), 'info');
			item.status = value.value;
		});
	}

	isTaskAssessable = () => {
		return (
			this.props.assignmentTask.status === 'ASSIGNMENT_TASK_SUBMITTED' ||
			this.props.assignmentTask.status === 'ASSIGNMENT_TASK_NOT_COMPLETE' ||
			this.props.assignmentTask.status === 'ASSIGNMENT_TASK_CAN_DEVELOP'
		);
	}

	assessmentSubmitting = (submitting) => {
		this.setState({ assessmentSubmitting: submitting });
	}

	onAssessmentSubmit = (values) => {

		return new Promise((resolve) => {
			const { assignmentTask } = this.props;
			if (values.id != null) {
				const existing = this.props.assessments.find((assessment) => assessment.id == values.id);
				this.props.updateAssessment(values, existing).then(async () => {
					await this.props.getAssignmentAssessment(this.props.assignmentId);
					this.props.addError(this.props.translate('changes-saved'), 'info');
					this.setState({ unsavedValues: null });
					resolve(1);
				});

				return true;
			}

			let data = {
				...values,
				assignmentId: this.props.assignment.id,
				referenceId: assignmentTask.id,
				studentId: assignmentTask.assignedTo,
			};

			this.props.createAssessments([data]).then(async () => {
				await this.props.getAssignmentAssessment(this.props.assignmentId);
				this.props.addError(this.props.translate('changes-saved'), 'info');
				this.setState({ unsavedValues: null });
				resolve(1);
			});
		});
	}

	goPrevious = () => {
		if (!this.props.previousEnable) {
			return;
		}

		if (!this.isTaskAssessable()) {
			this.props.goPrevious();
			return;
		}

		if (this.state.unsavedValues != null) {
			swal.fire({
				title: this.props.translate('are-you-sure'),
				text: this.props.translate("There are changes that haven't been saved. Are you sure you want to leave the page"),
				showCancelButton: true,
				cancelButtonText: this.props.translate('No'),
				confirmButtonText: this.props.translate('Yes'),
			}).then((result) => {
				if (result.value != null) {
					this.setState({ unsavedValues: null });

					if (this.clickChildSubmit != null) {
						this.clickChildSubmit().then(() => {
							this.props.goPrevious();
						});
					} else {
						this.props.goPrevious();
					}
				}
			});
		} else {
			if (this.clickChildSubmit != null) {
				this.clickChildSubmit().then(() => {
					this.props.goPrevious();
				});
			} else {
				this.props.goPrevious();
			}
		}
	}

	goNext = () => {
		if (!this.props.nextEnable) {
			return;
		}

		if (!this.isTaskAssessable()) {
			this.props.goNext();
			return;
		}

		if (this.state.unsavedValues != null) {
			swal.fire({
				title: this.props.translate('are-you-sure'),
				text: this.props.translate("There are changes that haven't been saved. Are you sure you want to leave the page"),
				showCancelButton: true,
				cancelButtonText: this.props.translate('No'),
				confirmButtonText: this.props.translate('Yes'),
			}).then((result) => {
				if (result.value != null) {
					this.setState({ unsavedValues: null });

					if (this.clickChildSubmit != null) {
						this.clickChildSubmit().then(() => {
							this.props.goNext();
						});
					} else {
						this.props.goNext();
					}
				}
			});
		} else {

			if (this.clickChildSubmit != null) {
				this.clickChildSubmit().then(() => {
					this.props.goNext();
				});
			} else {
				this.props.goNext();
			}
		}
	}

	updateUnsavedValues = (newValue, studentId, referenceId) => {
		if (
			newValue == null ||
			studentId !== this.props.assignmentTask?.students[0]?.userId ||
			referenceId !== this.props.assignmentTask?.id
		) {
			this.setState({ unsavedValues: null });
		} else {
			this.setState((prevState) => ({
				unsavedValues: {
					...newValue,
					studentId: studentId,
					referenceId: referenceId
				}
			}));
		}
	};


	renderAssess = () => {
		let conversation = null;
		if (this.props.conversations != null) {
			conversation = this.props.conversations.find((conv) => {
				return conv.memberships.find((member) => {
					return member.membershipID == this.props.assignmentTask.students[0].userId;
				});
			});
		}
		const student = this.props.assignmentTask.students[0];

		return (
			<div style={{ marginBottom: '.75rem' }}>
				<div style={{ display: 'flex' }}>
					<div style={{ cursor: 'pointer' }} onClick={this.goPrevious}>
						{this.toggleIcon(false)}
					</div>

					<div style={{ flex: 1, display: 'flex', textAlign: 'center', alignItems: 'center' }}>
						<span style={{ fontWeight: 500, flex: '1' }}>
							{student ?
								<DisplayName
									firstName={student.firstName}
									lastName={student.lastName}
									email={student.email}
									dynamicStringWidth={true}
									showEmail={true}
								/>
								:
								this.props.assignmentTask.groupName
							}
							{this.state.assessmentSubmitting ?
								<span className="assessment-publishing">
									<Spinner small />
								</span>
								:
								<span className="conversation-trigger" onClick={this.toggleConversation}>
									{conversation != null &&
										conversation.unreadMessages > 0 ?
										<div className="badge">
											<span>{conversation.unreadMessages}</span>
										</div>
										: null}

									<Icon name="Message" bw={conversation == null} />
								</span>
							}
						</span>
					</div>

					<div style={{ cursor: 'pointer' }} onClick={this.goNext}>
						{this.toggleIcon(true)}
					</div>
				</div>

				<div className="clearfix"></div>
				{this.renderStatus()}
			</div>
		);
	}

	renderNoDocuments = () => {
		return <div style={{ textAlign: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
			<strong>{this.props.translate('no-files-found')}</strong>
		</div>;
	}

	getParameterByName(name, url) {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	onClickDocumentFunc = (file) => {
		let notOfficeFile = file.webUrlViewMode === '';
		let mp4File = notOfficeFile && /.mp4$/.test(file.webUrl);
		let imageFile = notOfficeFile && /(.png|.jpg|.gif|.jpeg)$/.test(file.webUrl);
		let m4aFile = notOfficeFile && /.m4a$/.test(file.webUrl);
		const url = file.webUrlViewMode.replace('#', '?');
		const token = jwtDecode(decodeURI(this.getParameterByName('access_token', url)));

		if (Moment.unix(token.exp).utc().isBefore(Moment())) {
			this.setState({
				assignmentAttachementLoading: true,
				selectedDocument: '',
				selectedVideo: '',
				selectedImage: '',
				selectedAudio: '',
			});

			this.props.assignment.tasks.forEach(t => t.selected = false);

			this.props.getFolderFiles(this.props.assignmentId, this.props.taskId).then(() => {
				this.setState({ assignmentAttachementLoading: false });

				let newFile = this.props.folderFiles.find(newFile => {
					return newFile.eTag == file.eTag;
				});

				if (newFile != null) {
					if (!notOfficeFile) {
						this.selectedDocument(newFile.webUrlViewMode)
					} else if (mp4File) {
						this.selectedVideo(newFile.webUrl)
					} else if (imageFile) {
						this.selectedImage(newFile.webUrl)
					} else if (m4aFile) {
						this.selectedAudio(newFile.webUrl)
					}
				}
			});
		} else {
			if (!notOfficeFile) {
				this.selectedDocument(file.webUrlViewMode)
			} else if (mp4File) {
				this.selectedVideo(file.webUrl)
			} else if (imageFile) {
				this.selectedImage(file.webUrl)
			} else if (m4aFile) {
				this.selectedAudio(file.webUrl)
			}
		}
	}

	renderWopiFrame = () => {
		const { selectedDocument } = this.state;

		if (this.props.folderFiles.length <= 0) {
			return <div style={{ textAlign: 'center' }}>
				<strong>{this.props.translate('No files to view.')}</strong>
			</div>;
		}

		if (sniffer.isDevice && this.inIframe()) {
			return <div className="df aic jcc y fc" style={{ padding: '3rem' }}>
				<div className="warning-box" style={{ width: 'auto', marginBottom: '2rem' }}>
					<div style={{ alignSelf: 'flex-start' }}>
						<Icon name="Info_Blue" />
					</div>

					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<p>
							{this.props.translate("We’re sorry but we can’t display the file in Haldor at the moment. But you can open it by clicking “Open file in workspace”.")}
						</p>
					</div>
				</div>

				<a href={selectedDocument} target="_blank">
					<Button type="secondary">
						<Icon name="External" />
						{this.props.translate("open-file-in-workspace")}
					</Button>
				</a>
			</div>
		}

		return (
			<div>
				{selectedDocument ?
					<WopiFrame activeFile={selectedDocument} />
					: null}
			</div>
		);
	}

	renderMp4Frame = () => {
		const { selectedVideo } = this.state;

		if (this.props.folderFiles.length <= 0) {
			return <div style={{ textAlign: 'center' }}>
				<strong>No files to play.</strong>
			</div>;
		}

		return (
			<div>
				{selectedVideo ?
					<video
						src={selectedVideo}
						controls
						autoPlay
						width="100%"
						height="600"
					>
						{this.props.translate('Your browser does not support the video tag.')}
					</video>
					: null}
			</div>
		);
	}

	renderM4aFrame = () => {
		const { selectedAudio } = this.state;

		if (this.props.folderFiles.length <= 0) {
			return <div style={{ textAlign: 'center' }}>
				<strong>No files to play.</strong>
			</div>;
		}

		return (
			<div>
				{selectedAudio ?
					<audio controls preload="auto" autoPlay style={{ width: '100%' }} >
						<source src={selectedAudio} type="audio/mp4" />

						<p>
							{this.props.translate('Your browser does not support the audio tag.')}
						</p>
					</audio>
					: null}
			</div>
		);
	}

	renderImageFrame = () => {
		const { selectedImage } = this.state;

		if (this.props.folderFiles.length <= 0) {
			return <div style={{ textAlign: 'center' }}>
				<strong>{this.props.translate('No images to view.')}</strong>
			</div>;
		}

		return (
			<div>
				{selectedImage ?
					<div style={{ height: '600px', width: '100%', overflowX: 'scroll' }}>
						<img src={selectedImage} width="100%" />
					</div>
					: null}
			</div>
		);
	}

	renderView = () => {
		const {
			selectedDocument,
			selectedVideo,
			selectedImage,
			selectedAudio
		} = this.state;

		if (selectedDocument) {
			return this.renderWopiFrame();
		}

		if (selectedVideo) {
			return this.renderMp4Frame();
		}

		if (selectedImage) {
			return this.renderImageFrame();
		}

		if (selectedAudio) {
			return this.renderM4aFrame();
		}

		return null;
	}

	renderStatus = () => {
		return (
			<div className="status-change">
				<label>{this.props.translate('Work status')}</label>

				<SimpleSelect
					selectedValue={this.props.assignmentTask.status}
					name="assignment-status"
					disabled={this.props.assignment.created == '0001-01-01T00:00:00' || this.props.assignment.locked}
					item={this.props.assignmentTask}
					type="assignment"
					onChange={this.onStatusSelect}
					menuPortalTarget={document.body}
				/>
			</div>
		)
	}

	render() {
		const { assignmentAttachementLoading, activeTaskLoading, sidebarWide, sidebar, unsavedValues } = this.state;
		const { IsTeacher, IsSubmitted, assignmentTask } = this.props;

		let activeConversation = null;
		if (this.state.conversation) {
			activeConversation = this.props.assignmentTask.students[0];
		}

		let assessmentBlocks = null;
		let assessment = null;
		let seperatedAssessments = {
			peerAssessments: [],
			teacherAssessments: [],
			selfAssessments: []
		}

		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);
			seperatedAssessments.peerAssessments = assessmentBlocks.filter((block) =>
				block.assessmentType == ASSESSMENT_TYPES.PEER && block.resources.length > 0
			)?.sort((a, b) => a.title.localeCompare(b.title));

			seperatedAssessments.teacherAssessments = assessmentBlocks.filter((block) =>
				(block.assessmentType == ASSESSMENT_TYPES.TEACHER || block.assessmentType == null) && block.resources.length > 0
			).sort((a, b) => a.title.localeCompare(b.title));

			seperatedAssessments.selfAssessments = assessmentBlocks.filter((block) =>
				block.assessmentType == ASSESSMENT_TYPES.SELF && block.resources.length > 0
			).sort((a, b) => a.title.localeCompare(b.title));
		}

		if (this.props.assessments != null) {
			assessment = this.props.assessments.find((assessment) =>
				assessment.referenceId == this.props.assignmentTask.id && assessment.assessorType == ASSESSMENT_TYPES.TEACHER
			);
		}

		if (this.state.assignmentLoading) {
			return (
				<div>
					<Spinner center />
				</div>
			);
		}

		let selectedFile = this.props.folderFiles.find((file) => {
			let notOfficeFile = file.webUrlViewMode === '';
			let mp4File = notOfficeFile && /.mp4$/.test(file.webUrl);
			let imageFile = notOfficeFile && /(.png|.jpg|.gif|.jpeg)$/.test(file.webUrl);
			let m4aFile = notOfficeFile && /.m4a$/.test(file.webUrl);
			return !notOfficeFile && this.state.selectedDocument === file.webUrlViewMode ? file
				: mp4File && this.state.selectedVideo === file.webUrl ? file
					: imageFile && this.state.selectedImage === file.webUrl ? file
						: m4aFile && this.state.selectedAudio === file.webUrl ? file
							: null;
		});

		const fileOptions = this.props.folderFiles.map((file) => ({
			value: file,
			label: file.name,
		}));

		if (!this.isTaskAssessable() && unsavedValues != null) {
			this.setState({ unsavedValues: null });
		}

		return (
			<div className={sidebarWide ? "attached-user-documents wide" : "attached-user-documents"}>
				{unsavedValues != null && IsTeacher ? (
					<FormPrompt
						hasUnsavedChanges={unsavedValues != null}
						formPromptMessage={this.props.translate(
							"There are changes that haven't been saved. Are you sure you want to leave the page"
						)}
						currentPathContext={window.location.pathname}
					/>
				) : null}

				<Message
					assignment={this.props.assignment}
					activeUser={activeConversation}
					onClose={this.toggleConversation}
				/>
				<div className='iframe-section left-side'>
					{!assignmentAttachementLoading && this.props.folderFiles.length <= 0 ?
						this.renderNoDocuments()
						: !assignmentAttachementLoading && selectedFile ?
							<div className="choose-file-container">
								<div className="choose-file" >
									<label>{this.props.translate('Choose file')}:</label>
									<Select
										menuPortalTarget={document.body}
										name={'file'}
										options={fileOptions}
										onChange={(eventTarget) => this.onClickDocumentFunc(eventTarget.value)}
										className="simple-select"
										placeholder={selectedFile.name != null ? selectedFile.name : ''}
										components={{ IndicatorSeparator: () => null }}
									/>
								</div>
								<div className="action">
									{(!IsSubmitted || IsTeacher) && selectedFile.webUrlViewMode !== ''
										&& selectedFile && (selectedFile.webUrlViewMode && selectedFile.webUrlViewMode.length < 2047 || selectedFile.webUrlEditMode
											&& selectedFile.webUrlEditMode.length < 2047) ?
										<div>
											<a href={selectedFile.webUrlEditMode} target="_blank">
												<span>
													<Icon name="Pen_Small" />
												</span>
												{this.props.translate('Edit') + ' '}({this.props.translate('opens-in-new-tab')})
											</a>
										</div>
										: null}
									{!IsTeacher && !IsSubmitted ?
										<div>
											<Link to="#" onClick={() => { this.removeDocument(selectedFile.name) }}>
												<span>
													<Icon name="Bin" />
												</span>

												{this.props.translate('Remove')}
											</Link>
										</div>
										: null}
								</div>
							</div>
							: null}

					{assignmentAttachementLoading ? <Spinner center /> : this.renderView()}
				</div>

				{sidebar && IsTeacher ?
					<div className="sidebar">
						<div className="collapse color--meta" onClick={this.toggleSidebar}>
							{this.props.translate('fold-up')} <Icon name="ArrowRight" />
						</div>

						{IsTeacher ? this.renderAssess() : null}

						{IsTeacher && !activeTaskLoading ?
							this.isTaskAssessable() ?
								<AssessmentForm
									onSubmit={this.onAssessmentSubmit}
									items={[assignmentTask]}
									blocks={seperatedAssessments.teacherAssessments}
									initialValues={assessment?.referenceId === assignmentTask?.id ? assessment : null}
									referenceType="AssignmentTask"
									showStudents={false}
									updateUnsavedValues={this.updateUnsavedValues}
									unsavedValues={unsavedValues?.referenceId === assignmentTask?.id ? unsavedValues : null}
								/>
								:
								<div
									style={{ padding: "1rem" }}
									className="color--meta text--center"
								>
									{this.props.translate("Student-has-not-submitted-assignment")}
								</div>
							: null}
					</div>
					: IsTeacher ?
						<div className="expand color--meta" onClick={this.toggleSidebar}>
							{this.props.translate('fold-out')} <Icon name="ArrowLeft" />
						</div>
						: null
				}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		folderFiles: state.assignments.folderFiles,
		assignment: state.assignments.active_assignment,
		currentUser: state.user.currentUser,
		sections: state.sections.educationGroups,
		assessments: state.assessments.assessments,
		conversations: state.Conversation.referenceConversations,
		blocks: state.Blocks.reference,
	};
}

export default connect(mapStateToProps, {
	getFolderFiles,
	deleteAssignmentUserDocument,
	getTaskDetails,
	getSection,
	getStudentAssessments,
	getStudentAssessment,
	getAssignmentAssessment,
	addError,
	createAssessments,
	updateAssessment,
	getBlocksByReference,
	clearBlocksOnReference,
})(AttachedUserDocuments);
