import React from 'react';

const OneDriveIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			data-name="Lager 1"
			viewBox="0 0 800 800"
		>
			<defs>
				<linearGradient
					id="b"
					x1={102.2}
					x2={125}
					y1={797.8}
					y2={799.19}
					gradientTransform="matrix(25 0 0 -25 -2448 20501.24)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset={0} stopColor="#2086b8" />
					<stop offset={1} stopColor="#46d3f6" />
				</linearGradient>
				<linearGradient
					id="c"
					x1={122.04}
					x2={128.42}
					y1={799.97}
					y2={804.43}
					gradientTransform="matrix(25 0 0 -25 -2448 20501.24)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset={0} stopColor="#1694db" />
					<stop offset={1} stopColor="#62c3fe" />
				</linearGradient>
				<linearGradient
					id="d"
					x1={107.09}
					x2={121.91}
					y1={813.85}
					y2={805.25}
					gradientTransform="matrix(25 0 0 -25 -2448 20501.24)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset={0} stopColor="#0d3d78" />
					<stop offset={1} stopColor="#063b83" />
				</linearGradient>
				<linearGradient
					id="e"
					x1={97.11}
					x2={112.01}
					y1={801.32}
					y2={806.85}
					gradientTransform="matrix(25 0 0 -25 -2448 20501.24)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset={0} stopColor="#16589b" />
					<stop offset={1} stopColor="#1464b7" />
				</linearGradient>
				<mask
					id="a"
					width={800.01}
					height={500}
					x={0}
					y={150}
					maskUnits="userSpaceOnUse"
				>
					<path
						d="M195.74 650C87.64 650 0 564.19 0 458.33 0 354.8 83.83 270.45 188.65 266.79 231.88 196.79 310.36 150 400 150c115.77 0 212.92 78.05 239.57 183.34C728.29 334 800 404.64 800 491.67c0 84.65-75 158.33-153.19 158.11Z"
						data-name="mask0 87 7796"
						style={{
							fill: "#c4c4c4",
						}}
					/>
				</mask>
			</defs>
			<g
				style={{
					mask: "url(#a)",
				}}
			>
				<path
					d="M195.75 650a197 197 0 0 1-151.9-70.77l407.22-170.89 316.65 178.28C739.81 623 697.67 650 650 649.79c-71.86.21-348.43.21-454.25.21Z"
					style={{
						fill: "url(#b)",
					}}
				/>
				<path
					d="m639.46 332.87-188.39 75.47 316.65 178.28A155.48 155.48 0 0 0 800 491.67c0-87-71.71-157.66-160.43-158.33Z"
					style={{
						fill: "url(#c)",
					}}
				/>
				<path
					d="m176.61 267.57 274.45 140.76 188.39-75.46C612.63 227.82 515.59 150 400 150c-89.64 0-168.11 46.79-211.34 116.79q-6.08.21-12.05.78Z"
					style={{
						fill: "url(#d)",
					}}
				/>
				<path
					d="m43.84 579.22 407.22-170.89-274.44-140.77C77.49 277 0 358.79 0 458.33a188.44 188.44 0 0 0 43.84 120.89Z"
					style={{
						fill: "url(#e)",
					}}
				/>
			</g>
		</svg>)
};

export default OneDriveIcon;