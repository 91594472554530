import {
	GET_ASSESSMENTS,
	GET_ASSESSMENTS_COURSES,
	CLEAR_MATRIX_ASSESSMENT_OVERVIEW,
	CLEAR_ASSESSMENTS,
	GET_STUDENT_COURSES_GOALS,
	GET_STUDENT_ASSESSMENT_STATISTICS,
} from 'actions/assessments';

import {
	GET_STUDENT_ASSESSOR_ASSESSMENT,
	GET_STUDENT_ASSESSOR_ASSESSMENTS
} from 'actions/studentassessments';

const INITIAL_STATE = {
	courses: [],
	assessments: [],
	assessment: null,
	studentAssessment: null,
	studentAssessments: [],
	studentStatistics: null,
	studentOverview: null,
	matrixAssessments: [],
	userAssessments: [],
	progressSections: [],
	progressAssessmentStats: [],
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {

		case GET_ASSESSMENTS:
			return { ...state, assessments: action.payload };
		case GET_ASSESSMENTS_COURSES:
			return { ...state, courses: action.payload };

		case GET_STUDENT_ASSESSOR_ASSESSMENT:
			return { ...state, studentAssessment: action.payload };

		case GET_STUDENT_COURSES_GOALS:
			return { ...state, studentOverview: action.payload };

		case GET_STUDENT_ASSESSMENT_STATISTICS:
			return { ...state, studentStatistics: action.payload };

		case GET_STUDENT_ASSESSOR_ASSESSMENTS:
			return { ...state, studentAssessments: action.payload };

		case CLEAR_MATRIX_ASSESSMENT_OVERVIEW:
			return { ...state, matrixAssessments: INITIAL_STATE.matrixAssessments, assessments: [] };

		case CLEAR_ASSESSMENTS:
			return { ...state, assessments: [] };

		default:
			return state;
	}
}
