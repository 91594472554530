import React, { Component, Fragment, createRef, useRef, useState } from 'react';
import { Icon, translate, Button } from '@haldor/ui';
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import swal from 'sweetalert2';
import moment from 'moment';
import api from 'lib/api';

import { addError } from 'actions';
import { createAssessments, updateAssessment, updateAssessmentStatus } from 'actions/assessments';
import { updateActiveAssignment } from 'actions/assignments';
import { getRootUrl } from 'helpers/url';

import SimpleSelect from 'components/Inputs/SimpleSelect';
import StudentAssessorSelect from 'components/Inputs/StudentAssessorSelect';
import AssessmentForm from 'containers/Forms/AssessmentForm';
import Modal from 'containers/Modals/Modal';
import ClearBlockAssessments from 'containers/Forms/Assessment/ClearBlockAssessments';
import AssessmentsConsumer from 'components/BlockAssessments/Consumer';
import { isUserTeacher } from 'helpers/user';

import { Spinner } from 'UI';
import { ASSESSMENT_TYPES } from 'components/BlockAssessments/AssessmentTypes';
import { DataGridPro, useGridApiRef, gridClasses } from '@mui/x-data-grid-pro';
import { svSE, enUS, nbNO, daDK } from '@mui/x-data-grid/locales';
import { CircularProgress, Divider, Stack, IconButton, Tooltip } from '@mui/material';
import DropdownButtons from 'components/Inputs/DropdownButtons';
import Select from 'react-select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { updateAssignmentTaskSubmittedDate, updateAssignmentTaskStatus, updateAssignmentInList } from 'actions/assignments';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import DateTime from '_class/DateTime';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

/**
 * this is a map of the locale to the data grid language
 */
const localeMapDataGrid = {
	'EN-US': enUS,
	'SV-SE': svSE,
	'NB-NO': nbNO,
	'DA-DK': daDK,
};


const EditDateTimeCell = ({ params }) => {
	const [loadingSubmittedDate, setLoadingSubmittedDate] = useState(false);
	const dispatch = useDispatch();
	let initialDate = params.value ? params.value : '0001-01-01T00:00';

	const handleOnClose = async (newValue) => {
		params.api.stopCellEditMode({ id: params.id, field: 'submitted' });
	}

	const handleDateAccept = async (newValue) => {
		const formattedDate = newValue ? moment(newValue).utc() : '0001-01-01T00:00';
		if (formattedDate != initialDate) {
			setLoadingSubmittedDate(true);
			await dispatch(updateAssignmentTaskSubmittedDate({ ...params.row.task, submittedDate: formattedDate }));
			setLoadingSubmittedDate(false);
		}
		params.api.setEditCellValue({ id: params.id, field: 'submitted', value: newValue });
		params.api.stopCellEditMode({ id: params.id, field: 'submitted' });
	};

	if (loadingSubmittedDate) {
		return (<Stack sx={{ width: '100%' }} direction="row"
			justifyContent="center"
			alignItems="center" >
			<CircularProgress size={25} />
		</Stack>);
	}

	return (
		<div style={{ padding: '0rem' }}>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<Stack direction="row" alignItems="center" spacing={0}>
					<DateTimePicker
						defaultValue={params.value ? moment(params.value) : moment()}
						onAccept={handleDateAccept}
						onChange={(newValue) => { params.api.setEditCellValue({ id: params.id, field: 'submitted', value: newValue }); }}
						disableFuture={true}
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									border: 'none',
								},
								paddingRight: '8px'
							},
						}}
					/>
					<IconButton
						sx={{ marginLeft: '0rem' }}
						onClick={() => handleDateAccept(params.value)}
					>
						<SaveIcon />
					</IconButton>
					<IconButton
						sx={{ marginLeft: '0rem', padding: '4px' }}
						onClick={() => handleOnClose()}
					>
						<CloseIcon />
					</IconButton>
				</Stack>
			</LocalizationProvider>
		</div>
	);
};

class UserAssignment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			allSelected: false,
			assessmentOpen: false,
			studentAssessmentOpen: false,
			removeAssessments: false,
			assessTask: null,
			permissionLoading: false,
			selectedAssessment: null,
			datagridRef: null,
			openMenu: false,
			loadingSubmittedDate: false,
			datagridLanguage: enUS,
			sortModel: [{ field: 'name', sort: 'asc' }]
		};

		this.apiRef = createRef();
	}

	componentDidMount() {
		let datagridLanguage = enUS;

		if (this.props.currentUser != null && this.props.currentUser.lcid != null && localeMapDataGrid[this.props.currentUser.lcid.toUpperCase()] != null) {
			datagridLanguage = localeMapDataGrid[this.props.currentUser.lcid.toUpperCase()];
		}

		this.setState({ datagridLanguage: datagridLanguage });
	}

	closeAssessment = (skipPrompt) => {
		if (skipPrompt) {
			this.props.reload(false);
			this.setState({ assessmentOpen: false });
		} else {
			this.setState({ assessmentOpen: false });
		}
	};
	handleLocked = (selectedUsers, lock) => {
		let assignmentId = this.props.assignment.id;
		let addPermissons = [];
		let permissionData = [];
		selectedUsers.forEach((element) => {
			return element.assignmentTaskAssignees.map((task) => {
				permissionData.push(task);
				if (lock) {
					if (task.permission !== '0') {
						addPermissons.push(task);
					}
				} else if (task.permission === '0') {
					addPermissons.push(task);
				}
			});
		});
		if (addPermissons.length > 0) {
			this.setState({ permissionLoading: true });
			let tasks = this.props.assignment.tasks;
			if (lock) {
				api.delete(`assignments/${assignmentId}/workspacepermissions`, addPermissons).then((response) => {
					tasks.map((items) => {
						let object = response.data.find((x) => x.assignmentTaskId === items.id);
						if (object) {
							object.permission = '0';
							items.assignmentTaskAssignees = [object];
						}
					});
					let newObject = this.props.assignment;
					newObject.tasks = tasks;
					this.props.assignment.tasks.forEach((t) => (t.selected = false));
					this.setState({
						allSelected: false,
						permissionLoading: false,
					});
				})
					.catch(() => {
						this.setState({ allSelected: false, permissionLoading: false });
						this.props.assignment.tasks.forEach((t) => (t.selected = false));
					});
				return;
			}
			api.put(`assignments/${assignmentId}/workspacepermissions`, addPermissons)
				.then((response) => {
					tasks.map((items) => {
						let object = response.data.find((x) => x.assignmentTaskId === items.id);
						if (object) {
							items.assignmentTaskAssignees = [object];
						}
					});
					let newObject = this.props.assignment;
					newObject.tasks = tasks;
					this.props.assignment.tasks.forEach((t) => (t.selected = false));
					this.setState({
						allSelected: false,
						permissionLoading: false,
					});
				})
				.catch(() => {
					this.setState({ allSelected: false, permissionLoading: false });
					this.props.assignment.tasks.forEach((t) => (t.selected = false));
				});
		} else {
			this.setState({ allSelected: false });
			this.props.assignment.tasks.forEach((t) => (t.selected = false));
		}
	};

	getSelectedAssignmentTasks = () => {
		let selectedTasks = [];
		if (this.apiRef != null && this.apiRef.current != null) {
			const selectedRows = this.apiRef.current.getSelectedRows();
			if (selectedRows != null) {
				const selectedValues = Array.from(selectedRows.values());
				const tasks = selectedValues.map((selectedValue) => selectedValue.task);
				selectedTasks = tasks;
			}
		}
		return selectedTasks;
	}

	/**
	 * Show the assessment modal for single specific assignmentTask or for all selecteded assignmentTasks from table
	 * @param {*} assignmentTask 
	 */
	openAssessment = (assignmentTask = null) => {
		if (assignmentTask != null) {
			this.props.assignment.tasks.forEach((t) => (t.selected = false));
			assignmentTask.selected = true;
		} else {
			var selectedAssignmentTasks = this.getSelectedAssignmentTasks();

			/**
			 * disable the selected assignmentTask who already have teacher assessment
			 */
			selectedAssignmentTasks.forEach((student) => {
				var assessment = this.props.assessments.find((t) => t.studentId == student.assignedTo && t.assessorType == ASSESSMENT_TYPES.TEACHER);
				if (assessment != null) {
					student.selected = false;
				} else {
					student.selected = true;
				}
			});
		}

		var selectedUsers = selectedAssignmentTasks.filter((t) => t.selected);

		if (selectedUsers.length > 0) {
			this.setState({ assessmentOpen: true });
		}
	};

	onAssessmentSubmit = (values) => {
		return new Promise((resolve) => {
			if (values.id != null) {
				const existing = this.props.assessments.find((assessment) => assessment.id == values.id);
				this.props.updateAssessment(values, existing).then(() => {
					resolve(1);
					this.closeAssessment(true);
				});

				return true;
			}

			const selectedTasks = this.props.assignment.tasks.filter(t => t.selected);
			let data = [];

			selectedTasks.forEach((task) => {
				data.push({
					...values,
					assignmentId: this.props.assignment.id,
					referenceId: task.id,
					studentId: task.assignedTo,
				});
			});

			this.props.createAssessments(data).then(() => {
				resolve(1);
				this.closeAssessment(true);
			});
		});
	}

	openStudentAssessment = (user, selectedAssessment) => {
		this.setState({ assessTask: user, selectedAssessment: selectedAssessment }, () => {
			this.setState({ studentAssessmentOpen: true });
		});
	};

	closeStudentAssessment = (event, skipPrompt) => {
		if (skipPrompt) {
			this.setState({ studentAssessmentOpen: false });
		} else {
			this.setState({ studentAssessmentOpen: false });
		}
	};

	onStatusSelect = async (item, selectedOption, params) => {
		item.disabled = true;

		this.props.updateAssignmentTaskStatus(this.props.assignment.id, item.id, selectedOption.value).then((response) => {
			this.props.addError(this.props.translate('changes-saved'), 'info');

			item.disabled = false;
			item.status = selectedOption.value;

			let assignment = this.props.assignment;
			var assignmentTasks = assignment.tasks;

			var stats = assignment.stats;
			if (stats != null && stats.length > 0) {
				stats.forEach((stat) => {
					stat.count = assignmentTasks.filter((t) => t.status == stat.assignmentTaskStatusId).length;
				})
			}

			assignment.stats = stats;
			this.props.updateAssignmentInList(assignment);
			this.props.updateActiveAssignment(assignment);

			if (selectedOption.value == 'ASSIGNMENT_TASK_SUBMITTED') {
				const submitted = moment().utc().format('YYYY-MM-DD HH:mm:ss');
				item.submittedDate = submitted;
				this.apiRef.current.updateRows([{ id: params.id, submitted }]);;
			}
		});
	};

	onAssessmentStatusSelect = (item, value) => {
		var assessment = this.props.assessments.find((t) =>
			t.studentId == item.assignedTo && t.assessorType == ASSESSMENT_TYPES.TEACHER
		);

		if (assessment != null) {
			this.props.updateAssessmentStatus(assessment.id, value.value).then(() => {
				this.props.addError(this.props.translate('changes-saved'), 'info');
			});
		}
	};

	onStudentAssessorChange = (task, studentId) => {
		api.put(`assignments/task/${task.id}/studentassessor/${studentId}`, null).then((response) => {
			this.props.addError(this.props.translate('student-assessor-saved'), 'info');

			//should update all the assessment type relation records which are peer assessments
			task.assessmentTypeRelationships?.forEach(s => {
				if (s.assessmentType == ASSESSMENT_TYPES.PEER) {
					s.assessor = event;
				}
			});

			task.studentAssessorId = studentId;
			this.setState({});
		});
	};

	markForConversation = (task) => {
		const student = task.students[0];
		if (this.props.onConversation != null) {
			this.props.onConversation(student);
		}
	};

	publishAllAssessments = () => {
		const { addError, translate, assessments } = this.props;
		let { tasks, publishedAssessments } = this.props.assignment;

		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('publish-all-assessments-warning'),
			showCancelButton: true,
			focusConfirm: false,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('yes-publish'),
		}).then((result) => {
			if (result.value != null) {
				var requests = 0;
				tasks.map((task) => {
					if (
						task.status == 'ASSIGNMENT_TASK_SUBMITTED' ||
						task.status == 'ASSIGNMENT_TASK_NOT_COMPLETE' ||
						task.status == 'ASSIGNMENT_TASK_CAN_DEVELOP'
					) {
						let userAssessment = assessments.find((ass) => {
							return ass.referenceId == task.id;
						});

						if (userAssessment != null) {
							if (userAssessment.status != 'ASSESSMENT_PUBLISHED') {
								requests++;
								api.put(
									`assessments/status?assessmentId=${userAssessment.id}&status=ASSESSMENT_PUBLISHED`,
									null
								).then(() => {
									requests--;

									if (requests == 0) {
										addError(translate('changes-saved'), 'info');
										this.props.reload(true);
									}
								});
							}
						}
					}
				});

				/**
				 * Update the publishedAssessments statistics and update in list in reducer.
				 */
				let assignment = this.props.assignment;
				assignment.publishedAssessments = publishedAssessments + requests;
				this.props.updateAssignmentInList(assignment);
			}
		});
	};

	openRandomizeStudentAssessors = () => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('buddy-assessment-randomize-assessor-prompt'),
			showCancelButton: true,
			focusConfirm: false,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then((result) => {
			if (result.value != null) {
				api.put(`assignments/${this.props.assignment.id}/studentassessors`, null).then(() => {
					this.props.reload(true);
					this.props.addError(this.props.translate('student-assessor-saved'), 'info');
				});
			}
		});
	};

	studentAssessmentButton = (assignmentTask) => {
		const { assignment } = this.props;
		let disabled = false;

		if (
			assignment.status == 'ASSIGNMENT_DRAFT' ||
			assignmentTask.id == -1
		) {
			disabled = true;
		}

		return (
			<Button type='secondary' disabled={disabled} onClick={() => this.handleClickOpenWorkspace(assignmentTask)}>
				{this.props.translate('assess')}
			</Button>
		);
	};

	editSubmittedDate = async (task) => {
		this.apiRef.current.startCellEditMode({ id: task.id, field: 'submitted' });
	}

	openSharepointWorkspace = (task) => {
		let section = this.props.assignment?.section;
		if (section == null) return null;

		let rootUrl = section.classroomUrl;
		let rootUri = new URL(rootUrl);
		let segments = rootUri.pathname.split('/');
		rootUrl = rootUrl.substring(0, rootUrl.indexOf('/' + segments[1] + '/'));

		if (task.workspace) {
			window.open(rootUrl + task.workspace, '_blank');
		}
	};

	handleClickOpenWorkspace = (task) => {
		if (task.workspace) {
			this.props.history.push(getRootUrl() +
				'assignment/' +
				this.props.assignment.id +
				'/task/' +
				task.id +
				'/documents');
		}
	}

	handleToggle = () => {
		this.setState({ openMenu: !this.state.openMenu });
	}

	renderButtonGroups = () => {
		return (
			<div style={{ width: '20px' }}>
			</div>
		);
	}

	renderTable = () => {
		let assessmentBlocks = null;
		let seperatedAssessments = {
			peerAssessments: [],
			teacherAssessments: [],
			selfAssessments: []
		}

		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);

			seperatedAssessments.peerAssessments = assessmentBlocks.filter((block) =>
				block.assessmentType == ASSESSMENT_TYPES.PEER && block.resources.length > 0
			)?.sort((a, b) => a.title.localeCompare(b.title));

			seperatedAssessments.teacherAssessments = assessmentBlocks.filter((block) =>
				(block.assessmentType == ASSESSMENT_TYPES.TEACHER || block.assessmentType == null) && block.resources.length > 0
			).sort((a, b) => a.title.localeCompare(b.title));

			seperatedAssessments.selfAssessments = assessmentBlocks.filter((block) =>
				block.assessmentType == ASSESSMENT_TYPES.SELF && block.resources.length > 0
			).sort((a, b) => a.title.localeCompare(b.title));
		}

		const columns = [
			{
				field: 'lock',
				headerName: '',
				width: 20,
				type: null,
				renderCell: (params) => {
					if (params.row.locked) {
						return (<span style={{ marginLeft: ".5rem" }}><Icon name='Lock' /></span>);
					}
				},
			},
			{
				field: 'name',
				headerName: 'Name',
				minWidth: 200,
				type: null,
				renderCell: (params) => {
					let row = params.row;
					let student = row.student;

					var recentlyLeftIcon = (<Tooltip title={this.props.translate('Left the group') + ' ' + moment.utc(student.endDate).local().format('YYYY-MM-DD')} placement="top" arrow>
						<ErrorRoundedIcon style={{ color: 'var(--color--blue)' }} />
					</Tooltip>);

					return (<Stack
						direction="row"
						spacing={0.5}
						sx={{
							justifyContent: "flex-start",
							alignItems: "center",
						}}
					><span>{student.endDate ? recentlyLeftIcon : null}</span> <span>{params.row.name}</span></Stack>);
				},
				valueGetter: (value, row) => {
					return row.lastName;
				},
			},
			{
				field: 'status',
				headerName: this.props.translate('Work status'),
				minWidth: 230,
				flex: 1,
				type: null,
				renderCell: (params) => {
					let task = params.row.task;
					if (task == null) {
						const options = [{ value: 'ASSIGNMENT_TASK_NOT_ASSIGNED', label: this.props.translate('Not assigned') }];
						const selectedOption = { value: 'ASSIGNMENT_TASK_NOT_ASSIGNED', label: this.props.translate('Not assigned') }
						return (<Select isDisabled={true} menuPortalTarget={document.body} className="simple-select" defaultValue={selectedOption} options={options} />);
					}

					let disabled = task?.disabled;

					return (
						<SimpleSelect
							selectedValue={task.status}
							name='user-status'
							disabled={
								this.props.assignment.created == '0001-01-01T00:00:00' ||
								this.props.printable == true ||
								this.props.assignment.locked ||
								disabled
							}
							item={task}
							type='assignment'
							onChange={(item, status) => this.onStatusSelect(item, status, params)}
							menuPortalTarget={document.body}
						/>
					);
				},
			},
			{
				field: 'submitted',
				headerName: this.props.translate('Submitted'),
				minWidth: 255,
				flex: 1,
				editable: true,
				renderCell: (params) => {
					let taskSubmittedLate = params.value != "" && params.value > params.row.assignmentDueDate;
					let submittedDate = params.value != "" ? new DateTime(moment(params.value).utc()).getLongDateWithTime() : null;

					return (<Stack direction="row" spacing={0.8}>
						{taskSubmittedLate ? <div><Icon name='Alert_Red' /></div> : null}
						<div className='mb-2'>
							{submittedDate}
						</div>
					</Stack>)
				},
				renderEditCell: (params) => (<EditDateTimeCell params={params} />),
				valueGetter: (value, row) => {
					let submitted = value;
					let localTime = submitted ? moment.utc(submitted).local().format('YYYY-MM-DD HH:mm') : '';
					return localTime;
				},
			},
			{
				field: 'assessment-status',
				headerName: this.props.translate('assessment-status'),
				minWidth: 240,
				flex: 1,
				renderCell: (params) => {
					let task = params.row.task;

					if (task == null) return null;

					let disabled = task.disabled;
					let teacherAssessment = params.row.teacherAssessment;

					return (<SimpleSelect
						selectedValue={teacherAssessment != null ? teacherAssessment.status : ''}
						name='assessment-status'
						disabled={
							this.props.assignment.created == '0001-01-01T00:00:00' ||
							this.props.printable == true ||
							this.props.assignment.locked ||
							disabled ||
							teacherAssessment == null
						}
						item={task}
						type='assessment'
						onChange={this.onAssessmentStatusSelect}
						menuPortalTarget={document.body}
					/>)
				},
				valueGetter: (value, row) => {
					let teacherAssessment = row.teacherAssessment;
					return teacherAssessment ? teacherAssessment.status : '';
				},
			},
			{
				field: 'assessment',
				headerName: this.props.translate('assessment'),
				minWidth: 110,
				flex: 1,
				renderCell: (params) => {
					let task = params.row.task;

					if (task == null) return null;

					if (this.props.printable == true ||
						this.props.assignment.created == '0001-01-01T00:00:00' ||
						this.props.assignment.locked || seperatedAssessments.teacherAssessments == null || seperatedAssessments.teacherAssessments?.length <= 0) {
						return null;
					}
					else {
						return this.studentAssessmentButton(task)
					}
				}
			}
		];

		if (seperatedAssessments.peerAssessments?.length > 0) {
			columns.push({
				field: 'peer-review',
				headerName: this.props.translate('Peer review'),
				minWidth: 300,
				flex: 1,
				renderCell: (params) => {
					let peerAssessment = params.row.peerAssessment;
					let studentAssessor = params.row.studentAssessor;
					let task = params.row.task;
					if (task == null) return null;

					let disabled = task.disabled;
					let student = params.row.student;

					let disabledSelectBox = this.props.assignment.created == '0001-01-01T00:00:00' ||
						this.props.printable == true ||
						this.props.assignment.locked ||
						disabled;

					if (peerAssessment == null) {
						return (<StudentAssessorSelect
							options={this.props.assignment.tasks}
							disabled={disabledSelectBox == true}
							name='studentAssessor'
							item={task}
							defaultValue={
								task.studentAssessorId != null ? task.studentAssessorId : null
							}
							onChange={(event) => {
								this.onStudentAssessorChange(task, event);
							}}
							placeholder={this.props.translate('select-student')}
							section={this.props.activeSection}
						/>);
					} else {
						if (peerAssessment?.status == 'ASSESSMENT_STARTED') {
							return (<SimpleSelect
								selectedValue={peerAssessment != null ? peerAssessment.status : ''}
								name='assessment-status'
								disabled={true}
								item={student}
								type='assessment'
								menuPortalTarget={document.body}
							/>)
						} else {
							return (<Button
								type='secondary'
								onClick={() => this.openStudentAssessment(student, peerAssessment)}
							>
								{studentAssessor != null
									? this.props.translate('assessed-by') +
									' ' +
									studentAssessor.groupName
									: this.props.translate('show-assessment')}
							</Button>)
						}
					}
				}
			});
		}

		if (seperatedAssessments.selfAssessments?.length > 0) {
			columns.push({
				field: 'self-assessment',
				headerName: this.props.translate('self-assessment'),
				minWidth: 165,
				flex: 1,
				renderCell: (params) => {
					let selfAssessment = params.row.selfAssessment;
					let task = params.row.task;
					let student = params.row.student;

					if (task == null) return null;

					if (selfAssessment != null) {
						return (<Button
							type='secondary'
							onClick={() => this.openStudentAssessment(student, selfAssessment)}
						>
							{this.props.translate('show-assessment')}
						</Button>);
					} else {
						return (
							<Button type='secondary' disabled={true}>
								{this.props.translate('show-assessment')}
							</Button>)
					}

				}
			});
		}

		if (this.props.services.conversations) {
			columns.push({
				field: 'conversations',
				headerName: '',
				disableColumnMenu: true,
				sortable: false,
				align: 'right',
				minWidth: 160,
				flex: 1,
				renderCell: (params) => {
					let task = params.row.task;
					let editable = params.api?.getCellParams(params.id, 'submitted')?.isEditable;

					if (task == null) return null;

					let conversation = params.row.conversation;

					let clickOptions = [{ title: this.props.translate('open-workspace'), onClick: () => this.openSharepointWorkspace(task) }];

					if (editable)
						clickOptions.push({ title: this.props.translate('Edit submission time'), onClick: () => this.editSubmittedDate(task) });

					return (
						<Stack
							direction="row"
							divider={<Divider orientation="vertical" flexItem />}
							spacing={2}
						>
							<div>
								<DropdownButtons
									options={clickOptions}
									style={{ border: '1px solid #c9c9c9', color: 'black' }}
								></DropdownButtons>
							</div>
							<div>
								<Link
									to="#"
									style={{ cursor: 'pointer' }}
									onClick={(e) => this.markForConversation(task)}
								>
									<div
										style={{
											width: '1.75rem',
											position: 'relative',
											marginTop: '0.5rem',
										}}
									>
										{conversation != null ? (
											<Icon name="Message" />
										) : (
											<Icon name="Message" bw />
										)}

										{conversation != null && conversation.unreadMessages > 0 ? (
											<div className="badge">
												<span>{conversation.unreadMessages}</span>
											</div>
										) : null}
									</div>
								</Link>
							</div>
						</Stack>
					);
				}
			});
		}

		const rows = this.props.assignment.tasks.map((task) => {
			let student = task.students.length > 0 ? task.students[0] : null;
			let teacherAssessment = null;
			if (this.props.assessments != null) {
				teacherAssessment = this.props.assessments.find(
					(t) =>
						t.studentId == task.assignedTo &&
						t.assessorType == ASSESSMENT_TYPES.TEACHER
				);
			}

			let peerAssessment = this.props.assessments.find(
				(t) =>
					t.studentId == task.assignedTo &&
					t.assessorType == ASSESSMENT_TYPES.PEER
			);

			let selfAssessment = this.props.assessments.find(
				(t) =>
					t.studentId == task.assignedTo &&
					t.assessorType == ASSESSMENT_TYPES.SELF &&
					t.status != 'ASSESSMENT_STARTED'
			);

			let disabled = task.disabled;
			task.studentAssessorId = task.assessmentTypeRelationships.find(
				(s) => s.assessmentType == ASSESSMENT_TYPES.PEER
			)?.assessor;
			let studentAssessor = peerAssessment != null
				? this.props.assignment.tasks.find((t) => t.assignedTo == task.studentAssessorId)
				: null;

			let conversation = null;
			if (this.props.conversations != null) {
				conversation = this.props.conversations.find((conv) => {
					return conv.memberships.find((member) => {
						return member.membershipID == task.assignedTo;
					});
				});
			}

			let assigmentsTask = task?.assignmentTaskAssignees;
			let locked = assigmentsTask.filter((x) => x.permission === '0').length > 0 ? true : false;

			let linkTextVisible =
				seperatedAssessments.teacherAssessments?.length > 0 &&
				seperatedAssessments.selfAssessments?.length > 0 &&
				seperatedAssessments.peerAssessments?.length > 0;

			let studentIsCurrentlyMemberInGroup = this.props.activeSection.students.find((s) => s.userId == student.userId);

			if (!studentIsCurrentlyMemberInGroup && this.props.activeSection.historicMembers != null && this.props.activeSection.historicMembers.length > 0) {
				var historicalMembership = this.props.activeSection.historicMembers.find((m) => m.userId == student.userId);
				if (historicalMembership) {
					student.endDate = historicalMembership.endDate;
				}
			}

			return {
				task: task,
				student: student,
				id: task.id,
				lastName: student && student.lastName != null ? student.lastName : '',
				name: student ? `${student.firstName ? student.firstName : task.groupName} ${student.lastName ? student.lastName : ''}` : '',
				status: task.status,
				submitted: task.submittedDate == null || task.submittedDate == '0001-01-01T00:00:00' ? null : task.submittedDate,
				teacherAssessment: teacherAssessment,
				linkTextVisible: linkTextVisible,
				conversation: conversation,
				peerAssessment: peerAssessment,
				selfAssessment: selfAssessment,
				studentAssessor: studentAssessor,
				disabled: disabled,
				assignmentDueDate: this.props.assignment.dueDate,
				locked: locked,
			};
		});

		// Add unassigned students to the list
		const unassignedStudents = this.props.activeSection.students.filter(student => {
			let studentTask = this.props.assignment.tasks.find(task => {
				return task.assignmentTaskAssignees.some(assignee => assignee.assigneeId === student.userId);
			});
			const deleted = studentTask != null ? studentTask.markedForDeletion : false;
			return studentTask == null || deleted;
		}).sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""));

		let negitiveId = -1;

		unassignedStudents.forEach(student => {
			rows.push({
				task: null,
				student: student,
				id: negitiveId,
				lastName: student && student.lastName ? student.lastName : '',
				name: student ? `${student.firstName ? student.firstName : ''} ${student.lastName ? student.lastName : ''}` : '',
				status: null,
				submitted: null,
				teacherAssessment: null,
				linkTextVisible: null,
				conversation: null,
			});

			negitiveId--;
		})

		const handleCellEditStop = (params, event) => {

			/**
			 * Prevent mui to auto close, only apply on submitted column
			 */
			if (params.field == "submitted") {
				event.defaultMuiPrevented = true;
			}
		};

		return (
			<div style={{ width: '100%' }}>
				<DataGridPro
					sx={{
						width: '100%', '--DataGrid-overlayHeight': '300px', [`& .${gridClasses.cell}`]: {
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
						},
					}}
					apiRef={this.apiRef}
					localeText={this.state.datagridLanguage != null ? {
						...this.state.datagridLanguage.components.MuiDataGrid.defaultProps.localeText,
					} : null}
					checkboxSelection
					disableRowSelectionOnClick
					isRowSelectable={(params) => params.row.task != null}
					isCellEditable={this.isCellEditable}
					autosizeOptions={{
						columns: ['name', 'status', 'assessment-status'],
						includeOutliers: true,
						includeHeaders: false,
					}}
					rows={rows}
					columns={columns}
					getRowHeight={() => 'auto'}
					pagination
					paginationMode="client"
					pageSizeOptions={[50, 100]}
					pageSize={50}
					autoPageSize={false}
					autosizeOnMount={false}
					density='standard'
					disableAutosize={true}
					disableColumnPinning={true}
					slots={{ footer: this.renderFooter }}
					disableColumnFilter={true}
					disableColumnSelector={true}
					autosizeOnMount={true}
					sortModel={this.state.sortModel}
					onSortModelChange={(model) => this.setState({ sortModel: model })}
					onCellEditStop={handleCellEditStop}
				></DataGridPro>
			</div>
		);
	}


	isCellEditable = (params) => {
		let field = params.field;
		let task = params.row.task;

		switch (field) {
			case 'submitted':
				if (task != null && task.status != 'ASSIGNMENT_TASK_NOT_STARTED')
					return true;
				break;
			default:
				return false;
		}
	}

	/**
	 * Change the status of selected assignment tasks to submitted if current status is not started or started
	 */
	changeToSubmitted = () => {
		this.setState({ loading: true });
		var selectedAssignmentTasks = this.getSelectedAssignmentTasks();

		let requests = [];
		let assignmentTasks = [];
		selectedAssignmentTasks.forEach((assignmentTask) => {
			if (
				assignmentTask.status == 'ASSIGNMENT_TASK_NOT_STARTED' ||
				assignmentTask.status == 'ASSIGNMENT_TASK_STARTED'
			) {
				let promise = api
					.put(
						'assignments/Student/Status?assignmentTaskId=' +
						assignmentTask.id +
						'&status=ASSIGNMENT_TASK_SUBMITTED'
					)
					.then(() => {
						assignmentTask.status = 'ASSIGNMENT_TASK_SUBMITTED';
					});

				assignmentTasks.push(assignmentTask);
				requests.push(promise);
			}
		});

		Promise.all(requests).then(() => {
			assignmentTasks.forEach((item) => {
				const submitted = moment().utc().format('YYYY-MM-DD HH:mm:ss');
				item.submittedDate = submitted;
				this.apiRef.current.updateRows([{ id: item.id, submitted }]);;
			});

			this.setState({ loading: false });
		});

		if (requests.length == 0) {
			this.setState({ loading: false });
		}
	};

	renderFooter = () => {
		let selectedTasks = [];
		selectedTasks = this.getSelectedAssignmentTasks();

		let unpublishedAssessment = null;
		if (this.props.assessments != null) {
			unpublishedAssessment = this.props.assessments.find((ass) => {
				return ass.status != 'ASSESSMENT_PUBLISHED' && ass.assessorType == ASSESSMENT_TYPES.TEACHER;
			});
		}

		let disableAssessmentsButton = true;
		if (unpublishedAssessment != null) {
			disableAssessmentsButton = false;
		}

		let assessAllButtonError = false;
		let disableRemoveAssessmentButton = true;

		let changeToSubmittedError = selectedTasks.some(
			(user) =>
				user.status == 'ASSIGNMENT_TASK_STARTED' || user.status == 'ASSIGNMENT_TASK_NOT_STARTED'
		);

		selectedTasks.forEach((task) => {
			if (task.status == 'ASSIGNMENT_TASK_STARTED' || task.status == 'ASSIGNMENT_TASK_NOT_STARTED') {
				assessAllButtonError = true;
				return;
			}

			let studentId = task.students[0].userId;
			if (studentId != null) {
				let hasAssessment = null;
				if (this.props.assessments != null) {
					hasAssessment = this.props.assessments.findIndex(
						(ass) => ass.studentId == studentId && ass.assessorType == ASSESSMENT_TYPES.TEACHER
					);
				}

				if (hasAssessment > -1) {
					assessAllButtonError = true;
					disableRemoveAssessmentButton = false;
				}
			}
		});

		let assessmentBlocks = null;
		let assessment = null;
		let seperatedAssessments = {
			peerAssessments: [],
			teacherAssessments: [],
			selfAssessments: []
		}

		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);

			seperatedAssessments.peerAssessments = assessmentBlocks.filter((block) =>
				block.assessmentType == ASSESSMENT_TYPES.PEER && block.resources.length > 0
			)?.sort((a, b) => a.title.localeCompare(b.title));

			seperatedAssessments.teacherAssessments = assessmentBlocks.filter((block) =>
				(block.assessmentType == ASSESSMENT_TYPES.TEACHER || block.assessmentType == null) && block.resources.length > 0
			).sort((a, b) => a.title.localeCompare(b.title));

			seperatedAssessments.selfAssessments = assessmentBlocks.filter((block) =>
				block.assessmentType == ASSESSMENT_TYPES.SELF && block.resources.length > 0
			).sort((a, b) => a.title.localeCompare(b.title));
		}

		if (selectedTasks.length == 1 && this.props.assessments != null) {
			assessment = this.props.assessments.find((assessment) =>
				assessment.studentId == selectedTasks[0].assignedTo && assessment.assessorType == ASSESSMENT_TYPES.TEACHER
			)
		}

		return (<div style={{ marginBottom: '1rem' }}>
			{!this.props.printable ?
				<Fragment>
					<Button
						disabled={selectedTasks.length == 0 ? true : false}
						style={{ marginLeft: '1rem', marginTop: '1rem' }}
						onClick={() => this.handleLocked(selectedTasks, true)}
					>
						{this.state.permissionLoading ? <Spinner small center /> : null}
						{this.props.translate('lock assignment')}
					</Button>

					<Button
						disabled={this.state.permissionLoading || selectedTasks.length == 0 ? true : false}
						style={{ marginLeft: '1rem', marginTop: '1rem' }}
						onClick={() => this.handleLocked(selectedTasks)}
					>
						{this.state.permissionLoading ? <Spinner small center /> : null}
						{this.props.translate('unlock assignment')}
					</Button>
				</Fragment>
				: null}

			{!this.props.printable ? (
				<Button
					disabled={
						selectedTasks.length == 0 ||
						this.props.assignment.created == '0001-01-01T00:00:00' ||
						this.props.assignment.locked ||
						!changeToSubmittedError ||
						this.state.loading
					}
					style={{ marginLeft: '1rem', marginTop: '1rem' }}
					onClick={() => this.changeToSubmitted()}
				>
					{this.state.loading ? <Spinner small center /> : null}

					{this.props.translate('turn-in-selected')}
				</Button>
			) : null}

			{!this.props.printable ? (
				<Button
					disabled={
						selectedTasks.length == 0 ||
						this.props.assignment.created == '0001-01-01T00:00:00' ||
						this.props.assignment.locked ||
						assessAllButtonError
					}
					style={{ marginLeft: '1rem', marginTop: '1rem' }}
					onClick={() => this.openAssessment()}
				>
					{this.props.translate('assess-selected-students')}
				</Button>
			) : null}

			{!this.props.printable ? (
				<Button
					disabled={
						this.props.assignment.created == '0001-01-01T00:00:00' ||
						this.props.assignment.locked ||
						disableAssessmentsButton
					}
					style={{ marginLeft: '1rem', marginTop: '1rem' }}
					onClick={() => this.publishAllAssessments()}
				>
					{this.props.translate('publish-assessments')}
				</Button>
			) : null}

			{seperatedAssessments.peerAssessments?.length > 0 &&
				this.props.assignment.created != '0001-01-01T00:00:00' &&
				this.props.printable == false &&
				this.props.assignment.locked == false ? (
				<Button
					disabled={this.props.assignment.tasks?.every((t) => t.assessmentTypeRelationships?.some(s => s.assessmentType == ASSESSMENT_TYPES.PEER && s.assessor != null))}
					style={{ marginLeft: '1rem', marginTop: '1rem' }}
					onClick={() => this.openRandomizeStudentAssessors()}
				>
					{this.props.translate('assign-student-assessors')}
				</Button>
			) : null}

			{!this.props.printable ?
				<Button
					disabled={
						disableRemoveAssessmentButton ||
						this.props.assignment.created == '0001-01-01T00:00:00' ||
						this.props.assignment.locked ||
						this.state.loading
					}
					style={{ marginLeft: '1rem', marginTop: '1rem' }}
					onClick={() => this.setState({ removeAssessments: true })}
				>
					{this.state.loading ? <Spinner small center /> : null}

					{this.props.translate('Remove assessments')}
				</Button>
				: null}
		</div>)
	};

	renderModals = () => {
		const isTeacher = isUserTeacher(this.props.currentUser);

		let selectedTasks = [];

		// If we have selected tasks, we should only assess those from the table
		// else we should assess all tasks that are manually selected
		var selectedAssignmentTasks = this.getSelectedAssignmentTasks();
		if (selectedAssignmentTasks.length > 0) {
			selectedTasks = selectedAssignmentTasks;
		} else {
			selectedTasks = this.props.assignment.tasks.filter((t) => t.selected);
		}

		let unpublishedAssessment = null;
		if (this.props.assessments != null) {
			unpublishedAssessment = this.props.assessments.find((ass) => {
				return ass.status != 'ASSESSMENT_PUBLISHED' && ass.assessorType == ASSESSMENT_TYPES.TEACHER;
			});
		}

		let disableAssessmentsButton = true;
		if (unpublishedAssessment != null) {
			disableAssessmentsButton = false;
		}

		let assessAllButtonError = false;
		let disableRemoveAssessmentButton = true;

		let changeToSubmittedError = selectedTasks.some(
			(user) =>
				user.status == 'ASSIGNMENT_TASK_STARTED' || user.status == 'ASSIGNMENT_TASK_NOT_STARTED'
		);

		selectedTasks.forEach((user) => {
			if (user.status == 'ASSIGNMENT_TASK_STARTED' || user.status == 'ASSIGNMENT_TASK_NOT_STARTED') {
				assessAllButtonError = true;
				return;
			}

			let studentId = user.students[0].userId;
			if (studentId != null) {
				let hasAssessment = null;
				if (this.props.assessments != null) {
					hasAssessment = this.props.assessments.findIndex(
						(ass) => ass.studentId == studentId && ass.assessorType == ASSESSMENT_TYPES.TEACHER
					);
				}

				if (hasAssessment > -1) {
					assessAllButtonError = true;
					disableRemoveAssessmentButton = false;
				}
			}
		});

		let assessmentBlocks = null;
		let assessment = null;
		let seperatedAssessments = {
			peerAssessments: [],
			teacherAssessments: [],
			selfAssessments: []
		}

		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);

			seperatedAssessments.peerAssessments = assessmentBlocks.filter((block) =>
				block.assessmentType == ASSESSMENT_TYPES.PEER && block.resources.length > 0
			)?.sort((a, b) => a.title.localeCompare(b.title));

			seperatedAssessments.teacherAssessments = assessmentBlocks.filter((block) =>
				(block.assessmentType == ASSESSMENT_TYPES.TEACHER || block.assessmentType == null) && block.resources.length > 0
			).sort((a, b) => a.title.localeCompare(b.title));

			seperatedAssessments.selfAssessments = assessmentBlocks.filter((block) =>
				block.assessmentType == ASSESSMENT_TYPES.SELF && block.resources.length > 0
			).sort((a, b) => a.title.localeCompare(b.title));
		}

		if (selectedTasks.length == 1 && this.props.assessments != null) {
			assessment = this.props.assessments.find((assessment) =>
				assessment.studentId == selectedTasks[0].assignedTo && assessment.assessorType == ASSESSMENT_TYPES.TEACHER
			)
		}


		return (<div>
			<Modal isOpen={this.state.assessmentOpen} onClose={this.closeAssessment} title={this.props.translate('Assessment')}>
				<AssessmentForm
					onClose={this.closeAssessment}
					onSubmit={this.onAssessmentSubmit}
					blocks={seperatedAssessments.teacherAssessments}
					initialValues={assessment}

					items={selectedTasks.map((selected) => ({
						firstname: selected.groupName,
						...selected,
					}))}
					referenceType="AssignmentTask"
					assessmentType={ASSESSMENT_TYPES.TEACHER}
				/>
			</Modal>

			<Modal
				isOpen={this.state.studentAssessmentOpen}
				onClose={this.closeStudentAssessment}
				title={
					this.state.selectedAssessment?.assessorType == ASSESSMENT_TYPES.PEER
						? this.props.translate('buddy-assessment')
						: this.props.translate('self-assessment')
				}
			>
				<AssessmentsConsumer
					fields={this.state.selectedAssessment?.assessorType == ASSESSMENT_TYPES.PEER ? seperatedAssessments.peerAssessments : seperatedAssessments.selfAssessments}
					assessment={this.state.selectedAssessment}
					isStudent={!isTeacher}
				/>
			</Modal>

			<Modal
				overridePrompt
				isOpen={this.state.removeAssessments}
				type='small'
				title={this.props.translate('Remove assessments')}
				onClose={() => this.setState({ removeAssessments: false })}
			>
				<ClearBlockAssessments
					items={selectedTasks.map(x => {
						return { assignedTo: x.assignedTo, groupName: x.groupName }
					})}
					assessments={this.props.assessments}
					section={this.props.activeSection}
					onAbort={(reload) => {
						if (reload) this.props.reload(true);

						this.setState({ removeAssessments: false });
					}}
				/>
			</Modal>
		</div>)
	}

	render() {
		return (
			<div>
				{this.renderModals()}
				{this.renderButtonGroups()}
				{this.renderTable()}
			</div>
		);
	}
}



function mapStateToProps(state) {
	return {
		services: state.Services.availableServices,
		conversations: state.Conversation.referenceConversations,
		assignment: state.assignments.active_assignment,
		assessments: state.assessments.assessments,
		studentAssessments: state.assessments.studentAssessments,
		activeSection: state.assignments.section,
		translate: translate(state.Languages.translations),
		blocks: state.Blocks.reference,
		currentUser: state.user.currentUser
	};
}

export default withRouter(connect(mapStateToProps, {
	addError,
	createAssessments,
	updateAssessment,
	updateAssessmentStatus,
	updateAssignmentTaskSubmittedDate,
	updateAssignmentTaskStatus,
	updateAssignmentInList,
	updateActiveAssignment,
})(UserAssignment));
