import React, { useEffect, useState, useCallback } from "react";
import {
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Checkbox,
	Skeleton,
	Stack,
	Breadcrumbs,
	Link,
	Typography,
	Box,
	Button,
	IconButton,
} from "@mui/material";
import { translate as translateConstructor, Button as HaldorButton, Icon } from '@haldor/ui';
import { getOnBehalfOfAccessToken } from "actions/user";
import { Document, FolderIcon } from 'UI/Icons';
import { useDispatch, useSelector } from "react-redux";

import './_FileBrowser.scss';

const FileBrowser = ({
	source,
	onUpdate,
	onClose,
	onBreadcrumbChange,
	initialBreadCrumbs = [{ id: "me", name: "Home" }],
}) => {
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [files, setFiles] = useState([]);
	const [loading, setLoading] = useState(false);
	const [breadcrumbs, setBreadcrumbs] = useState(initialBreadCrumbs);
	const [error, setError] = useState(null);

	const translations = useSelector(state => state.Languages.translations);
	const translate = React.useMemo(() => translateConstructor(translations), [translations]);

	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.currentUser);

	const getAccessTokenAsync = useCallback(async () => {
		let token = await dispatch(getOnBehalfOfAccessToken(user.userId, "Files.ReadWrite.All"));
		if (token == null) {
			token = await dispatch(getOnBehalfOfAccessToken(user.userId, "Sites.ReadWrite.All"));
		}

		if (!token) {
			setError("Something went wrong");
			return null;
		}

		return token;
	}, [dispatch, user.userId]);

	// Fetch files for the root folder
	const defaultFetchFiles = useCallback(async () => {
		try {
			const token = await getAccessTokenAsync();
			if (!token) {
				return [];
			}

			const url = source === "me"
				? "https://graph.microsoft.com/v1.0/me/drive/root/children"
				: `https://graph.microsoft.com/v1.0/groups/${source}/drive/root/children`;
			const response = await fetch(url, {
				headers: {
					"Authorization": `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});
			const data = await response.json();
			return data.value || [];
		} catch (error) {
			return [];
		}
	}, [source, getAccessTokenAsync]);

	// Load files when component mounts or source changes
	useEffect(() => {
		const loadFiles = async () => {
			setLoading(true);
			const fetchedFiles = await defaultFetchFiles();
			setFiles(fetchedFiles);
			setLoading(false);
		};
		loadFiles();
	}, [source, defaultFetchFiles]);

	const fetchFileById = useCallback(async (fileId) => {
		try {
			const token = await getAccessTokenAsync();
			if (!token) {
				return null;
			}
			// Determine URL based on the source value
			const url = source === "me"
				? `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}`
				: `https://graph.microsoft.com/v1.0/groups/${source}/drive/items/${fileId}`;

			const response = await fetch(url, {
				headers: {
					"Authorization": `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});
			const data = await response.json();

			if (data != null && data.package != null) {
				if (data.package.type != null && data.package.type == "oneNote") {
					return null;
				}
			}

			return data;
		} catch (error) {
			console.error("Error fetching file by ID:", error);
			return null;
		}
	}, [getAccessTokenAsync, source]);

	// Fetch files from a specific folder
	const fetchFilesFromFolder = useCallback(async (folderId) => {
		try {
			const token = await getAccessTokenAsync();
			if (!token) {
				console.error("Failed to get access token");
				return [];
			}
			const url = source === "me"
				? `https://graph.microsoft.com/v1.0/me/drive/items/${folderId}/children`
				: `https://graph.microsoft.com/v1.0/groups/${source}/drive/items/${folderId}/children`;
			const response = await fetch(url, {
				headers: {
					"Authorization": `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});
			const data = await response.json();
			return data.value || [];
		} catch (error) {
			console.error("Error fetching folder contents:", error);
			return [];
		}
	}, [getAccessTokenAsync, source]);

	// Helper to load root files (called when root breadcrumb is clicked)
	const loadRootFiles = useCallback(async () => {
		setLoading(true);
		const rootFiles = await defaultFetchFiles();
		setFiles(rootFiles);
		setBreadcrumbs(initialBreadCrumbs);
		setLoading(false);
	}, [source, defaultFetchFiles]);

	// Handler for opening a folder
	const handleOpenFolder = useCallback(async (folderId, folderName) => {
		const newFiles = await fetchFilesFromFolder(folderId);
		setFiles(newFiles);
		setBreadcrumbs((prevBreadcrumbs) => {
			const updatedBreadcrumbs = [...prevBreadcrumbs, { id: folderId, name: folderName }];
			return updatedBreadcrumbs;
		});
	}, [fetchFilesFromFolder]);

	// Handler for clicking on a breadcrumb
	const handleBreadcrumbClick = useCallback((index) => {
		const currentBreadCrumb = breadcrumbs[index];

		const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
		setBreadcrumbs(newBreadcrumbs);
		setSelectedFiles([]);

		// course is currentBreadCrumb.id then loadRootFiles
		if (currentBreadCrumb != null && currentBreadCrumb.id === source) {
			loadRootFiles();
		}

		if (onBreadcrumbChange != null) {
			onBreadcrumbChange(currentBreadCrumb);
		}

	}, [breadcrumbs, source, loadRootFiles]);

	// Helper function to get the appropriate icon for a file/folder
	const getFileIcon = useCallback((file) => {
		let icon = (<div className="file-icon"> {file.folder ? <FolderIcon /> : <Document />}</div>)
		return icon;
		;
	}, []);

	const formatSize = useCallback((bytes) => {
		if (bytes < 1024) return `${bytes} B`;
		const kb = bytes / 1024;
		if (kb < 1024) {
			return `${kb.toFixed(2)} KB`;
		}
		const mb = kb / 1024;
		if (mb < 1024) {
			return `${mb.toFixed(2)} MB`;
		}
		const gb = mb / 1024;
		return `${gb.toFixed(2)} GB`;
	}, []);

	const onSelect = useCallback((fileId) => {
		setSelectedFiles((prev) =>
			prev.includes(fileId) ? prev.filter((id) => id !== fileId) : [...prev, fileId]
		);
	}, []);

	const processSelectedFiles = async () => {
		setLoading(true);
		const fetchedFiles = await Promise.all(selectedFiles.map(fetchFileById));

		let processedFiles = [];

		fetchedFiles.forEach(element => {
			if (element == null) return;

			processedFiles.push({
				name: element.name,
				url: element['@microsoft.graph.downloadUrl'],
				id: element.id,
				type: element['file'].mimeType,
				driveItemId: element.id,
				parentReference: element.parentReference,
			});
		});

		if (onUpdate != null) {
			onUpdate(processedFiles);
		}

		setLoading(false);
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
			{/* Fixed Header */}
			<Box
				sx={{
					flex: "0 0 auto",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					mb: 1,
				}}
			>
				<Breadcrumbs
					aria-label="breadcrumb"
					sx={{
						flex: "1 1 auto",
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}}
				>
					{breadcrumbs &&
						breadcrumbs.map((crumb, index) => (
							<Link
								key={crumb.id}
								onClick={() => handleBreadcrumbClick(index)}
								sx={{ cursor: "pointer" }}
								color={index === breadcrumbs.length - 1 ? "text.primary" : "inherit"}
							>
								{translate(crumb.name)}
							</Link>
						))}
				</Breadcrumbs>
			</Box>

			{/* File/Folder List (Takes remaining space) */}
			<Box
				sx={{
					flex: "1 1 auto", // Fills remaining space
					overflowY: "auto",
					pt: 2,
					pb: 2
				}}
			>
				<List>
					{!loading && error && (
						<Typography variant="caption" align="center" color="error">
							{translate(error)}
						</Typography>
					)}

					{loading && error === null && (
						<Stack spacing={2}>
							<Skeleton variant="rectangular" width={310} height={20} />
							<Skeleton variant="rectangular" width={380} height={20} />
							<Skeleton variant="rectangular" width={440} height={20} />
							<Skeleton variant="rectangular" width={500} height={20} />
							<Skeleton variant="rectangular" width={310} height={20} />
							<Skeleton variant="rectangular" width={380} height={20} />
							<Skeleton variant="rectangular" width={340} height={20} />
						</Stack>
					)}

					{!loading && files.length === 0 && error === null && (
						<Typography variant="body1" align="center" sx={{ py: 2 }}>
							{translate('No files selected')}
						</Typography>
					)}

					{!loading && files.length > 0 && error === null && (
						files.map((file) => (
							<ListItem key={file.id} disablePadding>
								<ListItemButton
									onClick={() =>
										file.folder
											? handleOpenFolder(file.id, file.name)
											: onSelect && onSelect(file.id)
									}
									sx={{
										backgroundColor: selectedFiles.includes(file.id)
											? "#e3f2fd"
											: "inherit",
									}}
								>
									{!file.folder && (
										<ListItemIcon>
											<Checkbox
												edge="start"
												checked={selectedFiles.includes(file.id)}
												tabIndex={-1}
												disableRipple
											/>
										</ListItemIcon>
									)}
									<ListItemIcon>{getFileIcon(file)}</ListItemIcon>
									<ListItemText
										primary={file.name}
										secondary={file.folder ? "Folder" : formatSize(file.size)}
									/>
								</ListItemButton>
							</ListItem>
						))
					)}
				</List>
			</Box>

			{/* Fixed Footer */}
			<Box
				sx={{
					flex: "0 0 auto", // Fixed height based on content
					background: "white",
					display: "flex",
					mt: 5,
				}}
			>
				<HaldorButton
					onClick={processSelectedFiles}
					disabled={selectedFiles.length === 0}
				>
					{translate('Upload')}
				</HaldorButton>
			</Box>
		</Box >
	);
};

export default FileBrowser;
