import React, { useCallback, useMemo, useRef, useState } from 'react';
import { getPlans, getPlansBySectionId } from "actions/plans";
import { Select, translate as translateConstructor, Button as HaldorButton } from '@haldor/ui';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DataGridPro, gridClasses, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';
import { svSE, enUS, nbNO, daDK } from '@mui/x-data-grid-pro/locales';
import { Avatar, Chip, Grid, Stack, Tooltip } from '@mui/material';
import ColorLabelContainer from 'UI/Elements/ColorLabel/ColorLabelContainer';
import ColorLabel from 'UI/Elements/ColorLabel/ColorLabel';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { getRootUrl } from 'helpers/url';
import { useHistory } from 'react-router-dom';

import NoItemsFoundLayout from 'containers/Assignments/NoItemsFoundLayout';
import SelectMultiple from 'containers/Overview/TableFilterComponents/SelectMultiple';
import CustomColumnSelector from 'components/DataGrid/CustomColumnSelector';
import DatePickerFromTo from 'containers/Forms/Partials/DatePickerFromTo';
import moment from 'moment';

/**
 * this is a map of the locale to the data grid language
 */
const localeMapDataGrid = {
	'EN-US': enUS,
	'SV-SE': svSE,
	'NB-NO': nbNO,
	'DA-DK': daDK,
};
const CustomToolbar = ({ filterValues, onChangeFilter, mySections, subjects }) => {
	const translations = useSelector(state => state.Languages.translations);
	const planStatuses = useSelector(state => state.status.status.planningStatus);
	let translate = translateConstructor(translations);

	const groupOptions = useMemo(() => mySections?.filter(item => item?.id > 0)?.map(item => ({ id: item.id, title: item.title })) || [], [mySections]);
	const assessmentStatusOptions = [{ id: 'NOT_PUBLISHED_ASSESSMENTS', title: translate('Missing published assessments') }];
	const planStatusOptions = useMemo(() => planStatuses != null ? planStatuses.map(item => ({ id: item.id, title: item.value })) : [], [planStatuses]);

	const subjectsOptions = useMemo(() => {
		if (subjects == null) return [];

		const uniqueSubjectObjects = subjects.reduce((uniqueSubjects, subject) => {
			if (!uniqueSubjects.some(item => item.title === subject.subjectTitle)) {
				uniqueSubjects.push({ id: subject.subjectId, title: subject.subjectTitle });
			}
			return uniqueSubjects;
		}, []);

		if (uniqueSubjectObjects.length > 1)
			uniqueSubjectObjects.sort((a, b) => a.title.localeCompare(b.title));

		return uniqueSubjectObjects;
	}, [subjects]);

	const initialSelectedGroups = useMemo(() => filterValues?.groups ?? [], [filterValues?.groups]);
	const initialSelectedSubjects = useMemo(() => filterValues?.subjects ?? [], [filterValues?.subjects]);
	const initialSelectedAssessmentStatus = useMemo(() => filterValues?.assessmentStatus ?? [], [filterValues?.assessmentStatus]);
	const initialSelectedPlanStatus = useMemo(() => filterValues?.planStatus ?? [], [filterValues?.planStatus]);

	return (
		<div>
			<GridToolbarContainer sx={{
				paddingLeft: '1.5rem',
				paddingTop: '1rem',
				marginBottom: '1rem',
			}}>
				<SelectMultiple onChange={(selectedOptions) => onChangeFilter('groups', selectedOptions)} initialSelectedItems={initialSelectedGroups} options={groupOptions} label={translate('Active groups')} sx={{ minWidth: 300, maxWidth: 350 }} />
				<SelectMultiple onChange={(selectedOptions) => onChangeFilter('subjects', selectedOptions)} initialSelectedItems={initialSelectedSubjects} options={subjectsOptions} label={translate('Subjects')} sx={{ minWidth: 300, maxWidth: 350 }} />
				{/* <SelectMultiple onChange={(selectedOptions) => onChangeFilter('assessmentStatus', selectedOptions)} initialSelectedItems={initialSelectedAssessmentStatus} options={assessmentStatusOptions} label={translate('Assessment status')} sx={{ minWidth: 350, maxWidth: 350 }} /> */}
				<SelectMultiple onChange={(selectedOptions) => onChangeFilter('planStatus', selectedOptions)} initialSelectedItems={initialSelectedPlanStatus} options={planStatusOptions} label={translate('Access status')} sx={{ minWidth: 300, maxWidth: 350 }} />
				<CustomColumnSelector id="MyPlansList" />
			</GridToolbarContainer>
		</div>
	);
};

const useStyles = makeStyles({
	root: {
		width: '100%',
		backgroundColor: '#FFFFFF',
		marginBottom: '3rem',
		position: 'relative',
	},
	firstColumn: {
		boxShadow: '5px 0 10px #0000000d;',
		fontSize: '.688rem',
	},
	rowStyle: {
		cursor: 'pointer',
	},
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: '#f0f0f0', // Custom background color
		color: '#333', // Custom text color
		fontSize: '.688rem', // Font size for headers
		fontWeight: 'normal !important',
		cursor: 'unset !important',
	},
	'& .MuiDataGrid-columnHeaderTitle': {
		fontWeight: 'normal !important',
		whiteSpace: 'normal',
		lineHeight: '1.2',
		overflow: 'visible',
	},
});

const MyPlansList = () => {
	const [datagridLanguage, setDatagridLanguage] = useState(enUS); // Default to English
	const [loading, setLoading] = useState(true);
	const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
	const [allSubjects, setAllSubjects] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const translations = useSelector(state => state.Languages.translations);
	const plans = useSelector(state => state.planning.plans);
	const currrentUser = useSelector(state => state.user.currentUser);
	const mySections = useSelector(state => state.sections.educationGroups);

	const currentTableFilter = useRef(null);
	useEffect(() => {
		const filterObject = localStorage.getItem("plans_filter_objects");
		currentTableFilter.current = JSON.parse(filterObject);
		if (startDate == null && endDate == null && currentTableFilter.current != null && currentTableFilter.current.startDate != null && currentTableFilter.current.endDate != null) {
			const newStartDate = moment(currentTableFilter.current.startDate);
			const newEndDate = moment(currentTableFilter.current.endDate);

			setStartDate(prevStartDate =>
				prevStartDate != null && moment(prevStartDate).isSame(newStartDate, 'day') ? prevStartDate : newStartDate
			);
			setEndDate(prevEndDate =>
				prevEndDate != null && moment(prevEndDate).isSame(newEndDate, 'day') ? prevEndDate : newEndDate
			);
		} else {
			onTermChange('year');
		}
	}, []);


	useEffect(() => {
		if (plans != null && plans.length >= 0) {
			fetchSubjects();
		}
	}, [plans]);

	useEffect(() => {
		if (allSubjects != null) {
			filterPlans();
		}
	}, [allSubjects]);

	useEffect(() => {
		if (currrentUser != null && currrentUser.lcid != null && localeMapDataGrid[currrentUser.lcid.toUpperCase()] != null) {
			setDatagridLanguage(localeMapDataGrid[currrentUser.lcid.toUpperCase()]);
		}
	}, [currrentUser]);

	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const apiRef = useGridApiRef();
	let translate = translateConstructor(translations);

	/**
	 * This is the columns for the DATAGRIDS and only to be recaulated when the plans are changed
	 */
	const columns = useMemo(() => [
		{
			field: 'title',
			headerName: translate('Title'),
			disableColumnMenu: true,
			minWidth: 200,
			align: 'left',
			flex: 1,
		},
		{
			field: 'groupTitle',
			headerName: translate('Groups'),
			disableColumnMenu: true,
			align: 'left',
			minWidth: 200,
			flex: 1,
		},
		{
			field: 'startDate',
			headerName: translate('Start date'),
			renderCell: (params) => { return params.value ? new Date(params.value).toLocaleDateString() : '' },
			disableColumnMenu: true,
			minWidth: 100,
			align: 'left',
			flex: 1,
		},
		{
			field: 'endDate',
			headerName: translate('Due Date'),
			disableColumnMenu: true,
			renderCell: (params) => { return params.value ? new Date(params.value).toLocaleDateString() : '' },
			align: 'left',
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'subjects',
			headerName: translate('Subjects'),
			disableColumnMenu: true,
			renderCell: (params) => renderCourses(params),
			minWidth: 250,
			align: 'left',
		},
		{
			field: 'informations',
			headerName: translate('Information'),
			disableColumnMenu: true,
			renderCell: (params) => renderInformations(params),
			align: 'left',
			minWidth: 150,
			flex: 1,
		}
	], [plans]);

	/**
	 * This method is used within the DATAGRIDS to render the column of informations
	 * @param {*} params 
	 * @returns 
	 */
	const renderInformations = (params) => {
		let plan = params.row && params.row.plan;
		if (plan == null) return null;

		let status = plan.status;
		let statusHtml = null;
		let autoAssignHTML = null;

		if (plan.autoAssignStudents) {
			autoAssignHTML = (<Tooltip placement="top" arrow title={translate('Will automatically be assigned to students who are added to the group.')}><div className="card-meta" style={{ alignSelf: 'baseline' }}><PersonAddIcon sx={{ fontSize: '15px' }} />{translate('Auto')}</div></Tooltip>);
		}

		if (status === "PLANNING_OPEN") {
			statusHtml = (
				<div className='card-meta' style={{ alignSelf: 'baseline' }}>
					{translate("Open")}
				</div>
			);
		} else if (status === "PLANNING_CLOSED") {
			statusHtml = (
				<div className='card-meta' style={{ alignSelf: 'baseline' }}>
					{translate("Closed")}
				</div>
			);
		} else if (status === "PLANNING_DRAFT") {
			statusHtml = (
				<div className='card-meta' style={{ alignSelf: 'baseline' }}>
					{translate("Draft")}
				</div>
			);
		}

		return (<Stack direction="column" spacing={0.5}>{statusHtml} {autoAssignHTML}</Stack>)
	}

	const fetchSubjects = async () => {
		let subjects = [];
		if (plans == null || plans.length == 0) return setAllSubjects(subjects);

		plans.forEach(plan => {
			if (plan.courses == null || plan.courses.length == 0) return null;
			plan.courses.forEach(course => {
				const subjectId = course.subjectId;
				const subjectTitle = course.subjectTitle;
				const existingSubject = subjects.find(subject => subject.subjectId === subjectId);
				if (!existingSubject) {
					subjects.push({
						subjectId,
						subjectTitle
					});
				}
			});
		});

		setAllSubjects(subjects);
	}

	/**
	 * This function is called when the user clicks on a row in the data grid.
	 * @param {*} params 
	 */
	const handleRowClick = (params) => {
		let planId = params?.id;
		var url = `${getRootUrl()}plan/${planId}`;
		history.push(url);
	};

	/**
	 * This method is used within the DATAGRIDS to render the column of courses
	 * @returns 
	 */
	const renderCourses = (params) => {
		let courses = params.row && params.row.courses;
		if (courses == null) return null;

		return (<Stack direction="column" spacing={0.5} style={{ minWidth: '200px' }}>
			{courses.map((course) => {
				return (<ColorLabelContainer key={course.id}>
					<ColorLabel
						key={course.id}
						tooltip={course.title}
						content={course.title}
						color={course.colorCode}
					/>
				</ColorLabelContainer>);
			})}
		</Stack>)
	}

	/**
	 * This method is called when the user changes the sort model in the data grid
	 * @param {*} details 
	 */
	const onSortModelChange = (details) => {
		localStorage.setItem("plans_sort_objects", JSON.stringify(details));
		if (apiRef != null && apiRef.current != null)
			apiRef.current.setSortModel(details);
	}

	useEffect(() => {
		/**
		 * This is the initial load when plans is null otherwise updateData will be called when the user clicks on the update button
		 */
		if (plans == null && startDate != null && endDate != null) {
			updateData(startDate, endDate);
		}
	}, [plans, startDate, endDate]);

	useEffect(() => {
		if (apiRef != null && apiRef.current != null) {
			let sortModel = JSON.parse(localStorage.getItem("plans_sort_objects"));
			if (sortModel != null)
				apiRef.current.setSortModel(sortModel);
		}
		return () => { };
	}, [apiRef]);

	const onChangeFilter = useCallback((filterType, selectedOptions) => {
		const filterObjects = {
			...currentTableFilter.current,
			[filterType]: selectedOptions
		};

		localStorage.setItem("plans_filter_objects", JSON.stringify(filterObjects));
		currentTableFilter.current = filterObjects;
		filterPlans();
	}, [plans, allSubjects]);

	const onTermChange = (value) => {
		if (value != '') {
			let dStartDate = moment();
			let dDueDate = moment();

			switch (value) {
				case 'year':
					if (moment().month() < 7) {
						dStartDate = moment().subtract(1, 'year').month(7).date(1);
						dDueDate = moment().month(6).date(31);
					} else {
						dStartDate = moment().month(7).date(1);
						dDueDate = moment().add(1, 'year').month(6).date(31);
					}

					break;
				case 'previous-year':
					if (moment().month() < 7) {
						dStartDate = moment().subtract(2, 'year').month(7).date(1);
						dDueDate = moment().subtract(1, 'year').month(6).date(31);
					}
					else {
						dStartDate = moment().subtract(1, 'year').month(7).date(1);
						dDueDate = moment().subtract(0, 'year').month(6).date(31);
					}
					break;
				case 'semester':
					if (moment().month() < 7) {
						dStartDate = moment().month(0).date(1);
						dDueDate = moment().month(6).date(31);
					} else {
						dStartDate = moment().month(6).date(1);
						dDueDate = moment().month(11).date(31);
					}

					break;
			}


			onDateChange({ start: dStartDate, end: dDueDate });
		}
	}

	const onDateChange = (dates) => {
		setStartDate(prevStartDate =>
			prevStartDate != null && moment(prevStartDate).isSame(dates.start, 'day') ? prevStartDate : dates.start
		);

		setEndDate(prevEndDate =>
			prevEndDate != null && moment(prevEndDate).isSame(dates.end, 'day') ? prevEndDate : dates.end
		);

		const filterObjects = {
			...currentTableFilter.current,
			"startDate": dates.start,
			"endDate": dates.end
		};

		localStorage.setItem("plans_filter_objects", JSON.stringify(filterObjects));
		currentTableFilter.current = filterObjects;
	};

	const updateData = (startDate, endDate) => {
		setLoading(true);
		dispatch(getPlans(moment(startDate).utc().format('YYYY-MM-DD hh:mm'), moment(endDate).add(1, 'day').utc().add().format('YYYY-MM-DD hh:mm')));
	}

	const filterPlans = async () => {
		let filteredPlans = plans;
		if (currentTableFilter.current != null && plans != null && plans.length > 0) {
			const { groups, assessmentStatus, planStatus, subjects } = currentTableFilter.current;

			if (groups && groups.length > 0) {
				filteredPlans = filteredPlans.filter((assignment) => {
					return groups.some((group) => group.id == assignment.sectionId);
				});
			}

			if (subjects && subjects.length > 0) {
				let filteredSubjectIds = allSubjects
					.filter((subject) => subjects.some((selectedSubject) => selectedSubject.title === subject.subjectTitle))
					.map((subject) => subject.subjectId);

				filteredPlans = filteredPlans.filter((assignment) => {
					return assignment.courses.some((course) =>
						filteredSubjectIds.includes(course.subjectId)
					);
				});
			}

			if (planStatus && planStatus.length > 0) {
				filteredPlans = filteredPlans.filter((plan) => {
					return planStatus.some((status) => status.id == plan.status);
				});
			}
		}

		if (apiRef && apiRef.current && apiRef.current.setRows && plans != null && plans.length >= 0) {
			var rows = filteredPlans.map((plan) => { return { id: plan.id, title: plan.title, groupTitle: plan.section?.title, startDate: plan.timeStart, endDate: plan.timeEnd, courses: plan.courses, plan: plan } });
			apiRef.current.setRows(rows);

			setLoading(false);
		}
	}

	return (
		<div>
			{startDate && endDate ? (<Grid className='mb-3' container justifyContent="flex-start" alignItems="center" spacing={2}>
				<Grid item xs={12} sm={12} md={9}>
					<div className="form">
						<div className="form-row">
							<DatePickerFromTo
								values={{
									start: moment(startDate),
									end: moment(endDate),
								}}
								onChange={onDateChange}
							/>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={3}>
					<div className='term-change' style={{ paddingBottom: '1.2rem' }}>
						<Select
							onChange={onTermChange}
							options={[
								{
									label: translate('current-schoolyear'),
									value: 'year',
								},
								{
									label: translate('previous-schoolyear'),
									value: 'previous-year',
								},
								{
									label: translate('current-semester'),
									value: 'semester',
								},
							]}
						>
							<strong style={{ marginBottom: '0.2rem' }}>{translate('select-period')}</strong>
						</Select>
					</div>
					<HaldorButton type='secondary' onClick={() => { updateData(startDate, endDate) }}>{translate('Update')}</HaldorButton>
				</Grid>
			</Grid>) : null}
			<div className={classes.root}>
				<DataGridPro
					sx={{
						width: '100%',
						minHeight: '500px',
						[`& .${gridClasses.cell}`]: {
							py: 2,
						},
						"& .MuiDataGrid-virtualScroller": {
							overflowY: "hidden !important",
						},
					}}
					apiRef={apiRef}
					loading={loading}
					pagination
					paginationMode='client'
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					slotProps={{
						loadingOverlay: {
							variant: 'skeleton',
							noRowsVariant: 'skeleton',
						},
					}}
					slots={{
						noRowsOverlay: () => (<NoItemsFoundLayout title={'No planning was found'} />),
						toolbar: () => (<CustomToolbar subjects={allSubjects} filterValues={currentTableFilter.current} onChangeFilter={onChangeFilter} mySections={mySections} />),
					}}
					checkboxSelection={false}
					localeText={datagridLanguage.components.MuiDataGrid.defaultProps.localeText}
					disableColumnResize={false}
					disableColumnSelector
					disableColumnFilter
					disableRowSelectionOnClick
					onRowClick={(params) => handleRowClick(params)}
					onSortModelChange={onSortModelChange}
					getRowHeight={() => 'auto'}
					getRowClassName={() => classes.rowStyle}
					autosizeOnMount={false}
					disableAutosize={false}
					columns={columns}

				/>
			</div>
		</div>
	);
};

export default MyPlansList;