import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { connect } from 'react-redux';
import { Field, change, reduxForm, formValueSelector } from 'redux-form';
import { getUserAbsence } from 'actions/user';

import User from "_class/User";

import DatePickerFromTo from '../Partials/DatePickerFromTo';

import { Expandable, Spinner } from 'UI';
import { Button, Radio, Icon, translate, getActiveLanguage } from '@haldor/ui';

import './_ReportAbsence.scss';

class ReportAbsenceForm extends Component {

	constructor(props) {
		super(props);

		Moment.locale(getActiveLanguage());

		this.state = {
			id: null,
			origin: null,
			type: null,
			loading: true,
			timeValid: false,
			showError: false,
			timeStart: Moment().format(),
			timeEnd: Moment().format(),
			submitResult: "",
		};
	}

	componentDidMount = () => {
		if (this.props.user != null) {
			this.setState({ loading: true });

			const start = Moment().subtract(1, 'weeks').second(0).format('YYYY-MM-DD');
			const end = Moment().add(3, 'weeks').add(1, 'day').second(0).format('YYYY-MM-DD');

			this.props.getUserAbsence(this.props.user.userId, start, end).then(() => {
				this.setState({ loading: false });
				this.validateTime();
			});
		}

		if (this.props.absence != null) {
			this.selectAbsence(this.props.absence);
		}
	}

	validateTime = () => {
		if (Moment(this.state.timeStart).format('HH:mm:ss') === Moment(this.state.timeEnd).format('HH:mm:ss')) {
			this.setState({ showError: false, timeValid: false });
			return true;
		}

		if (this.state.timeEnd > this.state.timeStart) {
			if (this.state.timeValid == true) {
				// No need to update state to its current value
				return true;
			}

			this.setState({ timeValid: true });
		} else {
			if (this.state.timeValid == false) {
				// No need to update state to its current value

				this.setState({ showError: true })
				return true;
			}

			this.setState({ timeValid: false, showError: true });
		}
	}

	validatePeriod = (value) => {
		if (value == null || value == '') {
			return this.props.translate('Required');
		}

		return undefined;
	}

	onTimeChange = async (time) => {
		if (this.props.entireDay == 'yes') {
			await this.setState({
				timeStart: Moment(time.start).set({ 'minute': 0, 'hour': 0, 'second': 0 }).format(),
				timeEnd: Moment(time.end).set({ 'minute': 59, 'hour': 23, 'second': 0 }).format(),
			});
		} else {
			await this.setState({
				timeStart: Moment(time.start).second(0).format(),
				timeEnd: Moment(time.start).set({ 'minute': time.end.minute(), 'hour': time.end.hour(), 'second': 0 }).format(),
			});
		}

		this.validateTime();
	}

	onCancel = (event) => {
		event.preventDefault();

		if (this.props.onModalClose) {
			this.props.onModalClose();
		}
	}

	onDelete = (event) => {
		event.preventDefault();

		this.props.onDelete(this.state.id);
	}

	onEntireDay = async () => {
		let start = Moment(this.state.timeStart);
		let end = Moment(this.state.timeEnd);

		start = start.set({ 'minute': 0, 'hour': 0, 'second': 0 });
		end = end.set({ 'minute': 59, 'hour': 23, 'second': 0 });

		await this.setState({ timeStart: start.format(), timeEnd: end.format() });
		this.validateTime();
	}

	onPartOfDay = async () => {
		await this.setState({ timeStart: Moment().format(), timeEnd: Moment().format() });
		this.validateTime();
	}

	submit = (values) => {
		const user = new User(this.props.currentUser);

		return new Promise((resolve, reject) => {
			values.startTime = Moment.utc(this.state.timeStart).format();
			values.endTime = Moment.utc(this.state.timeEnd).format();
			values.user = this.props.user;
			values.type = this.state.type;

			values.period = this.props.entireDay == 'yes';

			if (this.state.id != null) {
				values.id = this.state.id;

				if (values.validAbsence == 'yes' && values.type.indexOf('INVALID') == 0) {
					values.type = values.type.replace('INVALID', 'VALID');
				}

				if (values.validAbsence == 'no' && values.type.indexOf('VALID') == 0) {
					values.type = values.type.replace('VALID', 'INVALID');
				}
			} else {
				values.type = values.validAbsence == 'yes' ? 'VALID_ABSENCE' : 'INVALID_ABSENCE';

				if (user.isStudent()) {
					values.type = 'VALID_ABSENCE';
				}
			}

			if (this.props.onSubmit) {
				this.props.onSubmit(values).then(() => {
					resolve(1);

					this.setState({ submitResult: this.props.submitResult });

					if (this.props.submitResult != 'error') {
						let absenceObject = this.props.userAbsence.find(function (absence) {
							return values.id == absence.id;
						});

						if (absenceObject != null) {
							absenceObject.startTime = this.state.timeStart;
							absenceObject.endTime = this.state.timeEnd;
							absenceObject.type = values.type;
						}

						if (this.props.onModalClose) {
							this.props.onModalClose();
						}
					}
				}).catch((error) => {
					this.setState({ submitResult: 'error' });
					reject(0);
				});
			} else {
				resolve(1);
			}
		});
	}

	selectAbsence = (absence) => {
		if (this.state.id == absence.id) {
			this.props.dispatch(change(FORM_NAME, 'entireDay', ''));
			this.props.dispatch(change(FORM_NAME, 'validAbsence', ''));

			this.setState({
				id: null,
				origin: null,
				timeStart: Moment().format(),
				timeEnd: Moment().format(),
				type: null,
			}, () => {
				this.validateTime();
			});

			return true;
		}

		if (absence.period) {
			this.props.dispatch(change(FORM_NAME, 'entireDay', 'yes'));
		} else {
			this.props.dispatch(change(FORM_NAME, 'entireDay', 'no'));
		}

		this.props.dispatch(change(FORM_NAME, 'validAbsence', absence.type.indexOf('VALID') == 0 ? 'yes' : 'no'));

		const user = new User(this.props.currentUser);
		const isStudent = user.isStudent();

		if (isStudent && user.isAuthoritative) {
			if (absence.reportedBy.userId == this.props.currentUser.id) {
				const start = Moment.utc(absence.startTime).local();
				const end = Moment.utc(absence.endTime).local();

				this.setState({
					id: absence.id,
					origin: absence.origin,
					timeStart: start.format(),
					timeEnd: end.format(),
					type: absence.type,
				}, () => {
					this.validateTime();
				});
			}
		} else {
			const start = Moment.utc(absence.startTime).local();
			const end = Moment.utc(absence.endTime).local();

			this.setState({
				id: absence.id,
				origin: absence.origin,
				timeStart: start.format(),
				timeEnd: end.format(),
				type: absence.type,
			}, () => {
				this.validateTime();
			});
		}
	}

	clickAbsence = (absence) => {
		this.selectAbsence(absence)
	}

	render() {
		const { handleSubmit, submitting, translate, valid, userAbsence } = this.props;
		let title = translate('registered-absence');
		const disabled = this.state.origin == 'ATTENDANCE';
		if (userAbsence != null && userAbsence.length > 0) {
			title = title + ' (' + userAbsence.length + ')';
		}

		const user = new User(this.props.currentUser);
		const isStudent = user.isStudent();

		return (
			<div className="form-container report-absence">
				{submitting ?
					<div style={{ height: '100%' }} className="is_sending">
						<p style={{ position: 'absolute' }}><span className="loading-spinner" /></p>
					</div>
					: null}

				{this.state.submitResult == 'error' ?
					<div className="form-row" style={{ marginBottom: '1.8em', color: 'red', fontWeight: '500' }}>
						{!isStudent ?
							this.props.translate('something-went-wrong-create-absence') :
							this.props.translate('something-went-wrong-create-absence-student')
						}
					</div>
					: null}

				{ /* Only show other absence objects if no absence is sent to the form */}
				{this.props.absence == null ?
					<div style={{ marginBottom: '2.4em' }}>
						<Expandable title={title} open>
							<div className="absence-date-range">
								<Icon name="Calendar" bw />
								{Moment().subtract(1, 'weeks').format('D MMMM')} - {Moment().add(3, 'weeks').format('D MMMM')}
							</div>

							{this.state.loading ?
								<Spinner center small />
								:
								userAbsence != null && userAbsence.length > 0 ?
									userAbsence.map((absence) => {
										let styles = {
											borderRadius: '4px',
											padding: '1rem',
											margin: '0.55rem 0',
											marginRight: '1.25rem',
											display: 'inline-block',
											cursor: 'pointer',
											border: '1px solid transparent',
											backgroundColor: 'white',
										};

										if (absence.id == this.state.id) {
											styles.border = '1px solid var(--color--blue)';
										}

										return <div key={absence.id + absence.reported} style={styles} onClick={e => this.clickAbsence(absence)}>
											<div style={{ fontSize: 11, fontWeight: '500' }}>
												{absence.origin == 'ATTENDANCE' ?
													this.props.translate('Reported lesson')
													: this.props.translate('Reported absence')}
											</div>

											{this.props.translate('start') + ': ' +
												Moment.utc(absence.startTime).local().format('Do MMMM YYYY - HH:mm')}
											<br />
											{this.props.translate('end') + ': ' +
												Moment.utc(absence.endTime).local().format('Do MMMM YYYY - HH:mm')}
											<br />
										</div>
									})
									:
									<h3 style={{ textAlign: 'center' }}>
										{translate('no-absence-reported-error')}
									</h3>
							}
						</Expandable>
					</div>
					: null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					{!isStudent ?
						<div className="form-row">
							<label style={{ marginTop: 0 }}>
								{translate('type')}
							</label>

							<div>
								<Field
									component={Radio}
									name="validAbsence"
									optionValue="yes"
									label={translate('VALID_ABSENCE')}
									validate={this.validatePeriod}
									disabled={disabled}
								/>
							</div>

							<div style={{ marginTop: '.35rem' }}>
								<Field
									component={Radio}
									name="validAbsence"
									optionValue="no"
									label={translate('INVALID_ABSENCE')}
									validate={this.validatePeriod}
									disabled={disabled}
								/>
							</div>
						</div>
						: null}

					{disabled && !isStudent && this.props.user != null ?
						<div className="form-row edit-notice">
							<Icon name="Alert" bw />

							<span
								dangerouslySetInnerHTML={{
									__html: translate('Editing of absences for reported lessons is done in {{link}}',
										`<a href="/user/${this.props.user.userId}/progress#attendance">
										${translate('student card')}
									</a>`
									)
								}}
							/>
						</div>
						: null}

					{this.state.origin == null || this.state.origin != "ATTENDANCE" ?
						<div>
							<div className="form-row">
								<label>{translate('Period')}</label>

								<div>
									<Field
										component={Radio}
										name="entireDay"
										optionValue="yes"
										label={translate('entire-day')}
										validate={this.validatePeriod}
										onChange={this.onEntireDay}
									/>
								</div>

								<div style={{ marginTop: '.35rem' }}>
									<Field
										component={Radio}
										name="entireDay"
										optionValue="no"
										label={translate('part-of-day')}
										validate={this.validatePeriod}
										onChange={this.onPartOfDay}
									/>
								</div>
							</div>

							<div className="form-row">
								<DatePickerFromTo
									timePicker={this.props.entireDay == 'no'}
									onChange={this.onTimeChange}
									type="plan" // To have values.timeStart and values.timeEnd
									values={this.state}
									hideEndDate={this.props.entireDay == 'no'}
								/>
							</div>
						</div>
						: null}

					<div className="clearfix" />

					{!this.state.timeValid && this.state.showError ?
						<div className="form-row" style={{ color: 'red', marginTop: '1rem', fontWeight: 'bold' }}>
							{translate('endTime-appears-before-startTime')}
						</div>
						: null}

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button disabled={submitting || !valid || !this.state.timeValid || disabled}>
							{this.state.id != null ?
								translate('save')
								: translate('report-submit')}
						</Button>

						<div className="align-right">
							{this.state.id != null ?
								<Button onClick={this.onDelete} disabled={submitting || disabled} type="secondary">
									{translate('Delete')}
								</Button>
								: null}

							<Button onClick={this.onCancel} disabled={submitting} type="secondary">
								{translate('Cancel')}
							</Button>
						</div>
					</div>

				</form>
			</div>
		);
	}
}

const FORM_NAME = 'ReportAbsence';
const getFormValue = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		userAbsence: state.user.absence,
		currentUser: state.user.currentUser,
		submitResult: state.Absence.submitResult,
		languages: state.Languages.languages,
		entireDay: getFormValue(state, 'entireDay'),
	};
}

export default connect(mapStateToProps, {
	getUserAbsence,
})(reduxForm({
	form: FORM_NAME,
	destroyOnUnmount: true,
})(ReportAbsenceForm));