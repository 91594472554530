import React, { useCallback, useState } from "react";
import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Modal, Typography, LinearProgress, IconButton, Stack } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { translate as translateConstructor, Button as HaldorButton } from '@haldor/ui';

import DropzoneComponent from 'react-dropzone-component';
import swal from 'sweetalert2';
import { http } from 'lib/msal';

import './LocalFilePicker.scss';
import { useDispatch, useSelector } from "react-redux";
import { getOnBehalfOfAccessToken } from "actions/user";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	height: "85vh",
	width: "80vw",
	overflow: "hidden",
	borderRadius: "5px",
	pt: 3,
	pb: 3,
	px: 4,
};

const LocalFilePicker = ({ onUpdate, onClose }) => {
	const [openModal, setOpenModal] = useState(true);
	const [dropzone, setDropzone] = useState(null);
	const [files, setFiles] = useState([]);
	const [processedFiles, setProcessedFiles] = useState([]);
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.currentUser);
	const translations = useSelector(state => state.Languages.translations);

	const translate = React.useMemo(() => translateConstructor(translations), [translations]);

	const onCloseModal = () => {
		setOpenModal(false);
		if (onClose) onClose();
	};

	// Save the dropzone instance in state
	const dropzoneInit = (dz) => {
		setDropzone(dz);
	};

	const getAccessTokenAsync = useCallback(async () => {
		let token = await dispatch(getOnBehalfOfAccessToken(user.userId, "Files.ReadWrite.All"));
		if (token == null) {
			token = await dispatch(getOnBehalfOfAccessToken(user.userId, "Sites.ReadWrite.All"));
		}
		return token;
	}, [dispatch, user.userId]);

	// Trigger file dialog via dropzone's hidden input
	const triggerDropzone = () => {
		if (dropzone && dropzone.hiddenFileInput) {
			dropzone.hiddenFileInput.click();
		}
	};

	// Dropzone event handlers
	const dropzoneEvents = {
		init: dropzoneInit,
		addedfile: (file) => {

			// Remove file if it's over 100MB
			if (file.size > 101000024) {
				if (dropzone) {
					dropzone.removeFile(file);
				}
				swal.fire({
					title: 'file-too-big',
					showCancelButton: false,
					confirmButtonText: 'okay',
				});
				return false;
			}

			// Save the file along with initial metadata for tracking upload progress
			setFiles(prevFiles => [...prevFiles, { file, progress: 0, status: "pending", fileInfo: null }]);
		},
	};

	// Dropzone configuration (we handle uploading separately)
	const djsConfig = {
		autoProcessQueue: false,
		parallelUploads: 1,
		timeout: 99900000,
		headers: {},
	};

	const componentConfig = {
		iconFiletypes: ['.jpg', '.png', '.gif'],
		showFiletypeIcon: false,
		postUrl: http.defaults.baseURL + 'fileItems'
	};

	// Helper to update a specific file's state
	const updateFileState = (index, newProps) => {
		setFiles(prevFiles => {
			const updated = [...prevFiles];
			updated[index] = { ...updated[index], ...newProps };
			return updated;
		});
	};


	const formatSize = useCallback((bytes) => {
		if (bytes < 1024) return `${bytes} B`;
		const kb = bytes / 1024;
		if (kb < 1024) {
			return `${kb.toFixed(2)} KB`;
		}
		const mb = kb / 1024;
		if (mb < 1024) {
			return `${mb.toFixed(2)} MB`;
		}
		const gb = mb / 1024;
		return `${gb.toFixed(2)} GB`;
	}, []);

	// Upload each file to OneDrive with progress updates
	const uploadFiles = async () => {
		setLoading(true);
		var token = await getAccessTokenAsync();
		let proccessedFiles = [];

		for (let i = 0; i < files.length; i++) {
			if (!token) {
				updateFileState(i, { status: "error" });
				continue;
			}

			updateFileState(i, { status: "uploading", progress: 0 });
			try {
				const fileInfo = await uploadLargeFile(token, files[i].file, (progress) => {
					updateFileState(i, { progress });
				});

				proccessedFiles.push({
					name: fileInfo.name,
					url: fileInfo['@microsoft.graph.downloadUrl'],
					id: fileInfo.id,
					type: fileInfo['file'].mimeType,
					driveItemId: fileInfo.id,
					parentReference: fileInfo.parentReference
				});
				updateFileState(i, { status: "success", progress: 100, fileInfo });
			} catch (error) {
				console.error("Upload error for file", files[i].file.name, error);
				updateFileState(i, { status: "error" });
			}
		}

		setLoading(false);

		if (onUpdate && token) {
			setOpenModal(false);
			onUpdate(proccessedFiles);
		}
	};

	return (
		<div>
			<Modal
				sx={{ zIndex: 99999999999999, marginBottom: '0rem' }}
				open={openModal}
				onClose={onCloseModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...modalStyle, display: 'flex', flexDirection: 'column' }}>
					{/* Fixed Header */}
					<Box sx={{ flex: '0 0 auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<h2>{translate('Selected files')}</h2>
						<IconButton onClick={onCloseModal} sx={{ ml: 2, flexShrink: 0 }}>
							<CloseIcon />
						</IconButton>
					</Box>

					{/* Middle content: Dropzone and List, each taking equal space */}
					<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', mt: 2 }}>
						{/* Dropzone Area */}
						<Box
							sx={{
								flex: 1,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<DropzoneComponent
								style={{ width: '100%', height: '100%' }}
								onClick={triggerDropzone}
								djsConfig={djsConfig}
								eventHandlers={dropzoneEvents}
								config={componentConfig}
							>
								{/* Custom Dropzone UI */}
								<div
									onClick={triggerDropzone}
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
										height: '100%',
										width: '100%',
										border: '2px dashed #aaa',
										borderRadius: '4px',
										cursor: 'pointer'
									}}
								>
									<CloudUploadIcon sx={{ fontSize: 48, color: '#aaa' }} />
									<Typography variant="body1" sx={{ marginTop: 1 }}>
										{translate('drop-files-or-click-here')}
									</Typography>
								</div>
							</DropzoneComponent>
						</Box>

						{/* List Area */}
						<Box
							sx={{
								flex: 1,
								display: 'flex',
								justifyContent: 'center',
								mt: 2
							}}
						>
							<List sx={{ overflowY: 'auto', width: '100%' }}>
								{files.length === 0 ? (
									<div
										style={{
											height: '100%',
											width: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											padding: '1rem'
										}}
									>
										{translate('No files selected')}
									</div>
								) : (
									<Stack spacing={1}>
										<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
											{translate('Selected files')}
										</Typography>
										{files.map((item, index) => (
											<div key={index}>
												<ListItem disablePadding>
													<ListItemIcon>
														<InsertDriveFileIcon />
													</ListItemIcon>
													<ListItemText
														primary={item.file.name}
														secondary={
															<Typography variant="caption" display="block">
																{formatSize(item.file.size)}
															</Typography>
														}
													/>
												</ListItem>
												{item.status === 'uploading' && (
													<Box sx={{ width: '100%', mr: 2 }}>
														<LinearProgress variant="determinate" value={item.progress} />
													</Box>
												)}
												{item.status === 'error' && (<Typography variant="caption" color="error">
													{translate('Something went wrong')}
												</Typography>)}
											</div>
										))}
									</Stack>
								)}
							</List>
						</Box>
					</Box>

					{/* Fixed Footer */}
					<Box sx={{ flex: '0 0 auto', background: 'white', pt: '1.5rem', borderTop: '1px solid #ddd' }}>
						<HaldorButton onClick={uploadFiles} disabled={files.length === 0 || loading}>
							{translate('Upload')}
						</HaldorButton>
					</Box>
				</Box>
			</Modal>
		</div>
	);
};
// Function to ensure the folder exists. If not, it creates the folder.
async function ensureFolderExists(accessToken, folderName) {
	const folderPath = `/${folderName}`;
	const checkUrl = `https://graph.microsoft.com/v1.0/me/drive/root:${folderPath}`;

	// Check if the folder exists.
	let response = await fetch(checkUrl, {
		headers: { 'Authorization': `Bearer ${accessToken}` }
	});

	// If the folder exists, return its data.
	if (response.ok) {
		return await response.json();
	}

	// Otherwise, create the folder.
	const createUrl = `https://graph.microsoft.com/v1.0/me/drive/root/children`;
	response = await fetch(createUrl, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${accessToken}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			name: folderName,
			folder: {}, // Indicates that this is a folder
			"@microsoft.graph.conflictBehavior": "rename"
		})
	});

	if (!response.ok) {
		const errorText = await response.text();
		console.error(`Error creating folder: ${errorText}`);
		throw new Error(`Failed to create folder: ${folderName}`);
	}

	return await response.json();
}


// Helper function to upload a large file to OneDrive with progress tracking
async function uploadLargeFile(accessToken, file, onProgress) {

	// Ensure the Haldor_tmpstorage folder exists.
	await ensureFolderExists(accessToken, 'Haldor_tmpstorage');

	// 1. Create an upload session in the Haldor_tmpstorage folder
	const sessionUrl = `https://graph.microsoft.com/v1.0/me/drive/root:/Haldor_tmpstorage/${encodeURIComponent(file.name)}:/createUploadSession`;
	const sessionResponse = await fetch(sessionUrl, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			item: {
				'@microsoft.graph.conflictBehavior': 'rename'
			}
		}),
	});

	if (!sessionResponse.ok) {
		console.error('Failed to create upload session:', await sessionResponse.text());
		throw new Error('Failed to create upload session');
	}

	const sessionData = await sessionResponse.json();
	const uploadUrl = sessionData.uploadUrl;

	// 2. Upload the file in chunks (10 MB per chunk)
	const chunkSize = 10 * 1024 * 1024;
	const totalSize = file.size;
	let start = 0;

	while (start < totalSize) {
		const end = Math.min(start + chunkSize, totalSize);
		const blob = file.slice(start, end);
		const contentRange = `bytes ${start}-${end - 1}/${totalSize}`;

		const response = await fetch(uploadUrl, {
			method: 'PUT',
			headers: {
				'Content-Length': blob.size,
				'Content-Range': contentRange,
			},
			body: blob,
		});

		if (!response.ok) {
			console.error('Error uploading chunk:', await response.text());
			throw new Error('Error uploading chunk');
		}
		start = end;
		const progress = Math.floor((start / totalSize) * 100);
		onProgress(progress);
	}

	// 3. Retrieve the uploaded file information from OneDrive (within Haldor_tmpstorage)
	const fileInfoResponse = await fetch(
		`https://graph.microsoft.com/v1.0/me/drive/root:/Haldor_tmpstorage/${encodeURIComponent(file.name)}`,
		{
			headers: {
				'Authorization': `Bearer ${accessToken}`,
			},
		}
	);

	if (!fileInfoResponse.ok) {
		console.error('Error retrieving file info:', await fileInfoResponse.text());
		throw new Error('Error retrieving file info');
	}

	const fileInfo = await fileInfoResponse.json();
	return fileInfo;
}

export default LocalFilePicker;
