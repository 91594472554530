import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as microsoftTeams from "@microsoft/teams-js";

import User from '_class/User';

import { setPageActions, setPageTitle } from 'actions/header';
import { getEducationGroups, getSectionByGraphId, getSection } from 'actions/sections';

import SectionForm from 'containers/Forms/SectionForm';
import Modal from 'containers/Modals/Modal';
import MyPlansWrapper from 'containers/Plans/MyPlansWrapper';
import SchoolSettingsForm from 'teams/containers/Forms/SchoolSettings';
import Assessments from 'teams/containers/Sections/Assessments';

import { translate } from '@haldor/ui';
import { Spinner } from 'UI';
import MyAssignmentsWrapper from 'containers/Assignments/MyAssignmentsWrapper';

class HaldorTab extends Component {

	constructor(props) {
		super(props);

		this.state = {
			groupId: null,
			noSectionFound: false,
			groupForm: false,
		};

		this.services = {
			microsoft: props.services.microsoft,
			haldor: props.services.haldor,
		};
	}

	componentDidMount = () => {
		const user = new User(this.props.user);

		this.props.setPageTitle(this.props.translate('overview'));

		if (!user.isStudent()) {
			let actions = [
				{
					type: 'icon',
					onClick: this.toggleGroupSettings,
					icon: 'cog_bw',
				},
			];

			this.props.setPageActions(actions);
		}

		microsoftTeams.app.getContext().then((context) => {
			const section = this.props.sections.find((section) => section.graphId == context.team.groupId);
			if (section != null) {
				this.props.getSection(section.id);
			}

			const { group } = this.props;

			if (group != null) {
				this.setState({ groupId: group.id, noSectionFound: false });
			} else {
				this.setState({ noSectionFound: true });
			}
		});

		if (this.props.services != null) {
			this.services = {
				microsoft: this.props.services.microsoft,
				haldor: this.props.services.haldor,
			};

			this.setState({});
		}
	}

	toggleGroupSettings = (shouldReload) => {
		this.setState({ groupForm: !this.state.groupForm });

		if (shouldReload == true) {
			microsoftTeams.app.getContext().then((context) => {
				this.props.getSectionByGraphId(context.team.groupId);
			});
		}
	}

	onSchoolSettingChange = (id) => {
		return new Promise(resolve => {
			microsoftTeams.app.getContext().then((context) => {
				Promise.all([
					this.props.getSectionByGraphId(context.team.groupId),
					this.props.getEducationGroups()
				]).then(() => {
					this.setState({ groupId: id, noSectionFound: false });
					resolve(1);
				});
			});
		});
	}

	renderEducationGroup = () => {
		const hasAssignments = this.services.haldor || this.services.microsoft;
		const user = new User(this.props.user);
		const school = user.getActiveSchool();

		return (
			<div className="overview">
				{this.props.group != null ?
					<Modal isOpen={this.state.groupForm} onClose={this.toggleGroupSettings} title={this.props.group.title}>
						<SectionForm
							onAbort={this.toggleGroupSettings}
							editView
							section={this.props.group}
						/>
					</Modal>
					: null}

				<div className="container">
					<div className="section" id="section-plans">
						{this.props.groups.length > 0 ?
							<MyPlansWrapper
								groupId={this.state.groupId}
								onlyGantView={true}
								overview
							/>
							: null}
					</div>

					{hasAssignments && school.type != 'PRESCHOOL' ?
						<div className="section" id="section-assignments">
							<MyAssignmentsWrapper showHeaderIcon pageSize={8} overview groupId={this.state.groupId}></MyAssignmentsWrapper>
						</div>
						: null}

					<Assessments groupId={this.state.groupId} />
				</div>
			</div>
		);
	}

	renderMentorGroup = () => {
		return (
			<div className="overview">
				{this.props.group != null ?
					<Modal isOpen={this.state.groupForm} onClose={this.toggleGroupSettings} title={this.props.group.title}>
						<SectionForm onAbort={this.toggleGroupSettings} editView section={this.props.group} />
					</Modal>
					: null}

				<div style={{ textAlign: 'center', marginTop: '4rem' }}>
					{this.props.translate('haldor-tab-mentor-group-not-supported')}
				</div>
			</div>
		);
	}

	render() {
		const user = new User(this.props.user);

		if (this.state.noSectionFound) {
			if (user.isStudent()) {
				return (
					<div className="overview">
						<div className="container">
							<h2>{this.props.translate('no-teams-connection')}</h2>
							<p style={{ marginTop: '1rem' }}>{this.props.translate('no-teams-connect-student-text')}</p>
						</div>
					</div>
				);
			}
			return (
				<div className="overview">
					<div className="form-container create-course-page course-form">
						<h2>{this.props.translate('no-teams-connection')}</h2>

						<p style={{ marginTop: '1rem', marginBottom: '2rem' }}>
							{this.props.translate('no-teams-connection-teacher-text')}
						</p>

						<SchoolSettingsForm
							onSubmit={this.onSchoolSettingChange}
							onboarding={true}
						/>

						<div className="clearfix"></div>
					</div>
				</div>
			);
		}

		if (this.state.groupId == null) {
			return <Spinner center />
		}

		if (this.props.group.type == 'EDUCATION_GROUP') {
			return this.renderEducationGroup();
		}

		return this.renderMentorGroup();
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		groups: state.sections.educationGroups,
		services: state.Services.availableServices,
		user: state.user.currentUser,
		sections: state.sections.educationGroups,
		group: state.sections.graphSection,
	};
}

export default connect(mapStateToProps, {
	getEducationGroups,
	setPageActions,
	setPageTitle,
	getSectionByGraphId,
	getSection
})(HaldorTab);
