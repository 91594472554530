import React, { useEffect, useState } from "react";
import {
	Box,
	Modal,
	Breadcrumbs,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
	IconButton,
} from "@mui/material";

import { translate as translateConstructor } from '@haldor/ui';

import GroupIcon from '@mui/icons-material/Group';
import FolderIcon from "@mui/icons-material/Folder";
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSelector } from "react-redux";
import FileBrowser from "./FileBrowser";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	height: "80vh",
	width: "80vw",
	overflow: "hidden",
	borderRadius: "5px",
	pt: 3,
	pb: 3,
	px: 4,
};

const TeamFilePicker = ({ onUpdate, onClose }) => {
	const [openModal, setOpenModal] = useState(true);
	const [breadcrumb, setBreadcrumb] = useState([{ id: "root", name: "Select Group" }]);
	const [currentGroup, setCurrentGroup] = useState({ id: "root", name: "Select Group" });
	const [selectedFiles, setSelectedFiles] = useState([]);

	const educationGroups = useSelector((state) => state.sections.educationGroups);
	const translations = useSelector(state => state.Languages.translations);

	const translate = React.useMemo(() => translateConstructor(translations), [translations]);

	const loadGroup = (groupId, groupName) => {
		setCurrentGroup({ id: groupId, name: groupName });
	};

	const goToBreadcrumb = (index) => {
		setBreadcrumb(breadcrumb.slice(0, index + 1));
	};

	const processSelectedFiles = (files) => {
		onUpdate(files);
		setOpenModal(false);
	}

	const onBreadcrumbChange = (newBreadcrumb) => {
		if (newBreadcrumb != null && newBreadcrumb.id == "root") {
			setBreadcrumb([newBreadcrumb]);
			setCurrentGroup({ id: "root", name: "Select Group" });
		}
	};


	const onCloseModal = () => {
		setOpenModal(false);

		if (onClose)
			onClose();
	}

	return (
		<Modal
			sx={{ zIndex: 99999999999999999, marginBottom: "0rem" }}
			open={openModal}
			onClose={onCloseModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={modalStyle}>
				<Box sx={{ height: '5%', mb: '1rem', display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
					<h2>{translate('Selected files')}</h2>

					{/* Reload Icon */}
					<IconButton onClick={onCloseModal} sx={{ ml: 2, flexShrink: 0 }}>
						<CloseIcon />
					</IconButton>
				</Box>

				{/* Group List */}
				{currentGroup.id == "root" ? (<Box sx={{ height: '90%' }}>
					{/* Breadcrumb Navigation */}
					<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
						<Breadcrumbs sx={{ flex: "1 1 auto", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
							{breadcrumb.map((item, index) => (
								<Link
									key={item.id}
									color={index === breadcrumb.length - 1 ? "text.primary" : "inherit"}
									onClick={() => goToBreadcrumb(index)}
									sx={{ cursor: "pointer" }}
								>
									{item.name}
								</Link>
							))}
						</Breadcrumbs>
					</Box>
					<List sx={{ height: '90%', overflowY: 'auto' }}>
						{educationGroups.map((group) => (
							<ListItem key={group.id} disablePadding>
								<ListItemButton onClick={() => loadGroup(group.graphId, group.title)}>
									<ListItemIcon>
										<GroupIcon color="primary" />
									</ListItemIcon>
									<ListItemText primary={group.title} />
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Box>) : null}

				{currentGroup.id != "root" ? <Box sx={{ height: '90%' }}><FileBrowser onClose={onCloseModal} onUpdate={processSelectedFiles} source={currentGroup.id} initialBreadCrumbs={[{ id: "root", name: "Select Group" }, { id: currentGroup.id, name: currentGroup.name }]} onBreadcrumbChange={onBreadcrumbChange} /></Box> : null}
			</Box>
		</Modal>
	);
};

export default TeamFilePicker;
