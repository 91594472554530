import React, { useEffect, useMemo, useState } from 'react';
import { useTranslate } from 'lib/translate';
import User from '_class/User';

import { Floating } from '@haldor/ui';
import { useSelector, useDispatch } from 'react-redux';
import { PRESCHOOL } from 'constants/schoolTypes';

import {
	clearScheduleFilter,
	toggleAdvanceCalendar,
	toggleMySchedule,
	UpdateFilter,
} from 'actions/ScheduleFilter';

import { Button, Checkbox, Icon, TooltipMenu } from '@haldor/ui';
import { Expandable, List } from 'UI';
import DropDownList from './DropDownList';
import ListSelector from './Partials/ListSelector';

const getLengthOfObject = (object) => {
	let ignore = ['Meeting', 'Assignment', 'Lesson', 'Test'];
	return Object.entries(object).filter(([key, value]) => ignore.indexOf(key) < 0 && value === true).length;
};

const getActivityLength = (data) => {
	return data.reduce((sum, element) => {
		if (element.id === 'Test') return sum; // Don't include test checkbox in count
		if (!element.subMenu) return sum + 1; // Count checkboxes without subMenu
		return sum + (element.subMenu ? element.subMenu?.length : 0); // Count subMenu checkboxes
	}, 0);
};

const CalenderHeader = (props) => {
	const translate = useTranslate();
	const {
		overview,
		toggleButtons,
		goBack,
		toggleIcon,
		notReportedSchedulePositions,
		toggleForm,
		isToday,
		goToday,
		isStudentCard,
		displayAdvanced,
		goForward,
		scheduleLoading
	} = props;

	const [isActive, setIsActive] = useState(false);
	const [isIndeterminate, setIsIndeterminate] = useState(false);
	const [disableSaveSettings, setDisableSaveSettings] = useState(true);
	const [settingsLoaded, setSettingsLoaded] = useState(false);
	const [studentFilterLoaded, setStudentFilterLoaded] = useState(false);

	const dispatch = useDispatch();
	const school = useSelector((state) => state.user.activeSchool);
	const activities = useSelector((state) => state.status.status.assignmentTypes);
	const service = useSelector((state) => state.Services.availableServices);
	const user = useSelector((state) => state.user.currentUser);
	const ScheduleFilter = useSelector((state) => state.ScheduleFilter);
	const loggedInUser = useMemo(() => new User(user), []);
	const actvitiesLabels = useMemo(() => [
		{
			id: 'Test',
			value: 'Test',
		},
		{
			id: 'Lesson',
			value: 'lessons',
			subMenu:
				[
					{
						id: 'LESSON_TYPE_REGULAR',
						value: 'Lesson',
					},
					{
						id: 'LESSON_TYPE_TEST',
						value: 'Test (Lessons)',
					},
				],
		},
		{
			id: 'Assignment',
			value: 'Assignments',
			subMenu: activities,
		},
		{
			id: 'CalendarEvent',
			value: 'Calendar events',
		},
		{
			id: 'Meeting',
			value: 'Meetings',
			subMenu: !loggedInUser.isStudent() ?
				[
					{
						id: 'booked',
						value: 'Booked meetings',
					},
					{
						id: 'unBooked',
						value: 'Unbooked meetings',
					},
				]
				: [],
		},
	], [ScheduleFilter.advancedCalendarActive]);
	const testCategories = [
		{
			id: 'Lesson',
			subIds: ['LESSON_TYPE_TEST']
		},
		{
			id: 'Assignment',
			subIds: ['ASSIGNMENT_TYPE_WRITTEN_TEST', 'ASSIGNMENT_TYPE_VERBAL_TEST']
		}
	];

	//handle checkbox for individual activities
	const handleActivity = (checked, data, name, parentId, checkedOffset = 0) => {
		let obj = {};
		if (name === 'Test') { // select test categories
			obj['Test'] = checked;
			testCategories.forEach(parentCategory => {
				parentCategory?.subIds.forEach((id) => {
					obj[id] = checked;
				});
			});
		} else {
			obj[name] = checked;
		}

		// Update parent checkboxes 
		Object.values(actvitiesLabels).forEach(item => {
			if (item?.subMenu?.length > 0) {
				let uncheckedItems = item.subMenu.filter(x => obj[x.id] != undefined ? obj[x.id] == false : ScheduleFilter.activitiesFilter[x.id] == false).length;
				item.indeterminate = uncheckedItems < item.subMenu.length;
				obj[item.id] = uncheckedItems <= 0;
			}
		});

		dispatch(UpdateFilter(false, obj));
	};

	//handle checkbox for each group of activities
	const handleAllSub = (checked, data, name, allButton) => {
		let obj = {};
		for (let i = 0; i < data.length; i++) {
			obj[data[i].id] = checked;
		}

		actvitiesLabels.find(x => x.id === name).indeterminate = false;

		if (allButton) {
			return obj;
		} else {
			obj[name] = checked;
		}
		dispatch(UpdateFilter(false, obj));
	};

	//set default values if labels exists
	useEffect(() => {
		if (actvitiesLabels.length < 1) {
			return;
		}

		if (!settingsLoaded) {
			// Activities filter			
			Object.keys(getAllActivitiesChecked(actvitiesLabels)).forEach((key) => {// All activites checked as default (also defaults any activites not in savedFilter)
				ScheduleFilter.activitiesFilter[key] = true;
			});
			let savedFilter = JSON.parse(localStorage.getItem('haldor-schedule-activities-filter'));
			if (savedFilter && Object.keys(savedFilter).length > 0) { // Load filter from local storage
				// Restore values for checkboxes
				Object.keys(savedFilter).forEach(key => {
					ScheduleFilter.activitiesFilter[key] = savedFilter[key];
				});

				// Restore indeterminate state for parent checkboxes
				Object.values(actvitiesLabels).forEach(parent => {
					if (parent?.subMenu) {
						parent.indeterminate = parent.subMenu.filter(element => { return !ScheduleFilter.activitiesFilter[element.id] }).length < parent.subMenu.length;
					}
				});
			}

			// Buttons
			let savedButtonStates = JSON.parse(localStorage.getItem('haldor-schedule-buttons'));
			if (savedButtonStates) {
				if (savedButtonStates['advancedCalendarActive'] != ScheduleFilter.advancedCalendarActive) {
					ScheduleFilter.advancedCalendarActive = savedButtonStates['advancedCalendarActive'];
				}
				if (savedButtonStates['displayMySchedule'] != ScheduleFilter.displayMySchedule) {
					ScheduleFilter.displayMySchedule = savedButtonStates['displayMySchedule'];
				}
			}

			setDisableSaveSettings(false);
			setSettingsLoaded(true);
		}
	}, [actvitiesLabels]);

	// Save button states
	useEffect(() => {
		if (disableSaveSettings) {
			return;
		}

		let buttonStates = {
			advancedCalendarActive: ScheduleFilter.advancedCalendarActive,
			displayMySchedule: ScheduleFilter.displayMySchedule
		};
		localStorage.setItem('haldor-schedule-buttons', JSON.stringify(buttonStates));
	}, [ScheduleFilter.advancedCalendarActive, ScheduleFilter.displayMySchedule]);

	// Save filter and update custom checkboxes when activitiesFilter changes
	useEffect(() => {
		if (!disableSaveSettings) {
			localStorage.setItem('haldor-schedule-activities-filter', JSON.stringify(ScheduleFilter.activitiesFilter));
		}

		// Check the 'all activities' box if all boxes are checked		
		let count = getLengthOfObject(ScheduleFilter?.activitiesFilter);
		let max = getActivityLength(actvitiesLabels);
		if (count === max) {
			dispatch(UpdateFilter(true, ScheduleFilter.activitiesFilter));
		}

		// Check the test checkbox if all test categories are checked
		let allSelected = testCategories.filter(parent => parent?.subIds.filter(id => ScheduleFilter.activitiesFilter[id] == false).length > 0).length < 1;
		if (ScheduleFilter.activitiesFilter['Test'] != allSelected) {
			dispatch(UpdateFilter(false, { 'Test': allSelected }));
		}
		
	}, [ScheduleFilter.activitiesFilter]); //, ScheduleFilter.advancedCalendarActive]);

	// Update checkboxes when advancedCalenderActive is toggled
	useEffect(() => {
		// update state of parents 
		let obj = {};
		Object.values(actvitiesLabels).forEach(item => {
			if (item?.subMenu?.length > 0) {
				let uncheckedItems = item.subMenu.filter(x => ScheduleFilter.activitiesFilter[x.id] == false).length;
				item.indeterminate = uncheckedItems < item.subMenu.length;
				obj[item.id] = uncheckedItems <= 0;
			}
		});

		// Determine state of the 'all activities' checkbox and send the update
		let count = getLengthOfObject(ScheduleFilter?.activitiesFilter);
		let max = getActivityLength(actvitiesLabels);
		dispatch(UpdateFilter(count === max, { ...ScheduleFilter.activitiesFilter, ...obj }));
	}, [ScheduleFilter.advancedCalendarActive]);

	const allActivites = (checked, arrName, groups) => {
		if (checked) {
			dispatch(UpdateFilter(false, getAllActivitiesChecked(arrName, groups)));
			return;
		}

		let obj = {};
		for (const [key, value] of Object.entries(ScheduleFilter.activitiesFilter)) {
			obj[key] = false;
			ScheduleFilter.activitiesFilter[key] = false;
		}
		for (let i = 0; i < arrName.length; i++) {
			if (arrName[i]?.subMenu) {
				arrName[i].indeterminate = false;
			}
		}

		dispatch(clearScheduleFilter(obj));
	};

	const getAllActivitiesChecked = (arrName, groups) => {
		let obj = {};
		for (let i = 0; i < arrName.length; i++) {
			if (groups) {
				obj[arrName[i].title] = true;
			} else {
				if (arrName[i].id) {
					obj[arrName[i].id] = true;
				}
				if (arrName[i].subMenu?.length > 0) {
					let sub = arrName[i].subMenu;
					let subValues = handleAllSub(true, sub, arrName[i].id, true);
					obj = { ...obj, ...subValues };
				}
			}
		}
		return obj;
	}

	return (
		<div>
			<div className="calendar-helper">
				<Floating trigger={(<Icon name="Help" />)}>
					<div className="helper--content">
						<strong>{translate('Sign explanation')}</strong>

						<div className="helper--content--item df aic">
							<div className="checkbox-imitator" style={{
								backgroundColor: '#fff'
							}} />

							{translate('My activities')}
						</div>

						<div className="helper--content--item df aic">
							<div className="checkbox-imitator" style={{
								backgroundColor: '#ededed'
							}} />

							{translate('Other peoples activities')}
						</div>

						<div className="helper--content--item df aic">
							<div className="checkbox-imitator" style={{
								backgroundColor: '#fff',
								borderLeftWidth: 3,
								borderLeftColor: '#027adc'
							}} />

							{translate('Lesson')}
						</div>

						<div className="helper--content--item df aic">
							<div className="checkbox-imitator" style={{
								backgroundColor: '#fff',
								borderLeftWidth: 3,
								borderLeftColor: '#ea4b9a'
							}} />

							{translate('Test')}
						</div>
					</div>
				</Floating>
			</div>

			<div className='calenderToolBar'>
				{overview && isStudentCard ? (
					<div className='calendar-title-overView'>
						<Icon name='Calendar' />

						<span className='schedule-title'>
							{translate('schedule-header-overview')}
						</span>
					</div>
				) : null}

				{toggleButtons()}

				<div className='navigation-buttons-container'>
					<Button
						type='secondary'
						style={{ marginLeft: 0, marginRight: '0.5em' }}
						onClick={goBack}
						disabled={scheduleLoading}
					>
						{toggleIcon(false)}
						&nbsp;
					</Button>

					<Button
						type='secondary'
						disabled={isToday || scheduleLoading}
						style={{ marginRight: '0.5em', marginLeft: 0 }}
						onClick={goToday}
					>
						{translate('today')}
					</Button>

					<Button
						type='secondary'
						style={{ marginLeft: 0 }}
						onClick={goForward}
						disabled={scheduleLoading}
					>
						{toggleIcon(true)}
						&nbsp;
					</Button>

					{overview && !loggedInUser.isStudent() ? (
						<div style={{ marginLeft: '1rem' }} className='dashboard-create'>
							{(loggedInUser?.isTeacher() || loggedInUser?.isMentor()) && !isStudentCard && !props.noCreate ?
								service.advancedSchedule && school.type != PRESCHOOL ? (
									<TooltipMenu
										trigger={(
											<Button type='secondary'>
												<Icon name='Plus' /> {translate('Create')}
											</Button>
										)}
									>
										<TooltipMenu.Item onClick={() => toggleForm(true)}>
											{translate('create-lesson')}
										</TooltipMenu.Item>

										<TooltipMenu.Item onClick={() => props.toggleAssignment(true)}>
											{translate('Create assignment')}
										</TooltipMenu.Item>

										<TooltipMenu.Item onClick={() => props.toggleGroupAssignment(true)}>
											{translate('Create group assignment')}
										</TooltipMenu.Item>

										<TooltipMenu.Item onClick={() => props.toggleConflictingEvent(true)}>
											{translate('Create calendar event')}
										</TooltipMenu.Item>
									</TooltipMenu>
								) : (
									<Button
										type='secondary'
										onClick={() => toggleForm()}
									>
										<Icon name='Plus' /> {translate('Create')}
									</Button>
								)
								: null}
						</div>
					) : null}
				</div>

				{service.advancedSchedule && school.type != PRESCHOOL ? (
					<div className='filtering'>
						{ScheduleFilter.advancedCalendarActive && !loggedInUser.isStudent() && displayAdvanced ? (
							<div className='filter-activties'>
								<span className='filter-headline'>{translate('select students')}</span>
								<ListSelector
									filterLoaded={studentFilterLoaded}
									setFilterLoaded={setStudentFilterLoaded}
									scheduleLoading={scheduleLoading}
								/>
							</div>
						) : null}

						{!props.noFilter ? (
							<div className='filter-activties'>
								<span className='filter-headline'>{translate('select activities')}</span>
								<DropDownList
									label={`${translate('Displaying')} ${getLengthOfObject(
										ScheduleFilter?.activitiesFilter,
										loggedInUser.isStudent(),
										ScheduleFilter.advancedCalendarActive
									)} ${translate('of')} ${getActivityLength(
										actvitiesLabels,
										loggedInUser.isStudent()
									)}`}
									offset={15}
									OffsetX={true}
									placement={'bottom-end'}
								>
									<Checkbox
										value={ScheduleFilter.allFilter}
										onChange={(value) => allActivites(value, actvitiesLabels)}
										label={translate('Select all')}
									/>

									<ul className='filterModal'>
										{actvitiesLabels.map((item) => {
											if (!item?.id) {
												return;
											}
											return item.subMenu?.length > 0 ? (
												<Expandable
													key={item.id}
													contentStyles={{
														padding: 0,
													}}
													contentStylesClosed={{
														padding: '0',
													}}
													headerStyles={{
														border: 'none',
													}}
													title={
														<Checkbox
															value={ScheduleFilter.activitiesFilter[item.id]}
															indeterminate={item?.indeterminate}
															onChange={(value) =>
																handleAllSub(value, item.subMenu, item.id)
															}
															label={translate(item.value)}
														/>
													}
												>
													{item?.subMenu?.map((items) => (
														ScheduleFilter.advancedCalendarActive && item.id === 'unBooked' ?
															<div></div> :
															<List key={items.id}>
																<Checkbox
																	value={
																		ScheduleFilter.activitiesFilter[items.id]
																	}
																	onChange={(value) =>
																		handleActivity(
																			value,
																			item.subMenu,
																			items.id,
																			item.id
																		)
																	}
																	label={translate(items.value)}
																/>
															</List>
													))}
												</Expandable>
											) : (
												<Checkbox
													key={item.id}
													value={ScheduleFilter.activitiesFilter[item.id]}
													onChange={(value) => handleActivity(value, 0, item.id)}
													label={translate(item.value)}
												/>
											)
										})}
									</ul>
								</DropDownList>

								{!ScheduleFilter.allFilter ?
									<div className="clear-filter-container" onClick={() => allActivites(true, actvitiesLabels)}>
										<Icon name="Close" /> {translate('clear-filter')}
									</div> : null}

							</div>
						) : null}

						<div className='checkBoxWrapper'>
							{
								ScheduleFilter.advancedCalendarActive &&
									!loggedInUser.isStudent() &&
									displayAdvanced &&
									service.advancedSchedule &&
									school.type != PRESCHOOL ? (
									<div className='checkBoxContainer'>
										<Checkbox
											value={ScheduleFilter.displayMySchedule}
											onChange={() => dispatch(toggleMySchedule())}
											type={'no_background'}
											label={<div className='size-16'>{translate('Display my schedule')}</div>}
										/>
									</div>
								) : null
							}

							{!isStudentCard &&
								displayAdvanced &&
								service.advancedSchedule &&
								school.type != PRESCHOOL ? (
								<div className='checkBoxContainer'>
									<Checkbox
										value={ScheduleFilter.advancedCalendarActive}
										onChange={() => dispatch(toggleAdvanceCalendar())}
										label={<div className='size-16'>{translate('Advanced mode')}</div>}
										disabled={scheduleLoading}
									/>
								</div>
							) : null}
						</div>
					</div>
				) : null}

				{notReportedSchedulePositions()}
			</div>
		</div>
	);
};

export default CalenderHeader;
