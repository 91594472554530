import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Moment from "moment";
import { getSchoolDetails, updateSchoolType } from "actions/schools";
import { getActiveSchool } from 'helpers/localstorage';
import SectionTitle from 'components/Presentation/SectionTitle';
import { DataList, Expandable } from 'UI';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { Flex, Skeleton, Menu, Icon, TooltipMenu, Button, getActiveLanguage } from '@haldor/ui';
import { setActiveSchool } from 'actions/user';
import User from '_class/User';
import swal from "sweetalert2";


class ExportAssessmentForSchool extends Component {
	constructor(props) {
		super(props)

		Moment.locale(getActiveLanguage());

		this.state = {
			loading: true,
			school: [],
			schools: [],
			typeOfSchools: [
				{ id: 1, type: "PRESCHOOL" },
				{ id: 2, type: "COMPULSORY_SCHOOL" },
				{ id: 3, type: "UPPER_SECONDARY_EDUCATION" },
				{ id: 4, type: "ADULT_EDUCATION" }
			]

		}
	}

	componentDidMount = () => {
		let schools = [];
		this.props.currentUser.schools.forEach(school => {
			if (this.userSchoolAdmin(school.id)) {
				schools.push({
					title: school.title,
					id: school.id,
					type: school.type,
					typeLocalizes: this.props.translate(school.type),
					typeOfSchools: [
						{ id: 1, type: "PRESCHOOL" },
						{ id: 2, type: "COMPULSORY_SCHOOL" },
						{ id: 3, type: "UPPER_SECONDARY_EDUCATION" },
						{ id: 4, type: "ADULT_EDUCATION" }
					]
				});
			}
		});

		let school = this.props.school;
		this.setState({ loading: false, schools, school });

	}

	userSchoolAdmin = (schoolId) => {

		if (this.props.currentUser.roles != null && this.props.currentUser.roles.length > 0) {
			var schoolAdministrator = this.props.currentUser.roles.find(role => (role.roleId.toUpperCase() == "SCHOOL_ADMINISTRATOR" && role.referenceId == schoolId) || (role.roleId.toUpperCase() == "SYSADMIN"));

			if (schoolAdministrator != null) {
				return true;
			}
		}

		return false;
	}

	static contextTypes = {
		router: PropTypes.object,
	};


	renderSchoolRow = (school) => {
		const dropdownTrigger = (<div className="dropdown--trigger">
			{school.typeLocalizes} <Icon name="Chevron" />
		</div>);
		return (
			<RowItem key={school.id} >
				<RowCell title={this.props.translate('name')}>
					{school.title}
				</RowCell>
			</RowItem>
		);
	}

	render() {
		const user = new User(this.props.currentUser);
		const activeSchool = getActiveSchool(this.props.currentUser.id);
		if (this.state.loading) {
			return (
				<div className="single-section">
					<SectionTitle>
						<Skeleton />
					</SectionTitle>
					<Flex>
						<div style={{ flex: 1 }}>
							<div>
								<Skeleton />
							</div>
						</div>
					</Flex>
				</div>
			)
		}

		return (
			<div className="single-section">

				<DataList
					data={this.state.schools}
					renderRow={this.renderSchoolRow}
					emptyData={() => (
						<h3 style={{ textAlign: 'center' }}>
							{this.props.translate('no-results')}
						</h3>
					)}
				/>
			</div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		languages: state.Languages.languages,
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		currentUser: state.user.currentUser,
		school: state.School.active
	};
}

export default connect(mapStateToProps, {
	setActiveSchool,
	getSchoolDetails,
	updateSchoolType,
})(ExportAssessmentForSchool);