import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ASSESSMENT_TAB, ASSIGNMENT_TAB, PLANNING_TAB } from 'constants/teamsTabs';

import { getTenant } from 'actions/user';
import { clearAssignmentsFromStore, clearAssignmentEventPaginationFromStore } from 'actions/assignments';
import { clearBlockTypes } from 'actions/blocks';
import { clearCoursesFromStore } from 'actions/courses';
import { clearDocumentsFromStore } from 'actions/documents';
import { clearNotificationsFromStore } from 'actions/notifications';
import { clearPlansFromStore } from 'actions/plans';
import { getMenuItems } from 'actions/menu';
import { clearPostsFromStore } from 'actions/posts';
import { clearNotReportedSchedulePositions, clearScheduleFromStore } from 'actions/schedule';
import { getSchoolServices } from 'actions/schools';
import { clearSectionsFromStore, getEducationGroups } from 'actions/sections';
import { clearSearch } from 'actions/search';
import { ClearAllFilter } from 'actions/ScheduleFilter';
import { clearPlanFilterObjects } from 'helpers/localstorage';

import { Spinner } from 'UI';

class SchoolSwitch extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		reloadSchoolProps(this.props);

		this.props.getSchoolServices(this.props.activeSchool).then(() => {
			if (this.props.activeTab != null) {
				if (this.props.activeTab == ASSIGNMENT_TAB) {
					this.props.history.push('/assignments-tab');
				} else if (this.props.activeTab == PLANNING_TAB) {
					this.props.history.push('/planning-tab');
				} else if (this.props.activeTab == ASSESSMENT_TAB) {
					this.props.history.push('/assessments-tab');
				}
			} else {
				this.props.history.push('/');
			}
		});
	}

	render() {
		return (
			<div>
				<div className='app-comp-content content'>
					<Spinner center />
				</div>
			</div>
		);
	}
}

export function reloadSchoolProps(props) {
	props.clearSearch();
	props.clearBlockTypes();
	props.clearAssignmentsFromStore();
	props.clearAssignmentEventPaginationFromStore();
	props.clearPlansFromStore();
	clearPlanFilterObjects();
	props.clearSectionsFromStore();
	props.clearDocumentsFromStore();
	props.clearPostsFromStore();
	props.clearScheduleFromStore();
	props.clearCoursesFromStore();
	props.clearNotificationsFromStore();
	props.clearNotReportedSchedulePositions();
	props.getEducationGroups();
	props.getMenuItems();
	props.getTenant();
	props.ClearAllFilter();
}

function mapStateToProps(state) {
	return {
		activeTab: state.user.activeTab,
		activeSchool: state.user.activeSchool,
	};
}

export var reloadSchoolActions = {
	clearAssignmentsFromStore,
	clearAssignmentEventPaginationFromStore,
	clearBlockTypes,
	clearPlansFromStore,
	clearSectionsFromStore,
	clearDocumentsFromStore,
	clearPostsFromStore,
	clearScheduleFromStore,
	clearCoursesFromStore,
	clearNotificationsFromStore,
	clearNotReportedSchedulePositions,
	getEducationGroups,
	getMenuItems,
	clearSearch,
	getTenant,
	getSchoolServices,
	ClearAllFilter,
};

export default withRouter(
	connect(mapStateToProps, reloadSchoolActions)(SchoolSwitch)
);
