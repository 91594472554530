import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import Moment from "moment";
import swal from 'sweetalert2';

import User from '_class/User';

import { getGuardianById, setGuardian, deleteStudentGuardian } from "actions/user";
import { getSchoolGuardians } from "actions/schools";
import { setPageTitle } from "actions/header";
import { getActiveSchool } from 'helpers/localstorage';

import GuardianDetailsForm from '../Forms/GuardianDetailsForm';
import Modal from 'containers/Modals/Modal';

import { Block as ContentBlock, Flex, Skeleton, TooltipMenu, Button, getActiveLanguage } from '@haldor/ui';
import { Spinner, DataList } from 'UI';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import Block, { BlockHeader, BlockContent, BlockWrapper } from "UI/Elements/Block";

class Guardian extends Component {

	constructor(props) {
		super(props)

		Moment.locale(getActiveLanguage());

		this.state = {
			loading: true,
			modal: false,
			loadingGuardians: true,
			guardian: null,
			guardians: null
		}
	}

	fetchGuardianData = (userId) => {
		if (userId) {
			this.props.getGuardianById(userId).then(() => {
				let guardian = this.props.currentGuardian;
				this.props.setPageTitle(guardian.firstName + ' ' + (guardian.lastName != null ? guardian.lastName : ''));
				this.setState({ loading: false, loadingGuardians: false, guardian: guardian, guardians: null });
			});
		} else {
			const school = getActiveSchool(this.props.currentUser.id);
			this.props.getSchoolGuardians(school).then(() => {
				this.props.setPageTitle(this.props.translate('Guardians'));
				let guardians = this.props.guardians;
				this.setState({ loading: false, loadingGuardians: false, guardian: null, guardians: guardians });
			})
		}
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.match.params.userId !== prevProps.match.params.userId) {
			this.fetchGuardianData(this.props.match.params.userId);
		}
	};

	componentDidMount = () => {
		let userId = null;
		if (this.props.match.params != null && this.props.match.params.userId != null) {
			userId = this.props.match.params.userId;
		}

		this.fetchGuardianData(userId);
	}

	toggleModal = () => {
		this.setState({ modal: !this.state.modal });
	}

	onGuardianUpdate = guardian => {
		if (guardian.id != "") {
			this.props.setGuardian(guardian.id, guardian).then(() => {
				this.setState({ guardian: guardian });
				this.toggleModal();
			});
		} else {
			this.props.getUserGuardianByEmail(guardian.mail).then(() => {
				if (this.props.guardianByMail == null) {
					this.props.addUserGuardian(this.props.user, guardian).then(() => {
						this.toggleModal();
					});
				}
			});
		}
	}

	onMailChanged = (email) => {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(String(email).toLowerCase())) {
			this.props.getUserGuardianByEmail(email).then(() => {
				if (this.props.guardianByMail != null && this.props.guardianByMail.mail == email) {
					let guardian = this.props.guardianByMail;
					guardian.id = "";
					this.toggleModal();
					this.toggleModal("guardian", guardian);
				}
			});
		}
	}

	onGuardianDelete = guardian => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Delete'),
		}).then(response => {
			if (response.value != null) {
				this.props.deleteStudentGuardian(this.props.user, guardian.id, true).then(() => {
					this.setState({ guardian: null });
					this.props.history.push('/guardian');
					this.toggleModal();
				})
			}
		});
	}

	renderGuardian = (guardian) => {
		return <RowItem key={guardian.id} target={`/guardian/${guardian.id}`}>
			<RowCell title={this.props.translate('name')}>
				{guardian.firstName + " " + (guardian.lastName != null ? guardian.lastName : '')}
			</RowCell>

			<RowCell title={this.props.translate('e-mail')}>
				{guardian.mail}
			</RowCell>
		</RowItem>
	}

	render() {
		const { translate } = this.props;
		const user = new User(this.props.currentUser);
		const triggerButton = (
			<Button type="secondary" style={{ marginLeft: 0 }}>
				{"⋯"}
			</Button>
		);

		if (this.state.loading) {
			return (
				<div>
					<div className="guardian-card">
						<Block key='guardian'>
							<BlockHeader>
							</BlockHeader>
							<BlockContent style={{ paddingTop: '0px' }} >
								<Flex>
									<div style={{ flex: 1 }}>
										<div style={{ width: 125 }}>
											<Skeleton />
										</div>
									</div>
								</Flex>
							</BlockContent>
						</Block>
					</div>
				</div>
			)
		}

		return (
			this.state.guardian != null ?
				<div className="guardian-card">
					<Modal onClose={this.toggleModal} overridePrompt title={this.state.modalType == "user" ? this.props.translate('edit-student') : this.state.currentGuardian != null && this.state.currentGuardian.id == "" ? this.props.translate('create-guardian') : this.props.translate('edit-guardian')} isOpen={this.state.modal} type="small">
						<GuardianDetailsForm
							currentGuardian={this.state.guardian}
							cancelModal={this.toggleModal}
							onModalSave={this.onGuardianUpdate}
							onMailChanged={this.onMailChanged}
							noStudent={true}
						></GuardianDetailsForm>
					</Modal>

					<ContentBlock>
						{user.isAdministrator() && !this.state.guardian.synced ?
							<div style={{ float: "right" }}>
								<TooltipMenu trigger={triggerButton} id={this.state.guardian.id}>
									<TooltipMenu.Item onClick={() => this.toggleModal("guardian", this.state.guardian)}>
										{this.props.translate('Edit')}
									</TooltipMenu.Item>

									<TooltipMenu.Item onClick={() => this.onGuardianDelete(this.state.guardian)}>
										{this.props.translate('Delete')}
									</TooltipMenu.Item>
								</TooltipMenu>
							</div>
							: null}

						<div style={{ paddingTop: '0px' }} >
							<Flex>
								<div style={{ flex: 1 }}>
									{this.state.guardian.phone != null ?
										<div style={{ marginTop: '.55rem' }}>
											<strong>{translate('phone')}</strong>
											<br />
											{this.state.guardian.phone}
										</div>
										: null}

									{this.state.guardian.mail != null ?
										<div style={{ marginTop: '.55rem' }}>
											<strong>{translate('e-mail')}</strong>
											<br />
											{this.state.guardian.mail}
										</div>
										: null}
								</div>

								<div style={{ flex: 1 }}>
									{this.state.guardian.street != null && this.state.guardian.postal != null ?
										<div style={{ marginTop: '.55rem' }}>
											<strong>{translate('address')}</strong>
											<br />
											{this.state.guardian.street}<br />
											{this.state.guardian.postal}, {this.state.guardian.city}
										</div>
										: null}
								</div>
							</Flex>
						</div>
					</ContentBlock>

					<div style={{ padding: '15px 0px 0px 15px' }}>
						<Link to="/guardian">{translate('show-all-guardians')}</Link>
					</div>
				</div>
				:
				<div className="overview">
					<div style={{ marginTop: '2rem' }}>
						{user.isAdministrator() || user.isMentor() ?
							<DataList
								data={this.state.loadingGuardians ? [] : this.props.guardians?.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))}
								renderRow={this.renderGuardian}
								emptyData={this.state.loadingGuardians ? () => (
									<div>
										<Spinner center />
									</div>
								) : () => (
									<div className="color--meta text--center weight--bold">
										{translate('no-results')}
									</div>
								)}
							/>
							: null}
					</div>
				</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		languages: state.Languages.languages,
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		currentGuardian: state.user.guardian,
		guardians: state.School.guardians,
	};
}

export default withRouter(connect(mapStateToProps, {
	setPageTitle,
	getGuardianById,
	setGuardian,
	getSchoolGuardians,
	deleteStudentGuardian,
})(Guardian));