import React, { useEffect, useState } from "react";
import { Button, Menu, MenuItem, ListItemIcon, Typography } from "@mui/material";
import CloudIcon from "@mui/icons-material/Cloud";
import GroupsIcon from "@mui/icons-material/Groups";
import UploadIcon from "@mui/icons-material/Upload";
import { useSelector } from "react-redux";
import { translate as translateConstructor, Button as HaldorButton, Icon } from '@haldor/ui';

import OneDriveFilePicker from "./OneDriveFilePicker";
import TeamFilePicker from "./TeamFilePicker";
import LocalFilePicker from "./LocalFilePicker";
import { OneDriveIcon } from "UI/Icons";

const FileUploadMenu = ({ onUpdate }) => {
	const translations = useSelector(state => state.Languages.translations);
	const translate = React.useMemo(() => translateConstructor(translations), [translations]);

	const [selectedPage, setSelectedPage] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setSelectedPage(null);
	};

	const handleOnUpdate = (files) => {
		handleClose();

		if (onUpdate)
			onUpdate(files);
	}

	switch (selectedPage) {
		case 'ONE_DRIVE':
			return <OneDriveFilePicker onUpdate={handleOnUpdate} onClose={handleClose} />;
		case 'TEAM':
			return <TeamFilePicker onUpdate={handleOnUpdate} onClose={handleClose} />;
		case 'CUSTOM_UPLOAD':
			return <LocalFilePicker onUpdate={handleOnUpdate} onClose={handleClose} />;
	}

	return (
		<div>
			<HaldorButton
				onClick={handleClick}
			>
				{translate("Add files")}
			</HaldorButton>
			<Menu
				sx={{ zIndex: 9999999 }}
				anchorEl={anchorEl}
				open={open}
				onClose={() => { handleClose(null) }}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center", // Centered horizontally
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center", // Ensure it aligns properly
				}}
				slotProps={{
					paper: {
						elevation: 0,
						sx: {
							overflow: "visible",
							filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
							mt: 1.5,
							"& .MuiAvatar-root": {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							"&::before": {
								content: '""',
								display: "block",
								position: "absolute",
								top: 0,
								left: "50%",
								transform: "translateX(-50%) translateY(-50%) rotate(45deg)",
								width: 10,
								height: 10,
								bgcolor: "background.paper",
								zIndex: 0,
							},
						},
					},
				}}
			>
				<MenuItem onClick={() => setSelectedPage('ONE_DRIVE')}>
					<ListItemIcon sx={{ height: '20px' }}>
						<OneDriveIcon />
					</ListItemIcon>
					<Typography variant="inherit">One Drive</Typography>
				</MenuItem>
				<MenuItem onClick={() => setSelectedPage('TEAM')}>
					<ListItemIcon>
						<Icon name='Teams' />
					</ListItemIcon>
					<Typography variant="inherit">Team</Typography>
				</MenuItem>
				<MenuItem onClick={() => setSelectedPage('CUSTOM_UPLOAD')}>
					<ListItemIcon>
						<UploadIcon fontSize="small" />
					</ListItemIcon>
					<Typography variant="inherit">Ladda upp från enheten</Typography>
				</MenuItem>
			</Menu>
		</div>
	);
};

export default FileUploadMenu;
