import React, { Component } from "react";
import { Floating, translate } from '@haldor/ui';
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { withRouter } from 'react-router-dom';

import { addError } from "actions";
import { getPlanDetails, updatePlan, createPlan } from "actions/plans";
import { getSection, toggleSelectedSection, clearSelectedSections } from "actions/sections";
import {
	saveBlocksOnReference,
	updateBlocksOnReference,
	deleteBlock,
	createAssessmentBlock,
	updateAssessmentBlock,
} from 'actions/blocks';

import { getRootUrl } from "helpers/url";

import BlockEditor from 'components/BlockEditor';
import BlockAssessments from 'components/BlockAssessments';
import BlockPurpose from "components/Inputs/BlockPurposeField";
import BlockCoreContentField from "components/Inputs/BlockCoreContentField";
import Select from "components/Inputs/select";

import DatePickerFromTo from "../Partials/DatePickerFromTo";
import GroupSelector from "../Partials/GroupSelector";

import TeacherAdjustments from "containers/AdditionalAdjustments/Display/TeacherAdjustments";

import { Checkbox, Button } from '@haldor/ui';
import { Expandable, Spinner } from "UI";
import api from "lib/api";
import moment from "moment";


import { appInsights } from "lib/appInsights";
import { forEach } from "lodash";
import { Stack } from "@mui/material";
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';


class SinglePlanForm extends Component {

	constructor(props) {
		super(props);
		const { initialValues, editView } = props;

		this.state = {
			dateStart: null,
			dateEnd: null,
			status: null,
			SectionId: 0,
			loading: false,
			students: editView && initialValues != null ? initialValues.students : [],
			enableAutoAssign: initialValues != null ? initialValues.autoAssignStudents : null
		};

		// Define form reference
		this.formRef = null;
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		if (this.props.cloneView) {
			this.props.getPlanDetails(this.props.plan.id).then(() => {
				this.setState({ loading: false });
			});
		}

		let sectionId = null;
		if (this.props.initialValues != null) {
			sectionId = this.props.initialValues.sectionId;
			this.setState({ dateStart: this.props.initialValues.timeStart, dateEnd: this.props.initialValues.timeEnd });
		}

		if (this.props.sectionId != null) {
			sectionId = this.props.sectionId;
		}

		if (sectionId != null) {
			let find = this.props.groups.find(t => t.id == sectionId);
			if (find == null) {
				this.setState({ SectionId: 0, loading: false });
			} else {
				this.setState({ SectionId: sectionId });
				this.props.getSection(sectionId).then(() => {
					if (!this.props.editView) {
						this.setState({ students: this.props.section.students });
					}

					this.props.toggleSelectedSection(this.props.section);
					this.setState({ loading: false });
				});
			}
		} else {
			this.setState({ loading: false });
		}
	};

	reCheckAutoAssign = () => {

		if (this.formRef != null) {
			// Calculate the new value of enableAutoAssign based on current state/props
			const enableAutoAssign =
				this.state.students != null &&
				this.props.section != null &&
				this.props.section.students.every(student => { return this.state.students.some(selectedStudent => selectedStudent.id === student.id) });

			// Update only if enableAutoAssign value changes
			if (!enableAutoAssign) {
				this.formRef.mutators.setAutoAssignStudents(false);
			}
		}
	};

	componentWillUnmount = () => {
		this.props.clearSelectedSections();
	}

	/* Redux form functions */
	required = (value) => {
		if (typeof value !== "undefined" && value !== "") {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate("field-max-200-chars");
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate("field-needs-input");
	};

	submit = values => {
		const { initialValues } = this.props;
		const {
			dateStart,
			dateEnd,
			SectionId,
			status,
		} = this.state;

		if (this.state.SectionId == 0) {
			this.props.addError(this.props.translate("pick-a-section"), "error");
		}

		return new Promise((resolvePlan) => {
			values.timeStart = moment(dateStart).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
			values.timeEnd = moment(dateEnd).set({ hour: 23, minute: 59 });
			values.sectionId = SectionId;
			values.students = this.state.students;
			values.students = values.students.filter(t => t.remove != true);

			let blocks = [];
			let deletedBlocks = [];
			let assessmentBlocks = [];
			let promises = [];

			if (values.assessmentBlocks != null) {
				assessmentBlocks = [...values.assessmentBlocks];
			}

			if (values.blocks != null) {
				blocks = [...values.blocks];

				blocks.forEach((block) => {
					if (block.type == 'Haldor.Blocks.Assignment') {
						if (block.resources != null) {
							let resources = [];
							block.resources.forEach(resource => {
								if (resource.existing) {
									assessmentBlocks.forEach((block) => {
										promises.push(new Promise((resolve) => {
											if (resource['@odata.type'] == 'haldor.assignment.block.teamsassignment') {
												api.get(`assignments/${resource.id}/details`).then((response) => {
													this.props.createAssessmentBlock(block, response.data.id, 'ASSIGNMENT')
														.then(() => {
															resolve(1);
														});
												});

												return true;
											}

											this.props.createAssessmentBlock(block, resource.id, 'ASSIGNMENT')
												.then(() => {
													resolve(1);
												});
										}));
									});
								}
								resources.push({
									id: resource.id,
									'@odata.type': resource['@odata.type'],
								});
							});

							block.resources = resources;
						}
					}

					return block;
				})

				if (initialValues != null && initialValues.blocks != null) {
					deletedBlocks = [...initialValues.blocks].filter((block) =>
						!blocks.find(_bl => _bl.id == block.id) && !values.assessmentBlocks.find(_bl => _bl.id == block.id)
					);
				}
			}

			delete values.blocks;

			if (status != null) {
				values.status = status;
			}

			const that = this;

			if (this.props.editView && this.props.cloneView == false) {
				// values.associatedCoreContent[0].ReferenceId = initialValues.id;

				promises.push(new Promise((resolve) => {
					this.props.updatePlan(values).then(() => {
						resolve(1);
					});
				}));

				if (blocks != null && blocks.length > 0) {
					/**
					 * We need to validate if the block is new or existing and check the length of value.
					 * We want to find out when the content is deleted, since we have support tickets where the user says the content is deleted.
					 */
					blocks.forEach(block => {
						appInsights.trackTrace({
							message: "EDU024b | Updating block"
						}, {
							properties: {
								category: "planningForm-" + block.referenceId,
								blockId: block.id > 0 ? block.id : 0,
								valueLength: block.value != null ? block.value.length : 0,
							}
						});
					});

					let updatePromise = new Promise(resolve => {
						this.props.updateBlocksOnReference(blocks, initialValues.id, 'PLAN')
							.then(() => {
								resolve(1);
							})
					});

					promises.push(updatePromise);
				}

				if (values.assessmentBlocks != null) {
					values.assessmentBlocks.forEach((block) => {
						promises.push(new Promise(async (resolve) => {
							const initialBlock = this.props.initialValues.assessmentBlocks.find((b) => b.id == block.id);

							if (initialBlock != null) {
								await this.props.updateAssessmentBlock(block, initialBlock);
							} else {
								await this.props.createAssessmentBlock(block, initialValues.id, 'PLAN');
							}

							resolve(1);
						}));
					});
				}

				if (deletedBlocks.length > 0) {
					deletedBlocks.forEach(block => {
						/**
						 * This block will be deleted.
						 * We want to find out when the content is deleted, since we have support tickets where the user says the content is deleted.
						 */

						appInsights.trackTrace({
							message: "EDU024a | Deleting block"
						}, {
							properties: {
								category: "planningForm-" + block.referenceId,
								blockId: block.id,
							}
						});

						let deletePromise = new Promise(resolve => {
							this.props.deleteBlock(block.id).then(() => {
								resolve(1);
							})
						});

						promises.push(deletePromise);
					})
				}

				Promise.all(promises).then(() => {
					that.props.onAbort(true, true);

					resolvePlan(1);
				});
			} else {
				this.props.createPlan(values).then(() => {
					let planId = this.props.createdPlan;
					if (this.props.createdPlan.hasOwnProperty('id')) {
						planId = this.props.createdPlan.id;
					}

					if (blocks != null) {
						blocks.forEach(block => {
							block.id = 0;
							return block;
						});
					}

					if (values.assessmentBlocks != null) {
						values.assessmentBlocks.forEach((block) => {
							block.resources.map((resource) => {
								resource.originId = resource.id;
								resource.id = 0;
								return resource;
							});

							promises.push(new Promise(async (resolve) => {
								await this.props.createAssessmentBlock(block, planId, 'PLAN');
								resolve(1);
							}));
						});
					}


					if (blocks != null && blocks.length > 0) {
						blocks = blocks.filter(block => block.type !== 'Haldor.Blocks.AssessmentBlock');
						promises.push(new Promise((resolve) => {
							this.props.saveBlocksOnReference(blocks, planId, 'PLAN').then(() => {
								resolve(1);
							})
						}))
					}

					Promise.all(promises).then(() => {
						if (this.props.cloneView) {
							window.location = `${getRootUrl()}plan/${planId}`;
						} else {
							this.props.history.push(`${getRootUrl()}plan/${planId}`);
						}

						resolvePlan(1);
					});
				});
			}
		});
	};

	onDateChange = (dates) => {
		this.setState({ dateStart: dates.start, dateEnd: dates.end });
	};

	onGroupSelect = (groupid) => {
		if (groupid != 0) {
			this.setState({ SectionId: groupid }, () => {
				this.props.getSection(groupid).then(() => {
					this.setState({ students: this.props.section.students });
				});
			});
		} else {
			this.setState({ SectionId: 0 });
		}
	};

	onStatusSelect = event => {
		this.setState({ status: event.target.value });
	};

	renderStudents = () => {
		const { section } = this.props;
		const { SectionId } = this.state;

		if (section != null && SectionId != 0 && SectionId != null) {
			let sectionStudents = this.getSectionStudents();
			const sortedStudents = sectionStudents.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""));

			return sortedStudents.map((student) => {
				let checked = false;
				let disabled = false;

				this.state.students.find((studentPlan) => {
					if (studentPlan.id == student.id) {
						checked = true;
						if (studentPlan.remove) {
							checked = false;
						}

						if (this.props.assessments != null && this.props.editView) {
							this.props.assessments.find((assessment) => {
								if (assessment.studentId == student.id) {
									disabled = true;
								}
							});
						}
					}
				});

				let title = student.firstName + " " + student.lastName;
				if (disabled && this.props.editView) {
					title += " (" + this.props.translate("assessed") + ")";
				}

				if (student.missing) {
					title += " (" + this.props.translate("removed-from-group") + ")";
				}

				return (
					<div style={{ marginTop: "1em" }} key={student.id}>
						<Checkbox
							name="studentSelect"
							value={checked}
							disabled={disabled}
							onChange={() => {
								this.onStudentSelect(student);
							}}
							label={title}
						/>
					</div>
				);
			});
		} else {
			return (
				<div>{this.props.translate("select-a-group-to-continue")}</div>
			);
		}
	};

	getSectionStudents() {
		let sectionStudents = this.props.section.students;

		// Only get missingStudents when not in copy mode
		if (!this.props.cloneView) {
			let missingStudents = this.state.students.filter(
				t => sectionStudents.find(s => s.id == t.id) == null
			);

			missingStudents.forEach((student) => {
				student.firstName = student.firstname;
				student.lastName = student.lastname;
				student.missing = true;
			});

			sectionStudents = sectionStudents.concat(missingStudents);
		}
		return sectionStudents;
	}

	onStudentSelect = student => {
		let foundStudent = false;
		student.firstname = student.firstName;
		student.lastname = student.lastName;
		this.state.students.find((studentPlan) => {
			if (studentPlan.id == student.id) {
				foundStudent = true;
			}
		});

		let students = this.state.students;
		if (foundStudent) {
			if (student.missing && !student.remove) {
				student.remove = true;
			} else if (student.missing && student.remove) {
				student.remove = false;
			} else {
				students = students.filter(t => t.id != student.id);
			}
		} else {
			students.push(student);
		}

		this.setState({ students }, (() => {
			this.reCheckAutoAssign();
		}));
	};

	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					type={type}
					placeholder={placeholder || label}
					onKeyPress={e => {
						if (e.key == 'Enter') {
							e.preventDefault();
						}
					}}
					style={touched && error ?
						{ border: "1px solid red" }
						: {}}
					{...input}
				/>

				{touched &&
					((error && (
						<span style={{ marginTop: "1rem", color: "red" }}>
							{error}
						</span>
					)) ||
						(warning && (
							<span style={{ marginTop: "1rem", color: "red" }}>
								{warning}
							</span>
						)))}
			</div>
		);
	};

	handleCancel = (event) => {
		event.preventDefault();
		this.props.onAbort(true, false);
	};

	render() {
		const { initialValues } = this.props;
		const { SectionId } = this.state;
		const statuses = this.props.status.planningStatus.filter(t => t.selectable);

		let dates = {
			timeStart: this.state.dateStart,
			timeEnd: this.state.dateEnd,
		}

		if (this.state.loading) {
			return <Spinner center />;
		}

		return (
			<div className="form-container form">
				<Form
					onSubmit={this.submit}
					mutators={{
						setStatusDraft: (args, state, utils) => {
							utils.changeValue(state, 'status', () => 'PLANNING_DRAFT');
						},
						setStatusOpen: (args, state, utils) => {
							utils.changeValue(state, 'status', () => 'PLANNING_OPEN');
						},
						setAutoAssignStudents: (args, state, utils) => {
							utils.changeValue(state, 'autoAssignStudents', () => args[0]);
						},
					}}
					initialValues={this.props.initialValues}
					render={({ submitting, handleSubmit, form, valid }) => {
						this.formRef = form;
						let blocks = form.getFieldState('assessmentBlocks')?.value;
						let subjects = [];

						if (this.props.section != null && blocks?.length > 0) {
							blocks?.forEach((content) => {
								content?.resources?.forEach(resources => {
									resources?.value?.details?.forEach(details => {
										let course = this.props.section.courses.find(course => {
											return course.details.find((detail) => {
												return detail.id == details.id;
											})
										})
										if (course != null) {
											if (subjects.indexOf(course.subjectId) == -1) {
												subjects.push(parseInt(course.subjectId));
											}
										}
									});

								})
							});
						}

						/**
						 * Enable the auto assign option if all groups have all students selected
						 */
						let enableAutoAssign = this.state.students != null &&
							this.props.section != null &&
							this.props.section.students.every(student => { return this.state.students.some(selectedStudent => selectedStudent.id === student.id) });

						return (
							<form onSubmit={handleSubmit} className="form form-component">
								{submitting ? (
									<div className="is_sending">
										<p>
											<span className="loading-spinner" />
										</p>
									</div>
								) : null}


								<div className="form-row">
									{!this.props.editView ?
										<GroupSelector
											disabled={
												this.props.sectionId != null && this.props.cloneView == false ? true : false
											}
											required
											onChange={this.onGroupSelect}
											sectionId={this.state.SectionId}
										/>
										: null}
								</div>

								<div style={{ marginTop: "0.5em" }}>
									<Expandable title={this.props.translate("students")}>
										{this.renderStudents()}
									</Expandable>
								</div>


								<div className="form-row mt-4 mb-4">
									<Field name="autoAssignStudents" type="checkbox">
										{({ input }) => {
											return (
												<Stack direction="row" sx={{
													justifyContent: "flex-start",
													alignItems: "center",
												}} spacing={2}>

													<Checkbox
														{...input}
														onChange={(event) => {
															input.onChange(event);
														}}
														value={input.checked}
														disabled={!enableAutoAssign}
														checked={input.checked}
														label={this.props.translate(
															'The plan should also be assigned to students who join the selected groups.'
														)}
													/>
													<div className="helper-text">
														<Floating trigger={(<HelpOutlineRoundedIcon sx={{ fontSize: '1.3rem' }} />)}>
															{this.props.translate('For new students to be automatically assigned to the plan, entire groups must be selected, meaning all students in the selected groups.')}
														</Floating>
													</div>
												</Stack>
											)
										}
										}
									</Field>
								</div>

								{this.props.editView && initialValues.status != "PLANNING_DRAFT" ?
									<div className="form-row">
										<Select
											name="type"
											label={this.props.translate("Status")}
											dataType="plan"
											options={statuses}
											placeholder={false}
											onChange={this.onStatusSelect}
											selectedValue={initialValues.status}
										/>
									</div>
									: null}


								<div className="form-row">
									<DatePickerFromTo
										type="plan"
										onChange={this.onDateChange}
										values={dates}
										cloneView={this.props.cloneView}
									/>
								</div>

								<div className="form-row input">
									<label>{this.props.translate("title")}*</label>

									<Field
										component={this.renderInput}
										type="text"
										name="title"
										placeholder={this.props.translate("title")}
										validate={this.required}
									/>
								</div>

								<div className="form-divider" />

								<Expandable title={this.props.translate('Assessments')}>
									<Field
										component={BlockAssessments}
										name="assessmentBlocks"
										assessmentFeedbackParts={this.props.cloneView ? null : this.props.assessments.reduce((prev, current, index) => {
											return [
												...prev,
												...this.props.assessments[index]?.assessmentFeedbacks?.map((feedback) =>
													feedback.assessmentBlockPartId
												)
											]
										}, [])}
										disableRows={this.props.cloneView ? null : this.props.assessments.reduce((prev, current, index) => {
											return [
												...prev,
												...this.props.assessments[index]?.assessmentDetails?.map((detail) =>
													detail.assessmentBlockPartRowId
												)
											]
										}, [])}
									/>
								</Expandable>

								<Expandable title={this.props.translate('purpose')}>
									<div className="form-row" style={{ padding: 0, marginBottom: "1em" }}>
										<div className="conditional-groups-container">
											<div className="conditional-groups-container">
												<Field
													name="viewablePurpose"
													component={Checkbox}
													label={this.props.translate("Show purpose to students and guardians")}
													value={true}
												/>
											</div>
										</div >
									</div>

									<Field
										component={BlockPurpose}
										name="purpose"
										assessmentBlocks={blocks}
									/>
								</Expandable >

								<Expandable title={this.props.translate("central-content")}>
									<div className="form-row" style={{ padding: 0, marginBottom: "1em" }}>
										<div className="conditional-groups-container">
											<Field
												name="viewableCoreContent"
												component={Checkbox}
												label={this.props.translate("Show-subject-lesson-content-to-students-and-guardians")}
												value={true}
											/>
										</div>

										<div style={{ clear: "both" }} />
									</div>

									<Field
										component={BlockCoreContentField}
										name="associatedCoreContent"
										referenceId={initialValues?.id}
									/>
								</Expandable>

								{this.props.services.additionalAdjustments ?
									<Expandable title={this.props.translate("additional-adjustments")}>
										<TeacherAdjustments
											disableCommenting={true}
											groupId={SectionId != 0 ? SectionId : null}
											subjects={subjects}
										/>
									</Expandable>
									: null}

								<Field
									component={BlockEditor}
									name="blocks"
								/>


								<div className="form-divider" />

								<div className="form-row spacing submit">
									<Button
										onClick={(e) => {
											e.preventDefault();
											form.mutators.setStatusOpen();
											form.submit();
										}}
										disabled={submitting || !valid || SectionId == null || SectionId == "0"}
									>
										{this.props.editView ?
											this.props.translate("save")
											: this.props.translate("publish")}
									</Button>

									{this.props.editView && initialValues.status != "PLANNING_DRAFT" ? null :
										<Button
											onClick={(e) => {
												e.preventDefault();
												form.mutators.setStatusDraft();
												form.submit();
											}}
											type="secondary"
											disabled={submitting || !valid || SectionId == null || SectionId == "0"}
										>
											{this.props.translate("save-draft")}
										</Button>
									}

									<div className="align-right">
										<Button type="secondary" onClick={this.handleCancel}>
											{this.props.translate("Cancel")}
										</Button>
									</div>
								</div>
							</form>
						)
					}}
				/>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let initialValues = null;
	if (state.planning.active_plan != null) {
		initialValues = state.planning.active_plan;
	}

	if (state.Blocks.reference != null) {
		initialValues.blocks = JSON.parse(JSON.stringify(state.Blocks.reference));

		if (initialValues.blocks != null && initialValues.blocks.length > 0) {
			/**
			 * Track planning with existing block becuase we have support tickets where the user says the content is deleted.
			 */
			forEach(initialValues.blocks, (block) => {
				appInsights.trackTrace({
					message: "EDU024 | Planning with exiting block"
				}, {
					properties: {
						category: "planningForm-" + block.referenceId,
						blockId: block.id,
						valueLength: block.value != null ? block.value.length : 0,
					}
				});
			});
		}

		initialValues.assessmentBlocks = initialValues.blocks.filter(block => block.type == "Haldor.Blocks.AssessmentBlock");

		if (ownProps.cloneView == true) {
			initialValues.blocks = initialValues.blocks.filter(block => {
				return block.type != 'Haldor.Blocks.Assignment';
			});
		}
	}

	return {
		initialValues,
		translate: translate(state.Languages.translations),
		section: state.sections.activeSection,
		status: state.status.status,
		groups: state.sections.educationGroups,
		assessments: state.assessments.assessments,
		services: state.Services.availableServices,
		createdPlan: state.planning.createdPlanId,
	};
}

export default withRouter(connect(mapStateToProps, {
	getSection,
	addError,
	getPlanDetails,
	updatePlan,
	createPlan,
	saveBlocksOnReference,
	updateBlocksOnReference,
	deleteBlock,
	createAssessmentBlock,
	updateAssessmentBlock,
	toggleSelectedSection,
	clearSelectedSections,
})(SinglePlanForm));