import React, { useState } from "react";
import {
	Box,
	IconButton,
	Modal,
} from "@mui/material";

import { translate as translateConstructor } from '@haldor/ui';

import FileBrowser from "./FileBrowser";

import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	height: "80vh",
	width: "80vw",
	borderRadius: "5px",
	overflow: "hidden",
	pt: 3,
	pb: 3,
	px: 4,
};
const OneDriveFilePicker = ({ onUpdate, onClose }) => {
	const [openModal, setOpenModal] = useState(true);

	const translations = useSelector(state => state.Languages.translations);
	const translate = React.useMemo(() => translateConstructor(translations), [translations]);

	const processSelectedFiles = (files) => {
		onUpdate(files);
		setOpenModal(false);
	}

	const onCloseModal = () => {
		setOpenModal(false);

		if (onClose)
			onClose();
	}

	return (
		<div>
			<Modal
				sx={{ zIndex: 99999999999999, marginBottom: '0rem' }}
				open={openModal}
				onClose={() => { setOpenModal(false); onClose(); }}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={modalStyle}>
					<Box sx={{ height: '100%' }}>
						<Box sx={{ height: '10%', display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
							<h2>{translate('Selected files')}</h2>

							{/* Reload Icon */}
							<IconButton onClick={onCloseModal} sx={{ ml: 2, flexShrink: 0 }}>
								<CloseIcon />
							</IconButton>
						</Box>


						<Box sx={{ height: '90%' }}>
							<FileBrowser source={'me'} onUpdate={processSelectedFiles} onClose={onCloseModal}></FileBrowser>
						</Box>
					</Box>
				</Box>
			</Modal>
		</div >
	);
};

export default OneDriveFilePicker;
