import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import { addError } from 'actions';
import { uploadUserOneDrive } from 'actions/assignments';

import OneDrivePicker from 'containers/OneDrivePicker/';
import DropzoneComponent from 'react-dropzone-component';

import { getToken, loginRequest, getUserId, http } from 'lib/msal';

import Modal from 'containers/Modals/Modal';

import { Button, ButtonGroup, Icon } from '@haldor/ui';
import { Image } from 'UI/Icons';
import getEnvironment from 'lib/env';
import './_uploadDocument.scss';

class UploadDocument extends Component {

	static defaultProps = {
		IsButtonEnable: true,
	};

	constructor(props) {
		super(props);

		const schoolId = localStorage.getItem(`hal.school${getUserId()}`);

		this.state = {
			isModelOpen: false,
			oneDriveFiles: [],
			attachedFiles: [],
			uploadedFiles: [],
			selectedTab: 'OneDrive',
			isUploading: false,
			submitted: false,
			uploadProgress: null,
		};

		this.dropzone = null;

		/* Configure dropzone component */
		this.componentConfig = {
			iconFiletypes: ['.jpg', '.png', '.gif'],
			showFiletypeIcon: false,
			postUrl: http.defaults.baseURL + 'assignments/' + props.assignmentId + '/uploadDocuments?_=' + new Date().getTime() + '&SchoolId=' + this.props.schoolId,
			dropzoneSelector: '.dropzone-trigger',
		};

		this.djsConfig = {
			autoProcessQueue: false,
			parallelUploads: 1,
			timeout: 99900000,
			headers: {
				'SchoolId': schoolId,
			},
		};

		loginRequest.scopes = getEnvironment().scopes;
		getToken(loginRequest).then((response) => {
			this.djsConfig.headers['Authorization'] = 'Bearer ' + response.accessToken;
		});

		this.dropzoneEvents = {
			init: this.dropzoneInitEvent,
			addedfile: this.onAddFileEvent,
			success: this.uploadedFileEvent,
			error: this.onUploadError,
		};
	}

	initState = () => {
		this.setState({
			isModelOpen: false,
			oneDriveFiles: [],
			attachedFiles: [],
			uploadedFiles: [],
			selectedTab: 'OneDrive',
			isUploading: false,
			submitted: false,
			uploadProgress: null,
		});
	}

	modelPopUpHelper = (popup) => {
		this.setState({ isModelOpen: popup });
	}

	onAddFileEvent = (file) => {
		let { attachedFiles } = this.state;

		if (file.size > 101000024) {
			this.dropzone.removeFile(file);

			// File over 100MB
			swal.fire({
				title: file.name + " (" + Math.round(file.size / 1024 / 1024) + 'MB)',
				html: `
				<div id="file-too-large-prompt">
					<div>
						<img src="/dist/svg/infoRed.svg" alt="warning"/>
					</div>
					<div>
					${this.props.translate('The file was too large to upload. Open your workspace and upload the file from there instead.')}
					</div>
				</div>`,
				showCancelButton: false,
				confirmButtonText: this.props.translate('okey'),
			});

			return false;
		}

		attachedFiles.push(file);

		setTimeout(() => {
			this.setState({ attachedFiles });
		}, 40);
	}

	uploadFiles = () => {
		this.dropzone.processQueue();
	}

	uploadedFileEvent = (djsFile, response) => {
		this.uploadFiles();
	}

	dropzoneInitEvent = (dropzone) => {
		this.dropzone = dropzone;
	}

	triggerDropzoneEvent = () => {
		if (this.dropzone != null) {
			this.dropzone.hiddenFileInput.click();
		}
	}

	onUploadError = () => {
		this.props.addError(this.props.translate('Forbidden'), 'error');
	}

	onOneDrivePickerChangeEvent = (files) => {
		let parsedFiles = [];

		files.map((file) => {
			let add = {
				id: file.id,
				key: file.name,
				value: file.url,
				markedForDeletion: false,
				fileType: 0,
			};

			parsedFiles.push(add);
		});

		this.setState({ oneDriveFiles: parsedFiles });
	}

	newDocumentButtonClickEvent = (event) => {
		const { isModelOpen } = this.state;

		loginRequest.scopes = getEnvironment().scopes;
		getToken(loginRequest).then((response) => {
			this.djsConfig.headers['Authorization'] = 'Bearer ' + response.accessToken;
		});

		this.modelPopUpHelper(!isModelOpen);
	}

	modelCloseClickEvent = () => {
		let { submitted, oneDriveFiles } = this.state;

		const closeConfirmCondition = !submitted &&
			((this.dropzone &&
				this.dropzone.getQueuedFiles().length > 0) ||
				oneDriveFiles.length > 0);

		if (closeConfirmCondition) {
			this.modelPopUpHelper(false);
			this.initState();
			this.props.refreshDocuments();
		} else if (submitted) {
			this.modelPopUpHelper(false);
			this.initState();
			this.props.refreshDocuments();
		} else {
			this.modelPopUpHelper(false);
			this.initState();
		}
	}

	onRemoveFileEvent = (file) => {
		let { attachedFiles } = this.state;

		const fileIndex = attachedFiles.findIndex(function (f) {
			return f.upload.filename === file.upload.filename;
		});

		attachedFiles.splice(fileIndex, 1);

		this.setState({ attachedFiles }, () => {
			if (this.dropzone) {
				this.dropzone.removeFile(file);
			}
		});
	}

	UploadButtonClickEvent = () => {
		const { oneDriveFiles } = this.state;

		this.setState({ isUploading: true });

		const outerThis = this;
		let uploadPromises = [];

		if (outerThis.dropzone && outerThis.dropzone.getQueuedFiles().length > 0) {
			uploadPromises.push(new Promise(resolve => {
				outerThis.uploadFiles();

				outerThis.dropzone.on('totaluploadprogress', (progress) => {
					outerThis.setState({ uploadProgress: progress });
				})

				outerThis.dropzone.on("queuecomplete", (files) => {
					resolve(files);
				});
			}));
		}

		if (oneDriveFiles.length > 0) {
			uploadPromises.push(this.props.uploadUserOneDrive(this.props.assignmentId, oneDriveFiles));
		}

		Promise.all(uploadPromises).then(files => {
			this.setState({
				isUploading: false,
				submitted: true
			});
			this.modelPopUpHelper(false);
			this.initState();
			if (this.props.uploadError.error == true) {
				this.props.onUploadError(this.props.uploadError);
			}
			else {
				setTimeout(() => this.props.refreshDocuments(), 1000);
			}
		});
	}

	tabSwitchClickEvent = (tab) => {
		this.setState({ selectedTab: tab });
	}

	renderComputer = () => {
		const {
			selectedTab
		} = this.state;

		return (
			<div style={{ margin: '1.55rem 0rem' }} className={(selectedTab === 'Computer' ? '' : 'hide-content') + " upload-document clearfix gap-bottom"}>
				<strong>{this.props.translate('computer')}</strong>

				<div className="dropzone-trigger icon-container" style={{ height: 'auto', width: '100%' }}>
					<div onClick={this.triggerDropzoneEvent} className="icon">
						<Image />
					</div>

					<div className="text" onClick={this.triggerDropzoneEvent}>
						{this.props.translate('drop-files-or-click-here')}
					</div>

					<DropzoneComponent
						djsConfig={this.djsConfig}
						eventHandlers={this.dropzoneEvents}
						config={this.componentConfig}
					/>
				</div>

				<div style={{ clear: 'both' }}></div>
			</div>
		)
	}

	renderOneDrive = () => {
		const { selectedTab } = this.state;

		return (
			<div className={(selectedTab === 'OneDrive' ? '' : 'hide-content') + " clearfix gap-bottom"}>
				<OneDrivePicker className="onedrive-picker-model" openPicker={true} onChange={this.onOneDrivePickerChangeEvent} />
			</div>
		)
	}

	renderUploadTypes = () => {
		const {
			selectedTab
		} = this.state;

		return (
			<div style={{ marginBottom: '1rem' }}>
				<ButtonGroup>
					<Button
						style={{ textDecoration: selectedTab === 'OneDrive' ? 'underline' : 'none' }}
						type="secondary"
						onClick={e => { this.tabSwitchClickEvent('OneDrive') }}>
						{this.props.translate('one-drive')}
					</Button>

					<Button
						style={{ textDecoration: selectedTab === 'Computer' ? 'underline' : 'none' }}
						type="secondary"
						onClick={e => { this.tabSwitchClickEvent('Computer') }}>
						{this.props.translate('computer')}
					</Button>
				</ButtonGroup>
			</div>
		)
	}

	renderDocumentList = () => {
		const { attachedFiles, oneDriveFiles } = this.state;

		return (
			<div className="clearfix upload-wrapper">
				{oneDriveFiles.length > 0 || attachedFiles.length > 0 ?
					<div className="upload-container">
						<div style={{ marginBottom: '1rem' }}>
							{oneDriveFiles.length > 0
								? <strong>{this.props.translate('one-drive')}</strong>
								: null}

							<div style={{ marginLeft: '-0.5rem', marginRight: '-0.5em', marginBottom: '1rem', display: 'flex' }}>
								{oneDriveFiles.map((file, i) => {
									const fileKey = `onedrive-include-file-${i}`;

									if (file.fileType == null) {
										return null;
									}

									return (
										<div
											key={fileKey}
											className={`dropzone-file-preview`}
										>
											<div className="file-name">
												<Icon name="File" />

												<div style={{ flex: 1 }}>
													{file.key}
												</div>
											</div>
										</div>
									);
								})}
							</div>

							{attachedFiles.length > 0
								? <strong>{this.props.translate('computer')}</strong>
								: null}

							<div style={{ marginLeft: '-0.5rem', marginRight: '-0.5em', display: 'flex' }}>
								{attachedFiles.map((file, i) => {
									const fileKey = `attached-file-${i}`;

									return (
										<div
											key={fileKey}
											className={`dropzone-file-preview ${!file.accepted ? " not-accepted" : ""} ${file.upload.progress > 0 ? ' upload-started' : ' not-started'}`}
										>
											<div className="file-name">
												<div style={{ flex: 1, maxWidth: 'calc(100% - 1rem)', wordWrap: 'break-word' }}>
													{file.name + ' '}

													({file.size > 100000 ?
														Math.round(file.size / 1024 / 1024) + 'MB'
														:
														Math.round(file.size / 1024) + 'Kb'
													})
												</div>

												<div onClick={e => { this.onRemoveFileEvent(file) }} className="file-delete-btn">
													<Icon name="Close" />
												</div>
											</div>

											{file.accepted && file.size > 7500000 ?
												<div className="file-warning">
													<Icon name="Info_Blue" />

													<div style={{ flex: 1 }}>
														{this.props.translate('size-warning')}
													</div>
												</div>
												: null}

											{!file.accepted ?
												<div className="file-warning">
													<Icon name="Alert" />

													<div style={{ flex: 1 }}>
														{this.props.translate('file-too-big')}
													</div>
												</div>
												: null}
										</div>
									);
								})}
							</div>
						</div>
					</div>
					: null}
			</div>
		)
	}

	renderUploadDocumentButton = () => {
		return (
			<div className="clearfix">
				<Button type="primary" className="c--button no-margin" onClick={e => { this.UploadButtonClickEvent() }}>
					{this.props.translate('upload')}
				</Button>
			</div>
		)
	}

	renderModel = () => {
		const {
			isModelOpen,
			isUploading
		} = this.state;

		const { translate } = this.props;

		return (
			<Modal isOpen={isModelOpen} onClose={this.modelCloseClickEvent} title={translate('Add work')}>
				{isUploading ?
					<div className="is_sending">
						<p>
							<span className="loading-spinner" />
							<br />

							{this.state.uploadProgress != null ?
								<div className="upload-progress">
									<div style={{ width: Math.round(this.state.uploadProgress) + '%' }} className="upload-progress-bar" />
								</div>
								: null}
						</p>
					</div>
					: null}

				<div className="document-attachment">
					{this.renderUploadTypes()}

					{this.renderOneDrive()}

					{this.renderComputer()}

					{this.renderDocumentList()}

					{this.renderUploadDocumentButton()}
				</div>
			</Modal>
		);
	}

	renderUploadButton = () => {
		const { IsButtonEnable, IsTeacher } = this.props;

		return (
			<div>
				{IsTeacher ? null
					: <Button disabled={!IsButtonEnable} type="secondary" onClick={this.newDocumentButtonClickEvent}>
						{this.props.title ? this.props.title : this.props.translate('upload')}
					</Button>
				}
			</div>
		);
	}

	render() {
		return (
			<div style={{ float: 'left' }}>
				{this.renderUploadButton()}
				{this.renderModel()}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		uploadError: state.assignments.uploadError,
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps, {
	uploadUserOneDrive,
	addError,
})(UploadDocument);
