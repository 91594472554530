import api from 'lib/api';

export const GET_SECTIONS = 'GET_SECTIONS';
export const GET_SECTION = 'GET_SECTION';
export const GET_FILTERED_SECTION = 'GET_FILTERED_SECTION';
export const GET_SECTION_DETAILS = 'GET_SECTION_DETAILS';
export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
export const GET_EDUCATION_GROUPS = 'GET_EDUCATION_GROUPS';
export const GET_EDUCATION_GROUPS_LEGACY = 'GET_EDUCATION_GROUPS_LEGACY';
export const GET_ARCHIVED_EDUCATION_GROUPS = 'GET_ARCHIVED_EDUCATION_GROUPS';
export const GET_MENTOR_GROUPS = 'GET_MENTOR_GROUPS';
export const GROUPS_LOADING = 'GROUPS_FETCH_IS_LOADING';
export const CLEAR_SECTIONS_FROM_STORE = 'CLEAR_SECTIONS_FROM_STORE';
export const GET_SCHOOL_BY_GROUP_GRAPH_ID = 'GET_SCHOOL_BY_GROUP_GRAPH_ID';
export const GET_SECTION_BY_GRAPH_ID = 'GET_SECTION_BY_GRAPH_ID';
export const SET_ARCHIVING_SECTION = 'SET_ARCHIVING_SECTION';
export const GET_SECTION_ATTENDANCE_STATS = 'SECTIONS_GET_SECTION_ATTENDANCE_STATISTICS';
export const CLEAR_SECTION_ATTENDANCE_STATS = 'CLEAR_SECTION_ATTENDANCE_STATS';
export const GET_GROUP_ABSENCE = 'SECTIONS_GET_GROUP_ABSENCE';
export const GET_SECTION_GUARDIANS = 'SECTIONS_GET_SECTION_GUARDIANS';
export const GET_STUDENT_ATTENDANCE_STATS = 'GET_STUDENT_ATTENDANCE_STATS';
export const CLEAR_STUDENT_ATTENDANCE_STATS = 'CLEAR_STUDENT_ATTENDANCE_STATS';
export const INFORM_ADD_TAB = 'INFORM_ADD_TAB';
export const GET_SECTION_TEAMS_CHANNEL = 'GET_SECTION_TEAMS_CHANNEL';
export const GET_SECTION_ASESSMENT_STATISTICS = 'SECTIONS_GET_SECTION_ASSESSMENT_STATISTICS';
export const GET_ARCHIEVED_MENTOR_GROUPS = 'GET_ARCHIEVED_MENTOR_GROUPS';
export const TOGGLE_SELECTED_SECTION = 'SECTIONS_TOGGLE_SELECTED_SECTION';
export const CLEAR_SELECTED_SECTIONS = 'SECTIONS_CLEAR_SELECTED_SECTIONS';
export const GET_GROUP_STUDENT_ADJUSTMENTS = 'SECTIONS_GET_GROUP_STUDENT_ADDITIONAL_ADJUSTMENTS';
export const GET_GROUP_STUDENT_ADJUSTMENTS_MULTIPLE = 'SECTIONS_GET_GROUP_STUDENT_ADDITIONAL_ADJUSTMENTS_MULTIPLE';

export const getSections = (callback) => ({
	type: GET_SECTIONS,
	payload: new Promise((resolve) => {
		api.get('sections').then((response) => {
			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export const getSchoolByGroupGraphId = (graphId) => {
	return {
		type: GET_SCHOOL_BY_GROUP_GRAPH_ID,
		payload: new Promise((resolve) => {
			api.get(`sections/${graphId}/school`).then((response) => {
				if (response.status == 204) {
					resolve(null);
					return;
				}

				resolve(response.data);
			});
		}),
	};
};

export const getSectionByGraphId = (graphId, additionalParams = null) => {
	return {
		type: GET_SECTION_BY_GRAPH_ID,
		payload: new Promise((resolve) => {

			let params = '';
			if (additionalParams != null) {
				params = '?expand=' + additionalParams;
			}

			api.get(`sections/${graphId}${params}`).then((response) => {
				if (response.status == 204) {
					resolve(null);
					return;
				}

				resolve(response.data);
			});
		}),
	};
};

export const getMentorGroups = (callback) => ({
	type: GET_MENTOR_GROUPS,
	payload: new Promise((resolve) => {
		api.get('sections?type=MENTOR_GROUP').then((response) => {
			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export const getArchievedMentorGroups = (callback) => ({
	type: GET_ARCHIEVED_MENTOR_GROUPS,
	payload: new Promise((resolve) => {
		api.get('sections?type=MENTOR_GROUP&archived=true').then((response) => {
			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export const getLegacyEducationGroups = () => ({
	type: GET_EDUCATION_GROUPS_LEGACY,
	payload: new Promise((resolve) => {
		api.get('sections/legacy?type=EDUCATION_GROUP').then((response) => {
			resolve(response.data);
		});
	}),
});


export const getEducationGroups = () => ({
	type: GET_EDUCATION_GROUPS,
	payload: new Promise((resolve) => {
		api.get('sections?type=EDUCATION_GROUP').then((response) => {
			resolve(response.data);
		});
	}),
});

export const getArchivedEducationGroups = (callback) => ({
	type: GET_ARCHIVED_EDUCATION_GROUPS,
	payload: new Promise((resolve) => {
		api.get('sections?type=EDUCATION_GROUP&archived=true').then((response) => {
			resolve(response.data);
			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export const getSection = (id, owners = true, additionalParams = '') => ({
	type: GET_SECTION,
	payload: new Promise((resolve, reject) => {
		let params = null;
		if (owners) {
			params = 'expand=owners;members;courses;historic_students;' + additionalParams;
		} else {
			params = 'expand=members;courses;historic_students'
		}

		api.get(`sections/${id}?${params}`)
			.then((response) => {
				var data = response.data;

				if (data && data.courses != null && data.courses.length > 0) {
					data.courses.forEach((element) => {
						var sortedList = [];
						if (element.details != null && element.details.length > 0) {
							var activeDetailIndex = element.details.findIndex(
								(element) => element.endDate == null
							);

							if (activeDetailIndex > -1) {
								var activeDetail = element.details[activeDetailIndex];
								element.details.splice(activeDetailIndex, 1);
								element.details.unshift(activeDetail);
							}
						}
					});
				}

				resolve(response.data);

				if (callback != null) {
					callback(response.data);
				}
			})
			.catch((e) => {
				reject(e);
			});
	}),
});

export const getFilteredSection = (id, startDate, endDate) => ({
	type: GET_FILTERED_SECTION,
	payload: new Promise((resolve) => {
		api.get(`sections/${id}/start/${startDate}/end/${endDate}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const appendSectionDetails = (id) => ({
	type: GET_SECTION_DETAILS,
	payload: new Promise((resolve) => {
		api.get(`sections/${id}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const setActiveSection = (id) => ({
	type: SET_ACTIVE_SECTION,
	payload: id,
});

export const clearSectionsFromStore = () => ({
	type: CLEAR_SECTIONS_FROM_STORE,
	payload: [],
});

export const updateArchived = (group) => ({
	type: SET_ARCHIVING_SECTION,
	payload: new Promise((resolve) => {
		let values = { ...group };
		values.archived = !group.archived;
		delete values.events;

		api.put(`sections`, values).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getSectionAttendance = (sectionId, start, end) => ({
	type: GET_SECTION_ATTENDANCE_STATS,
	payload: new Promise((resolve) => {
		api.get(`attendance/Statistics?sectionId=${sectionId}&startDate=${start}&endDate=${end}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getStudentAttendance = (studentId, start, end, sectionId) => ({
	type: GET_STUDENT_ATTENDANCE_STATS,
	payload: new Promise((resolve) => {
		if (sectionId != 0 && sectionId != null) {
			api.get(`attendance/Statistics?studentId=${studentId}&startDate=${start}&endDate=${end}&sectionId=${sectionId}`)
				.then((response) => {
					resolve(response.data);
				});

			return;
		}

		api.get(`attendance/Statistics?studentId=${studentId}&startDate=${start}&endDate=${end}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const clearStudentAttendance = () => ({
	type: CLEAR_STUDENT_ATTENDANCE_STATS,
	payload: true,
});

export const clearSectionAttendanceStats = () => ({
	type: CLEAR_SECTION_ATTENDANCE_STATS,
	payload: true,
});

export const getGroupAbsenceForToday = (id, start, end) => ({
	type: GET_GROUP_ABSENCE,
	payload: new Promise((resolve) => {
		api.get(`sections/${id}/absences?startDate=${start}&endDate=${end}`).then((response) => {
			let values = {
				groupId: id,
				value: response.data,
			};

			resolve(values);
		});
	}),
});

export const getSectionAssessmentStatistics = (sectionId, start, end, subjects = []) => ({
	type: GET_SECTION_ASESSMENT_STATISTICS,
	payload: new Promise((resolve) => {
		let url = `assessments/sections/${sectionId}/statistics?startDate=${start}&endDate=${end}`

		api.post(url, subjects).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getSectionGuardians = (id) => ({
	type: GET_SECTION_GUARDIANS,
	payload: new Promise((resolve) => {
		api.get(`sections/${id}/guardians`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getTeamChannels = (groupId) => ({
	type: GET_SECTION_TEAMS_CHANNEL,
	payload: new Promise((resolve) => {
		api.get(`sections/${groupId}/channels`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const informAboutTabData = (sectionId, tabId) => ({
	type: INFORM_ADD_TAB,
	payload: new Promise((resolve) => {
		api.put(`sections/${sectionId}/tabs/${tabId}`, null).then((response) => {
			resolve(response.data);
		});
	}),
});

export const toggleSelectedSection = (section, groupAssignment = false) => ({
	type: TOGGLE_SELECTED_SECTION,
	payload: { section, groupAssignment },
});

export const clearSelectedSections = () => ({
	type: CLEAR_SELECTED_SECTIONS,
	payload: true,
});

export const getStudentAdditionalAdjustmentsForGroup = (groupId) => ({
	type: GET_GROUP_STUDENT_ADJUSTMENTS,
	payload: new Promise((resolve) => {
		api.get(`sections/${groupId}/studentAdditionalAdjustments`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getStudentAdditionalAdjustmentsForMultipleGroups = (group) => ({
	type: GET_GROUP_STUDENT_ADJUSTMENTS_MULTIPLE,
	payload: new Promise((resolve) => {
		api.get(`StudentAdditionalAdjustments?sectionId=${group}`).then((response) => {
			let data = null;
			if (response.data != null) {
				data = [];

				response.data.forEach((adjustment) => {
					adjustment.groupId = group;
					data.push(adjustment);
				});
			}

			resolve(data);
		});
	}),
});
