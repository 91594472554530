import api from 'lib/api';
import { get_operations } from 'lib/patch';

export const GET_ASSESSMENTS_COURSES = 'GET_ASSESSMENTS_COURSES';
export const GET_ASSESSMENTS = 'GET_ASSESSMENTS';
export const CREATE_ASSESSMENTS = 'CREATE_ASSESSMENTS';
export const DELETE_ASSESSMENT = 'ASSESSMENTS_DELETE_ASSESSMENTS';
export const DELETE_ASSESSMENTS_DETAIL = 'ASSESSMENTS_DELETE_ASSESSMENTS_DETAIL';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const UPDATE_ASSESSMENT_STATUS = 'UPDATE_ASSESSMENT_STATUS';
export const GET_STUDENT_COURSES_GOALS = 'ASSESSMENTS_GET_STUDENT_COURSES_AND_GOALS';
export const GET_STUDENT_ASSESSMENT_STATISTICS = 'ASSESSMENTS_GET_STUDENT_ASSESSMENT_STATISTICS';

export const CLEAR_MATRIX_ASSESSMENT_OVERVIEW = 'CLEAR_MATRIX_ASSESSMENT_OVERVIEW';

export const CLEAR_ASSESSMENTS = 'CLEAR_ASSESSMENTS';
export const CREATE_ASSESSMENTS_STUDENT = 'CREATE_ASSESSMENTS_STUDENT';


export const getCoursesUsedWithinMySections = (from, to) => ({
	type: GET_ASSESSMENTS_COURSES,
	payload: new Promise((resolve) => {
		api.get(`assessments/courses?fromDate=${from}&toDate=${to}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getPlanAssessments = (id) => ({
	type: GET_ASSESSMENTS,
	payload: new Promise((resolve) => {
		api.get(`assessments/plans/${id}`).then((response) => {
			if (response.status > 300) {
				resolve([]);
				return false;
			}

			resolve(response.data);
		});
	}),
});

export const createAssessments = (values) => ({
	type: CREATE_ASSESSMENTS,
	payload: new Promise((resolve) => {
		api.post('assessments', values).then(async (response) => {
			resolve(response.data);
		});
	}),
});

export const createStudentAssessment = (values) => ({
	type: CREATE_ASSESSMENTS_STUDENT,
	payload: new Promise((resolve) => {
		api.post('assessments/student', values).then(async (response) => {
			resolve(response.data);
		})
	}),
});

export const updateAssessment = (values, assessment) => ({
	type: UPDATE_ASSESSMENT,
	payload: new Promise((resolve) => {
		const operations = get_operations(assessment, values);

		if (operations.length > 0) {
			api.patch(`assessments/${values.id}`, assessment, operations).then((response) => {
				resolve(response.data);
			});
		} else {
			resolve(values);
		}
	}),
});
// Used in assignments and planning
export const updateAssessmentStatus = (assessmentId, status) => ({
	type: UPDATE_ASSESSMENT_STATUS,
	payload: new Promise((resolve) => {
		api.put(`assessments/status?assessmentId=${assessmentId}&status=${status}`, null).then((response) => {
			resolve(response.data);
		});
	}),
});
// Used in multiple assessment forms
export const deleteAssessment = (assessmentId) => ({
	type: DELETE_ASSESSMENT,
	payload: new Promise((resolve) => {
		api.delete("assessments/" + assessmentId).then((response) => {
			resolve(response.data);
		});
	}),
});
// Used in assessmentWidget
export const deleteAssessmentDetail = (assessmentId, detailId) => ({
	type: DELETE_ASSESSMENTS_DETAIL,
	payload: new Promise((resolve) => {
		api.delete(`assessments/${assessmentId}/details/${detailId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getAssignmentAssessment = (id) => ({
	type: GET_ASSESSMENTS,
	payload: new Promise((resolve) => {
		api.get(`assessments/assignments/${id}`).then((response) => {
			if (response.status != 200) {
				resolve(null);
			} else {
				resolve(response.data);
			}
		});
	}),
});
// Used in assignment and planning
export const clearAssessments = () => ({
	type: CLEAR_ASSESSMENTS,
	payload: []
});

export const getUserSummary = (studentId, startDate, endDate, params = {}) => ({
	type: GET_STUDENT_COURSES_GOALS,
	payload: new Promise((resolve) => {
		let url = `assessments/users/${studentId}/summary/data?startDate=${startDate}&endDate=${endDate}`;

		if (params.courseId != null) {
			url += `&courseId=${params.courseId}`;
		}

		if (params.sectionId != null) {
			url += `&sectionId=${params.sectionId}`;
		}

		if (params.type != null) {
			url += `&type=${params.type}`;
		}

		api.get(url).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getUserStatisticsDetailed = (studentId, startDate, endDate, params = {}) => ({
	type: GET_STUDENT_ASSESSMENT_STATISTICS,
	payload: new Promise((resolve) => {
		let url = `assessments/users/${studentId}/statistics/detailed?startDate=${startDate}&endDate=${endDate}`;

		if (params.sectionId != null) {
			url += `&sectionId=${params.sectionId}`;
		}

		if (params.courseId != null) {
			url += `&courseId=${params.courseId}`;
		}

		if (params.type != null) {
			url += `&type=${params.type}`;
		}

		api.get(url).then((response) => {
			resolve(response.data);
		});
	}),
});