/**
 * @typedef {import('@azure/msal-browser').RedirectRequest} RedirectRequest
 */

import * as microsoftTeams from "@microsoft/teams-js";
import * as msal from '@azure/msal-browser';
import axios from 'axios';
import getEnvironment from 'lib/env';
import moment from 'moment';
import store from '../index';
import DateTime from '_class/DateTime';
import { appInsights } from 'lib/appInsights';

/**
 * @typedef {Object} MSALConfig MSAL Config model
 */
const msalConfig = {
	auth: {
		clientId: getEnvironment().clientId,
		authority: 'https://login.microsoftonline.com/common/',
		postLogoutRedirectUri: window.location.origin + '/',
		redirectUri: window.location.origin + '/',
		navigateToLoginRequestUrl: false,
	},
	cache: {
		// TODO: Decide if we should use sessionStorage (default) or localStorage here
		// localStorage seems to work better for our use case
		cacheLocation: "localStorage",
		storeAuthStateInCookie: true,
	},
};

console.log('_env', getEnvironment());

/**
 * @type {RedirectRequest} loginRequest
 */
const loginRequest = {
	scopes: [
		"openid",
		"profile",
		"offline_access",
	],
};

/**
 * @type {Array} graphScopes
 */
const graphScopes = [
	"Files.ReadWrite.All",
];

const instance = new msal.PublicClientApplication(msalConfig);

const _addToast = (message, type = 'warning') => {
	const error = {
		translateMsg: message,
		type: type,
		target: 'API',
		time: new DateTime(),
	};

	store.dispatch({
		type: 'ADD_ERROR',
		payload: error,
	});
}

const requirePopupConsent = (clientId, redirectUri, scopes, knownClientApplications = []) => new Promise(async (resolve, reject) => {
	const msalCustomConfig = {
		auth: {
			clientId: clientId,
			authority: 'https://login.microsoftonline.com/common/',
			postLogoutRedirectUri: window.location.origin + '/',
			redirectUri: window.location.origin + redirectUri,
			navigateToLoginRequestUrl: false,
		},
		cache: {
			// TODO: Decide if we should use sessionStorage (default) or localStorage here
			// localStorage seems to work better for our use case
			cacheLocation: "localStorage",
			storeAuthStateInCookie: false,
		},
	};

	const msalClient = new msal.PublicClientApplication(msalCustomConfig);

	var customRequest = {
		scopes: scopes
	}

	customRequest.account = getUser();

	msalClient.acquireTokenSilent(customRequest).then(response => {
		resolve(response);
	}).catch(error => {
		msalClient.acquireTokenPopup(customRequest).then((token) => {
			resolve(token);
		}).catch(error => {
			reject(error);
		});
	});
});

const getToken = (request) => new Promise(async (resolve, reject) => {
	request.account = getUser();

	if (microsoftTeams.app.isInitialized()) {
		microsoftTeams.authentication.getAuthToken().then((token) => {
			resolve({ accessToken: token });
		}).catch((error) => {
			appInsights.trackTrace({ message: "EDU005 | Teams SDK - getAuthToken fails" }, error);
			console.log(error);
			reject('Failed to retrieve token from Teams SDK');
		});
	} else {
		if (instance.getAllAccounts().length == 0) {
			reject('User not logged in');
			return true;
		}

		const token = await new Promise(async (resolveToken, rejectToken) => {
			instance.acquireTokenSilent(request).then(token => {
				resolveToken(token);
			})
				.catch(async (error) => {
					appInsights.trackTrace({ message: "EDU005 | MSAL - AcquireTokenSilent fails (step 503)" }, error);
					resolveToken(null);
				});
		}).catch(error => {
			console.log("error catch", error);
			reject(error);
		});

		resolve(token);
	}
});

let tokenErrorCallbacks = [];
const onMsalTokenError = (callback, ...args) => {
	if (callback != null) {
		tokenErrorCallbacks.push(callback);
		return true;
	}

	tokenErrorCallbacks.forEach(callback => {
		if (callback != null && typeof callback == 'function') {
			callback(...args);
		}
	})
}

let loginRequiredCallbacks = [];
const onMsalLoginRequired = (callback) => {
	return new Promise((resolve) => {
		if (callback != null) {
			loginRequiredCallbacks.push(callback);
			resolve(true);
			return true;
		}

		let promises = [];

		loginRequiredCallbacks.forEach(callback => {
			if (callback != null) {
				let promise = callback();
				promises.push(promise);

				return true;
			}
		})

		Promise.all(promises).then(() => {
			resolve(1);
		})
	})
}

const getUser = () => {
	if (microsoftTeams.app.isInitialized()) {
		return { username: localStorage.getItem('session.username') };
	} else {
		const accounts = instance.getAllAccounts();
		if (accounts.length > 0) {
			const username = localStorage.getItem('session.username');
			if (username) {
				return accounts.find(account => account.username === username) || accounts[0];
			} else {
				return accounts[0];
			}
		}
	}

	return null;
}

const setUser = (username) => {
	localStorage.setItem('session.username', username);
}

const setOnboardingUser = (username) => {
	var existingUserName = localStorage.getItem('onboarding.username');

	if (username != existingUserName) {
		localStorage.removeItem('onboarding.expireTokenIn');
		localStorage.setItem('onboarding.username', username);
	}
}

const setOnboardingToken = (token) => {
	localStorage.setItem('onboarding.token', token);
}

const setOnboardingExpireTokenIn = (expireIn) => {
	localStorage.setItem('onboarding.expireTokenIn', moment().add(expireIn, 's').format("YYYY-MM-DD HH:mm"))
}

const isTokenValid = () => {
	var date = localStorage.getItem('onboarding.expireTokenIn');

	if (date != null) {
		var now = moment();
		var expireDate = moment(date);

		if (now < expireDate) {
			return true;
		}
	}

	return false;
}

const getSchoolId = () => new Promise(async (resolve, reject) => {
	var currentStore = store.getState();
	var currentActiveSchoolId = currentStore.user.activeSchool;

	// If we have a school in the store, return it
	if (currentActiveSchoolId != null) {
		resolve(currentActiveSchoolId);
		return;
	}

	let properties = {
		user: null
	}

	// if we dont have in store, backup check in localstorage
	if (microsoftTeams.app.isInitialized()) {
		microsoftTeams.app.getContext().then((context) => {
			var schoolId = localStorage.getItem('hal.school' + context.user.id);
			properties.user = context.user.id;


			if (schoolId == null) {
				appInsights.trackTrace({ message: "EDU025a | MSAL - SchoolId could not be found through teams context" }, properties);
			}

			resolve(schoolId);
		});
	} else {
		if (instance.getAllAccounts().length > 0) {
			const account = getUser();

			if (account != null) {
				const id = account.homeAccountId.split('.')[0];
				const schoolId = localStorage.getItem('hal.school' + id);
				properties.user = id;

				if (schoolId == null) {
					appInsights.trackTrace({ message: "EDU025a | MSAL - SchoolId could not be found through account" }, properties);
				}

				resolve(schoolId);
			}
		}
	}
});

const getUserId = () => {
	const user = getUser();

	if (user != null) {
		if (user.homeAccountId?.indexOf('.') > -1) {
			return user.homeAccountId.split('.')[0];
		}

		return user.homeAccountId;
	}

	return null;
}

const getTokenForScope = async () => {
	try {
		const account = getUser();
		const customRequest = {
			scopes: ["Files.ReadWrite.All"],
			account: account,
		};

		try {
			const tokenResponse = await instance.acquireTokenSilent(customRequest);
			return tokenResponse.accessToken;
		} catch (silentError) {
			const tokenResponse = await instance.acquireTokenPopup(customRequest);
			return tokenResponse.accessToken;
		}
	} catch (error) {
		console.error("Token acquisition error:", error);
		return null;
	}
};

const getConsentStatus = async () => {
	const account = getUser();
	instance.acquireTokenSilent({
		scopes: ["Files.ReadWrite.All"],
		account: account
	}).then((tokenResponse) => {
		if (!tokenResponse.scopes.includes("Files.ReadWrite.All")) {
			appInsights.trackTrace({ message: "EDU100 | MSAL - User in tenant " + tokenResponse.tenantId + " has not consented to Files.ReadWrite.All" });
		}
		else {
			appInsights.trackTrace({ message: "EDU101 | MSAL - User in tenant " + tokenResponse.tenantId + " has consented to Files.ReadWrite.All" });
		}
	}).catch((error) => {
		appInsights.trackTrace({ message: "EDU102 | MSAL - User in tenant " + account.tenantId + " errorcode " + error.errorCode });
	});

};

const http = axios.create({
	baseURL: getEnvironment().baseURL,
});

const errors = {
	AuthError: msal.AuthError,
	AuthErrorMessage: msal.AuthErrorMessage,
	BrowserAuthError: msal.BrowserAuthError,
	BrowserAuthErrorMessage: msal.BrowserAuthErrorMessage,
	BrowserConfigurationAuthError: msal.BrowserConfigurationAuthError,
	BrowserConfigurationAuthErrorMessage: msal.BrowserConfigurationAuthErrorMessage,
	InteractionRequiredAuthError: msal.InteractionRequiredAuthError,
};

const clearInteractionProgress = () => {
	if (document.cookie.indexOf('interaction_in_progress') > -1 || document.cookie.indexOf('interaction.status') > -1) {
		document.cookie.split(";").forEach(function (c) {
			document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
		});
	}

	Object.keys(sessionStorage).forEach(key => {
		if (key.indexOf('interaction.status') > -1) {
			sessionStorage.removeItem(key);
		}
	});

	Object.keys(localStorage).forEach(key => {
		if (key.indexOf('interaction.status') > -1) {
			localStorage.removeItem(key);
		}
	});
}

http.interceptors.request.use((config) => {
	return new Promise(async (resolve, reject) => {
		let request = { ...loginRequest };

		if (config.url.indexOf('graph.microsoft.com') > -1) {
			// Add scopes for MS Graph
			request.scopes = [
				...graphScopes,
				...request.scopes
			];
		} else {
			// Add scopes for environment
			request.scopes = [
				...getEnvironment().scopes,
				...request.scopes
			];
		}

		var token = null;
		var onboardingInit = localStorage.getItem('onboarding:init');

		/** If Admin consent is present then we make sure accessToken always have to taken from getToken method **/
		var onboardingValid = localStorage.getItem("onboarding.valid");

		const { pathname } = window.location;

		if ((pathname.indexOf('/teams/he') > -1 && pathname.indexOf('/tab-configure') < 0 && onboardingValid == null) || (pathname.indexOf('/teams/he/tab-configure') > -1 && onboardingInit != null && onboardingValid == null)) {
			let accessToken = localStorage.getItem('onboarding.token');

			if (!accessToken)
				console.log("token does not exit in localstorage", accessToken);

			token = {
				accessToken: accessToken
			}
		} else {
			token = await getToken(request);
		}

		const lcid = localStorage.getItem('language');
		let currentSchool = await getSchoolId();

		if (currentSchool != null) {
			if (config.params == null) {
				config.params = {};
			}

			config.params['SchoolId'] = currentSchool;
		} else {
			appInsights.trackTrace({ message: "EDU025b | MSAL - SchoolId could not be found" });
		}

		if (lcid != null) {
			if (config.params == null) {
				config.params = {};
			}

			config.params['lcid'] = lcid;
		}

		if (token != null) {
			config.headers.common['Authorization'] = "Bearer " + token.accessToken;
		}

		// TODO: Reject promise if something goes wrong with fetching token
		resolve(config);
	})
}, function (error) {
	return Promise.reject(error);
});

http.interceptors.response.use((response) => {
	if (response.status == 204) {
		response.data = null;
	}

	return response;
}, async (error) => {
	if (error == null || error.response == null) {
		return Promise.reject(error);
	}

	if (error.response.status == 401) {
		return onMsalLoginRequired().then((result) => {
			return http.request(error.config)
		})
	}

	if (error.response.status == 403) {
		appInsights.trackTrace({ message: "EDU021 | MSAL lib interceptor got status 403" }, {
			data: error?.response?.data,
			params: error?.config?.params,
			url: error?.config?.url,
			method: error?.config?.method,
			baseURL: error?.config?.baseURL,
			schoolId: error?.config?.params['SchoolId'],
		});

		// Dispatch an error (toast bar)
		_addToast("You don't seem to have permission to perform this action. For support, contact your school administrator.", 'error');

		return Promise.reject(error);
	}

	if (error.response.status == 0) {
		appInsights.trackTrace({ message: "EDU020 | MSAL lib interceptor got status 0" }, {
			data: error?.response?.data,
			params: error?.config?.params,
			url: error?.config?.url,
			method: error?.config?.method,
			baseURL: error?.config?.baseURL,
			schoolId: error?.config?.params['SchoolId'],
		});

		// Wait 1 second before trying again
		await new Promise((resolve) => {
			setTimeout(() => resolve(1), 1000);
		});

		// Save or update number of tries in HTTP headers
		if (error.config.headers['Retries'] == null) {
			// Dispatch an error (toast bar) first retry
			_addToast("You seem to have lost connection to the server. You can keep working as usual while we're trying to reconnect you.");

			error.config.headers['Retries'] = '1';
		} else {
			error.config.headers['Retries'] = parseInt(error.config.headers['Retries']) + 1 + '';
		}

		if (parseInt(error.config.headers['Retries']) > 49) {
			return Promise.reject(error);
		}

		// Make call again
		return http.request(error.config)
	}

	return Promise.reject(error)
});

// Export members of msal.js
export {
	loginRequest,
	getToken,
	getTokenForScope,
	getConsentStatus,
	http,
	getUser,
	setUser,
	setOnboardingUser,
	getUserId,
	setOnboardingToken,
	requirePopupConsent,
	errors,
	onMsalTokenError,
	setOnboardingExpireTokenIn,
	isTokenValid,
	onMsalLoginRequired,
	clearInteractionProgress,
};

// Export MSAL instance
export default instance;