import React, { useState, useEffect, useRef } from 'react';
import "./_displayName.scss";
import { Tooltip } from '@mui/material';

const DisplayName = ({
	firstName,
	lastName,
	email,
	data,
	showEmail,
	displayAvatar,
	dynamicStringWidth,
	splitFirstName,
	sameName,
	tooltipTile
}) => {
	useEffect(() => {
		if (firstName == null) {
			return null;
		}

		if (lastName) {
			setUserName(`${firstName} ${lastName}`);
		} else {
			setUserName(firstName);
		}
	}, [firstName, lastName]);

	useEffect(() => {
		if (!showEmail) {
			setDisplayString(`${userName} (${email})`);
		} else {
			setDisplayString(`(${email})`);
		}
	}, [email]);

	const [userName, setUserName] = useState(lastName ? `${firstName} ${lastName}` : firstName);
	const [displayString, setDisplayString] = useState(!showEmail ? `${userName} (${email})` : `(${email})`);
	const dynamicStringRef = useRef(null);

	// Checks if there is another student with the same name and triggers css animation to alternate between name/email
	// Alot of different checks depending on view this component is rendered in
	let hasSameName = false;

	if (sameName) {
		hasSameName = sameName;
	}

	if (data != null && !showEmail) {
		try {
			if (lastName != null) {
				hasSameName = data.filter(x =>
					x.firstName === firstName && x.lastName === lastName
				).length > 1;

				if (!hasSameName) {
					hasSameName = data.filter(x =>
						x.firstname === firstName && x.lastname === lastName
					).length > 1;
				}
			} else if (splitFirstName) {
				const [firstname, lastname] = firstName.split(" ");
				hasSameName = data.filter(x =>
					x.firstName === firstname && x.lastName === lastname
				).length > 1;
			}
			else {
				hasSameName = data.filter(x =>
					x.groupName === firstName).length > 1;

				if (!hasSameName) {
					hasSameName = data.filter(x =>
						x.referenceTitle === firstName).length > 1;
				}
			}
		}
		catch (error) {
			console.log(error);
		}
	}

	if (dynamicStringWidth && email) {
		// Update string initially and on resize
		useEffect(() => {
			const updateString = () => {
				const container = dynamicStringRef.current;
				const containerWidth = container.offsetWidth;
				const textWidth = getTextWidth(displayString);

				if (textWidth > containerWidth) {
					// String is longer than container width
					let endIndex = binarySearchForFit(displayString, containerWidth);
					setDisplayString(displayString.slice(0, endIndex) + '...)');
				} else {
					// String fits within container width
					setDisplayString(displayString);
				}
			};

			const handleResize = () => {
				const container = dynamicStringRef.current;
				const containerWidth = container.offsetWidth;
				const string = (!showEmail ? `${userName} (${email})` : `(${email})`);
				const textWidth = getTextWidth(string);

				if (textWidth > containerWidth) {
					// String is longer than container width
					let endIndex = binarySearchForFit(string, containerWidth);
					setDisplayString(string.slice(0, endIndex) + '...)');
				} else {
					// String fits within container width
					setDisplayString(string);
				}
			};

			updateString();
			window.addEventListener('resize', handleResize);

			// Cleanup event listener on component unmount
			return () => window.removeEventListener('resize', handleResize);
		}, [displayString]);

		// Function to calculate text width
		const getTextWidth = (text) => {
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			context.font = getComputedStyle(dynamicStringRef.current).font;
			return context.measureText(text).width;
		};

		// Function for binary search to find substring that fits within container width
		const binarySearchForFit = (text, targetWidth) => {
			let start = 0;
			let end = text.length - 1;
			while (start <= end) {
				const mid = Math.floor((start + end) / 2);
				const midWidth = getTextWidth(text.slice(0, mid + 1));
				if (midWidth <= targetWidth) {
					start = mid + 1;
				} else {
					end = mid - 1;
				}
			}
			return start;
		};
	}

	// Changes class in span only to match size for layout
	let isNameLonger = false;
	if (email && userName && hasSameName) {
		isNameLonger = email.length < userName.length;
	}

	// Takes the first letter of the firstname and lastname to the avatar
	const getInitials = () => {
		let name = userName;

		if (name != null && name.length > 0) {
			let nameSplit = name.split(' ');

			if (nameSplit.length > 1)
				return nameSplit[0].charAt(0).toUpperCase() + nameSplit[nameSplit.length - 1].charAt(0).toUpperCase();

			if (nameSplit.length == 1)
				return nameSplit[0].charAt(0).toUpperCase();
		} else {
			return '';
		}
	}

	return (
		<>
			<Tooltip title={tooltipTile ?? ''} arrow placement="top">
				<div>
					<div className="avatar" style={displayAvatar ? { display: "flex" } : { display: "none" }}>
						{displayAvatar ? getInitials() : ""}
					</div>
					<div ref={dynamicStringRef} className={displayAvatar ? "avatar-hover display-name-container" : "display-name-container"}>

						{hasSameName && !dynamicStringWidth && email ?
							<>
								<span id="display-email" className={isNameLonger ? "hide shorter-name" : "hide"}>{email}</span>
								<span id="display-name" className={isNameLonger ? "hide" : "hide shorter-name"}>{userName}</span>
							</>

							: dynamicStringWidth && hasSameName ?
								<span id='display-string-email'>
									{displayString}
								</span>

								: showEmail && email && dynamicStringWidth ?
									<div>
										<span>{userName}</span>
										<span id='show-email'>
											{displayString}
										</span>
									</div>

									: !hasSameName || !email && !dynamicStringWidth ?
										<span>{userName}</span>

										: ""}

						{showEmail && email && !dynamicStringWidth ?
							<span id='show-email'>
								{email}
							</span>

							: ""}
					</div>
				</div>
			</Tooltip>
		</>
	);
}

export default DisplayName;
