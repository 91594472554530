import api from 'lib/api';

export const SEARCH_PLANS = 'SEARCH_PLANS';
export const GET_PLANS = 'GET_PLANS';
export const ACTIVE_PLAN = 'ACTIVE_PLAN';
export const ACTIVE_PLAN_KNOWLEDGECOLLECTIONS = 'ACTIVE_PLAN_KNOWLEDGECOLLECTIONS';
export const GET_PLANS_BY_SECTIONID = 'GET_PLANS_BY_SECTIONID';
export const SET_ACTIVE_PLAN = 'SET_ACTIVE_PLAN';
export const PLAN_UPDATE = 'PLANNING_UPDATE';
export const CLEAR_PLANS_FROM_STORE = 'CLEAR_PLANS_FROM_STORE';
export const CLEAR_SECTION_PLANS = 'CLEAR_SECTION_PLANS';
export const PLAN_DELETE_PLAN = 'PLAN_DELETE_PLAN';
export const PLAN_DELETE_ASSIGNMENT_FROM_PLAN = 'PLAN_DELETE_ASSIGNMENT_FROM_PLAN';
export const CREATE_BULK_PLANS = 'CREATE_BULK_PLANS';
export const CREATE_PLAN = 'CREATE_PLAN';
export const GET_PLAN_STUDENT_ADDITIONAL_ADJUSTMENTS = 'PLANS_GET_STUDENT_ADDITIONAL_ADJUSTMENTS';
export const GET_PLAN_SECTION = 'PLANS_GET_PLAN_SECTION';
export const CLEAR_PLAN_SECTION = 'PLANS_CLEAR_PLAN_SECTION';

export const getPlans = (startDate, endDate) => ({
	type: GET_PLANS,
	payload: new Promise((resolve) => {
		var endpoint = 'planning';

		if (startDate != null && endDate != null) {
			endpoint += '?startDate=' + startDate;
			endpoint += '&endDate=' + endDate;
		}

		api.get(endpoint).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getPlanDetails = (id, callback) => ({
	type: ACTIVE_PLAN,
	payload: new Promise((resolve, reject) => {
		api.get(`planning/${id}?ver=2`)
			.then((response) => {
				resolve(response.data);

				if (callback != null) {
					callback(response.data);
				}
			}).catch((error) => {
				reject(error);
			});
	}),
});

export const createBulkPlans = (bulkPlans) => ({
	type: CREATE_BULK_PLANS,
	payload: new Promise(resolve => {
		api.post(`planning/bulk?ver=2`, bulkPlans).then((response) => {
			resolve(response.data);
		});
	}),
});

export const createPlan = (plan) => ({
	type: CREATE_PLAN,
	payload: new Promise(resolve => {
		api.post(`planning/?ver=2`, plan).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getPlanKnowledgeCollections = (sectionId, planId, callback) => ({
	type: ACTIVE_PLAN_KNOWLEDGECOLLECTIONS,
	payload: new Promise(resolve => {
		api.get(`knowledge/section/${sectionId}/plan/${planId}/collections`).then((response) => {
			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export const updatePlan = (values) => ({
	type: PLAN_UPDATE,
	payload: new Promise(resolve => {
		api.put(`planning?ver=2`, values).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getPlansBySectionId = (id, callback) => ({
	type: GET_PLANS_BY_SECTIONID,
	payload: new Promise((resolve) => {
		api.get(`planning/section/${id}`).then((response) => {
			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export const deletePlan = (id) => ({
	type: PLAN_DELETE_PLAN,
	payload: new Promise((resolve) => {
		api.delete(`planning/${id}`).then((response) => {
			resolve(id);
		});
	}),
});

export const setActivePlan = (id) => ({
	type: SET_ACTIVE_PLAN,
	payload: id
});

export const clearPlansFromStore = (id) => ({
	type: CLEAR_PLANS_FROM_STORE,
	payload: []
});

export const clearSectionPlansFromStore = () => ({
	type: CLEAR_SECTION_PLANS,
	payload: []
})

export const deleteAssignmentFromPlan = (planId, assignmentId) => ({
	type: PLAN_DELETE_ASSIGNMENT_FROM_PLAN,
	payload: new Promise(resolve => {
		api.delete(`planning/${planId}/assignment/${assignmentId}`).then((response) => {
			resolve({ 'planId': planId, 'assignmentId': assignmentId });
		});
	}),
});

export const getStudentAdditionalAdjustmentsForPlan = (planId) => ({
	type: GET_PLAN_STUDENT_ADDITIONAL_ADJUSTMENTS,
	payload: new Promise((resolve) => {
		api.get(`planning/${planId}/studentAdditionalAdjustments`).then((response) => {
			resolve(response.data);
		});
	}),
})

export const getPlanSection = (id) => ({
	type: GET_PLAN_SECTION,
	payload: new Promise((resolve) => {
		api.get(`sections/${id}`).then((response) => {

			var data = response.data;

			if (data && data.courses != null && data.courses.length > 0) {
				data.courses.forEach(element => {
					var sortedList = [];
					if (element.details != null && element.details.length > 0) {
						var activeDetailIndex = element.details.findIndex(element => element.endDate == null);

						if (activeDetailIndex > -1) {
							var activeDetail = element.details[activeDetailIndex];
							element.details.splice(activeDetailIndex, 1);
							element.details.unshift(activeDetail);
						}
					}
				});
			}

			resolve(response.data);
		});
	}),
});

export const clearPlanSection = () => ({
	type: CLEAR_PLAN_SECTION,
	payload: null,
});