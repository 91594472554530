import api from 'lib/api';

export const SCHEDULE_TODAY = 'SCHEDULE_GET_SCHEDULE_TODAY';
export const CLEAR_SCHEDULE_FROM_STORE = 'CLEAR_SCHEDULE_FROM_STORE';
export const GET_SCHEDULE_ITEM = 'SCHEDULE_GET_SCHEDULE_ITEM';
export const GET_SCHEDULES_BY_ID = 'SCHEDULE_GET_SCHEDULE_ITEMS'
export const GET_SCHEDULE_FOR_USER = 'SCHEDULE_GET_USER_SCHEDULE';
export const GET_SCHEDULE_FOR_USERS = 'GET_SCHEDULE_FOR_USERS';
export const CLEAR_SCHEDULE_ITEM = 'SCHEDULE_CLEAR_SCHEDULE_ITEM';
export const GET_ACTIVITIES_FOR_USER = 'GET_ACTIVITIES_FOR_USER';
export const GET_ACTIVITIES_FOR_GROUPS = 'GET_ACTIVITIES_FOR_GROUPS';
export const CLEAR_ACTIVITIES_FOR_OTHERS_STORE = 'CLEAR_ACTIVITIES_FOR_OTHERS_STORE';
export const CLEAR_SEARCHED_SCHEDULES = 'CLEAR_SEARCHED_SCHEDULES'
export const UPDATE_LESSON_LIST = 'UPDATE_LESSON_LIST';
export const GET_ACTIVITIES_BY_ID = 'GET_ACTIVITIES_BY_ID';

export const SUBMIT_CALENDAR_EVENT = 'SCHEDULE_CREATE_CALENDAR_EVENT';
export const UPDATE_CALENDAR_EVENT = 'SCHEDULE_UPDATE_CALENDAR_EVENT';
export const DELETE_CALENDAR_EVENT = 'SCHEDULE_DELETE_CALENDAR_EVENT';
export const DELETE_LESSON_IN_LIST = 'DELETE_LESSON_IN_LIST';

export const GET_SCHEDULE_POSITION_WITH_NO_REPORTED_ATTENDANCE =
	'GET_SCHEDULE_POSITION_WITH_NO_REPORTED_ATTENDANCE';
export const CLEAR_SCHEDULE_POSITION_WITH_NO_REPORTED_ATTENDANCE =
	'CLEAR_SCHEDULE_POSITION_WITH_NO_REPORTED_ATTENDANCE';

export const SET_LAST_FETCHED = 'SET_LAST_FETCHED';

export const getSchedule = (start, end) => ({
	type: SCHEDULE_TODAY,
	payload: new Promise((resolve) => {
		api.get(`schedule?starttime=${start}&endtime=${end}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getScheduleForMe = (user, start, end) => ({
	type: GET_SCHEDULE_FOR_USER,
	payload: new Promise(async (resolve, reject) => {
		api.get(`schedule?starttime=${start}&endtime=${end}`).then((response) => {
			resolve({
				userId: user,
				documents: response.data,
			});
		}).catch((e) => {
			console.log(e);
		})
	}),
});

export const getScheduleForUser = (user, start, end) => ({
	type: GET_SCHEDULE_FOR_USER,
	payload: new Promise(async (resolve, reject) => {
		api.get(`schedule/user/${user}/?starttime=${start}&endtime=${end}`).then((response) => {
			resolve({
				userId: user,
				documents: response.data,
			});
		}).catch((e) => {
			console.log(e);
		})
	}),
});

export const getActivitiesForMe = (start, end, userId) => ({
	type: GET_ACTIVITIES_FOR_USER,
	payload: new Promise(async (resolve) => {
		api.get(`users/me/activities?startDate=${start}&endDate=${end}`).then((response) => {
			resolve({
				userId: userId,
				documents: response.data,
			});
		});
	}),
});

export const getActivitiesById = (userId, start, end) => ({
	type: GET_ACTIVITIES_BY_ID,
	payload: new Promise(async (resolve) => {
		api.get(`activities/users/${userId}?startDate=${start}&endDate=${end}`)
			.then((response) => {
				resolve({
					documents: response.data,
				});
			})
			.catch((e) => {
				console.log(e);
			});
	}),
});

export const getScheduleForGroups = (startDate, endDate, section, id) => ({
	type: GET_SCHEDULE_FOR_USERS,
	payload: new Promise(async (resolve) => {
		api.post(`schedule/users?starttime=${startDate}&endtime=${endDate}`, section).then((response) => {
			resolve({
				documents: response.data,
				startDate: startDate,
				endDate: endDate,
				id: id
			});
		}).catch((e) => {
			console.log(e);
		})
	}),
})
export const getActivitesForGroups = (startDate, endDate, section, id) => ({
	type: GET_ACTIVITIES_FOR_GROUPS,
	payload: new Promise(async (resolve) => {
		api.post(`activities/sections?startDate=${startDate}&endDate=${endDate}`, section).then((response) => {
			resolve({
				documents: response.data,
				startDate: startDate,
				endDate: endDate,
				id: id
			});
		});
	}),
});

export const getScheduleItem = (id) => ({
	type: GET_SCHEDULE_ITEM,
	payload: new Promise((resolve) => {
		api.get(`schedule/${id}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getScheduleItems = (id) => ({
	type: GET_SCHEDULES_BY_ID,
	payload: new Promise((resolve) => {
		api.get(`schedule/${id}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const setLastFetched = (date) => ({ type: SET_LAST_FETCHED, payload: date });

export const clearScheduleItem = () => ({
	type: CLEAR_SCHEDULE_ITEM,
	payload: true,
});
export const clearSearchedSchedules = () => ({
	type: CLEAR_SEARCHED_SCHEDULES,
	payload: true,
});
export const clearActivitesForOther = () => ({
	type: CLEAR_ACTIVITIES_FOR_OTHERS_STORE,
	payload: [],
});

export const clearScheduleFromStore = () => ({
	type: CLEAR_SCHEDULE_FROM_STORE,
	payload: [],
});

export const submitCalendarEvent = (values) => ({
	type: SUBMIT_CALENDAR_EVENT,
	payload: new Promise((resolve, reject) => {
		api.post(`schedule`, values).then((response) => {
			// TODO: Add error here
			if (false) {
				reject(response.data);
			} else {
				resolve(response.data);
			}
		});
	}),
});

export const updateCalendarEvent = (values) => ({
	type: UPDATE_CALENDAR_EVENT,
	payload: new Promise((resolve, reject) => {
		api.put(`schedule`, values).then((response) => {
			if (false) {
				reject(response);
			} else {
				resolve(response.data);
			}
		});
	}),
});

export const deleteCalendarEvent = (id) => ({
	type: DELETE_CALENDAR_EVENT,
	payload: new Promise((resolve, reject) => {
		api.delete(`schedule/${id}`).then((response) => {
			if (response.status != 200) reject(0);

			resolve(1);
		});
	}),
});

export const getNotReportedSchedulePositions = (roleId, startDate, endDate, callback) => ({
	type: GET_SCHEDULE_POSITION_WITH_NO_REPORTED_ATTENDANCE,
	payload: new Promise((resolve) => {
		api.get(`schedule/notreported?roleId=${roleId}&startDate=${startDate}&endDate=${endDate}`).then(
			(response) => {
				resolve(response.data);
			}
		);
	}),
});

export const clearNotReportedSchedulePositions = () => ({
	type: CLEAR_SCHEDULE_POSITION_WITH_NO_REPORTED_ATTENDANCE,
	payload: [],
});

export const updateLessonInList = (updatedLessonList) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_LESSON_LIST,
				payload: updatedLessonList,
			});
			return updatedLessonList;
		} catch (error) {
			// Handle the error appropriately
			throw error;
		}
	};

}

export const deleteLessonInList = (removedId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: DELETE_LESSON_IN_LIST,
				payload: removedId
			});
			return true;
		} catch (error) {
			// Handle the error appropriately
			throw error;
		}
	};

}