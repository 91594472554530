import api from 'lib/api';
import { CLEAR_SCHEDULE_FROM_STORE } from './schedule';

export const GET_CURRENT_MEETINGS = 'MEETINGS_GET_MEETINGS';
export const GET_PAST_MEETINGS = 'MEETINGS_GET_PAST_MEETINGS';
export const GET_USER_MEETINGS = 'MEETINGS_GET_USER_MEETINGS';
export const GET_MEETING = 'MEETINGS_GET_SINGLE_MEETING';
export const CREATE_MEETING = 'MEETINGS_CREATE_MEETING';

export const GET_MEETING_INSTANCE = 'GET_MEETING_INSTANCE';
export const MEETING_INSTANCE_UPDATE = 'MEETING_INSTANCE_UPDATE';
export const MEETING_ADD_INSTANCES = 'MEETING_ADD_INSTANCES';
export const MEETING_MEETING_UPDATE = 'MEETING_MEETING_UPDATE';
export const ADD_MEETING_INSTANCE_RELATIONSHIP = 'MEETING_ADD_INSTANCE_RELATIONSHIP';
export const DELETE_MEETING_INSTANCE_RELATIONSHIP = 'DELETE_MEETING_INSTANCE_RELATIONSHIP';
export const MEETING_INSTANCE_UPDATE_DATE = 'MEETING_INSTANCE_UPDATE_DATE';
export const CREATE_MEETING_FORM_INSTANCE = 'CREATE_MEETING_FORM_INSTANCE';
export const MEETING_INSTANCE_SEND_REMINDER = 'MEETING_INSTANCE_SEND_REMINDER';
export const DELETE_MEETING_RELATIONSHIP = 'DELETE_MEETING_RELATIONSHIP';
export const CLEAR_MEETING = 'CLEAR_MEETING';
export const SAVE_WIZARD_MEETING = 'MEETINGS_SAVE_WIZARD_MEETING';
export const CLEAR_WIZARD_MEETING = 'MEETINGS_CLEAR_WIZARD_MEETING';
export const DELETE_MEETING_INSTANCE_PARTICIPANT = 'MEETINGS_DELETE_MEETING_INSTANCE_PARTICIPANT';
export const DELETE_MEETING = 'MEETINGS_DELETE_MEETING';
export const DELETE_MEETING_INSTANCE = 'MEETINGS_DELETE_MEETING_INSTANCE';


export const createMeeting = (meeting) => {
	return async (dispatch) => {
		const response = await api.post('meetings', meeting);
		dispatch({ type: CREATE_MEETING, payload: response.data });
		dispatch({ type: CLEAR_SCHEDULE_FROM_STORE });
	};
};

export const getCurrentMeetings = () => ({
	type: GET_CURRENT_MEETINGS,
	payload: new Promise(resolve => {
		api.get(`meetings?filter=active`).then(response => {
			resolve(response.data ?? []);
		});
	}),
});

export const getPastMeetings = () => ({
	type: GET_PAST_MEETINGS,
	payload: new Promise((resolve) => {
		api.get(`meetings?filter=inactive`).then((response) => {
			resolve(response.data ?? []);
		});
	}),
});

export const getUserMeetings = (user, start, end) => ({
	type: GET_USER_MEETINGS,
	payload: new Promise(resolve => {
		let url = `meetings?studentId=${user}&start=${start}`;
		if (end != null) {
			url += `&end=${end}`;
		}

		api.get(url).then(response => {
			resolve(response.data);
		});
	}),
});

export const getMeeting = (id) => ({
	type: GET_MEETING,
	payload: new Promise(resolve => {
		api.get(`meetings/${id}`).then(response => {
			resolve(response.data);
		});
	}),
});

export const getMeetingInstance = (id, instanceId) => ({
	type: GET_MEETING_INSTANCE,
	payload: new Promise(resolve => {
		api.get(`meetings/${id}/instance/${instanceId}`).then(response => {
			resolve(response.data);
		});
	}),
});

export const updateMeetingInstance = (instance) => ({
	type: MEETING_INSTANCE_UPDATE,
	payload: new Promise(resolve => {
		api.put('meetings/instance', instance).then(response => {
			resolve(response.data);
		});
	}),
});

export const updateMeetingInstanceDate = (instance, onError) => ({
	type: MEETING_INSTANCE_UPDATE_DATE,
	payload: new Promise((resolve, reject) => {
		api.put('meetings/instance/date', instance).then((response) => {
			resolve(response.data);
		})
			.catch((reason) => {
				if (onError != null) {
					onError(reason);
				}
			});
	}),
});

export const updateMeeting = (values) => {
	return async (dispatch) => {
		await api.put('meetings', values);
		const response = await api.get(`meetings/${values.id}`);
		dispatch({ type: GET_MEETING, payload: response.data });
		dispatch({ type: CLEAR_SCHEDULE_FROM_STORE });
	};
};

export const addMeetingInstances = (id, instances) => ({
	type: MEETING_ADD_INSTANCES,
	payload: new Promise(resolve => {
		api.post(`meetings/${id}/instances`, instances).then(response => {
			resolve(response.data);
		});
	})
});

export const sendMeetingInstanceTimeBookingReminder = (id, instanceId) => ({
	type: MEETING_INSTANCE_SEND_REMINDER,
	payload: new Promise(resolve => {
		api.post(`meetings/${id}/instance/${instanceId}/reminder`, {}).then(response => {
			resolve(response.data);
		});
	})
});


export const createMeetingInstanceRelationship = (meeting, instance, relation) => ({
	type: ADD_MEETING_INSTANCE_RELATIONSHIP,
	payload: new Promise(resolve => {
		api.post(`meetings/${meeting}/instance/${instance}/relationships`, relation).then((response) => {
			resolve(response.data);
		});
	}),
});

export const deleteMeetingInstanceRelationship = (meetingId, instanceId, relationshipId) => ({
	type: DELETE_MEETING_INSTANCE_RELATIONSHIP,
	payload: new Promise(resolve => {
		api.delete(`meetings/${meetingId}/instance/${instanceId}/relationship/${relationshipId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const deleteMeetingRelationship = (meetingId, relationshipId) => ({
	type: DELETE_MEETING_RELATIONSHIP,
	payload: new Promise(resolve => {
		api.delete(`meetings/${meetingId}/relationship/${relationshipId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const deleteMeetingInstanceParticipant = (meetingId, instanceId, participantId) => ({
	type: DELETE_MEETING_INSTANCE_PARTICIPANT,
	payload: new Promise((resolve) => {
		api.delete(`meetings/${meetingId}/instance/${instanceId}/participant/${participantId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const deleteMeeting = (meetingId) => ({
	type: DELETE_MEETING,
	payload: new Promise((resolve) => {
		api.delete(`meetings/${meetingId}`).then((response) => {
			if (response.status == 200) {
				resolve(meetingId);
			} else {
				resolve(false);
			}
		})
	})
});

export const deleteMeetingInstance = (meetingId, instanceId) => ({
	type: DELETE_MEETING_INSTANCE,
	payload: new Promise((resolve) => {
		api.delete(`meetings/${meetingId}/instance/${instanceId}`).then((response) => {
			if (response.status == 200) {
				resolve(instanceId);
			} else {
				resolve(false);
			}
		})
	})
});

export const createMeetingFormInstance = (meetingId, instanceId, instance) => ({
	type: CREATE_MEETING_FORM_INSTANCE,
	payload: new Promise(resolve => {
		api.post(`meetings/${meetingId}/instance/${instanceId}/formInstance`, instance).then(response => {
			resolve(response.data);
		});
	}),
});

export const clearMeeting = () => ({
	type: CLEAR_MEETING,
	payload: null
});

export const saveWizardMeeting = (meeting) => ({
	type: SAVE_WIZARD_MEETING,
	payload: meeting,
});

export const clearWizardMeeting = () => ({
	type: CLEAR_WIZARD_MEETING,
	payload: true,
});
